<div >              
    <div  class="card d-flex justify-content-between  card11 p-3"  >
      <div class="card-body d-flex justify-content-between">
        <div>
          <p  (click)="navigateToAnotherPage()">Order ID: {{ data[0].order_id }}</p>
          <p  (click)="navigateToAnotherPage()">AWb: {{ data[0].awb }}</p>
        </div>
        <div>
          <button class="btn-danger button" (click)="closeModal()">Cancel</button>             
        </div>
      </div>
    
    </div>
  
</div>