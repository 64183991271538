import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, retry } from "rxjs";
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ModeServicesService {

  private isDarkModeOnSubject = new BehaviorSubject<boolean>(false);
  isDarkModeOn$ = this.isDarkModeOnSubject.asObservable();

  constructor() { }

  setIsDarkModeOn(isDarkModeOn: boolean) {
    this.isDarkModeOnSubject.next(isDarkModeOn);
  }
}
