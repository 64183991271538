<h1 class="page col-md-6 mb-4 mt-2">Products List</h1>
<div class="d-flex justify-content-between mt-5 laptop">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            >
        <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-end page-top">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
</div>
<div class="justify-content-center mt-5 mobile">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            >
        <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-center page-top mt-2">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
</div>
<div class="row d-flex justify-content-center p-4">
    <div class="card-body" style="background-color:transparent;">
        <div class="material-datatables">
            <div class="position-relative overflow-auto">
                <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers" matSort>
                    <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i"
                        [stickyEnd]="tableheader == 'actions'">
                        <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold" mat-sort-header
                            style="white-space: nowrap;">
                            {{ headerData[tableheader] }}
                        </th>
                        <ng-container>
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

            </div>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom">
            <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
              [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
        </div>
    </div>


</div>

<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>