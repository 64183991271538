import { Component ,Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';



@Component({
  selector: 'app-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.scss']
})
export class OpenComponent {
  searchdata:any;
  constructor(private dialogRef: MatDialogRef<OpenComponent>,  @Inject(MAT_DIALOG_DATA) public data: any,private router: Router
  ) {}

  ngOnInit(): void {
    console.log('Data received in modal:', this.data);
    // this.searchdata = JSON.parse(this.data)
    // console.log(this.searchdata,'5751264084251')
    
  }


  saveChanges(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.dialogRef.close();
  }
  navigateToAnotherPage(): void {
    this.closeModal(); 
    this.router.navigate(['page/crmD2c/details']);


  }


}
