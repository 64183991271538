<div class="container">
    <div class="row">
        <div class="">
            <div class="d-flex justify-content-between col-md-6">
                <h1 class="mb-5 mt-5 ms-5">Personal Details</h1>
            </div>
            <div class="ms-5 mb-5 col-md-6 justify-content-between d-flex">
                <img src="{{this.imageURL}}" width="150px" height="150px">
                <button class="btn btn-success w-md waves-effect waves-light me-5 mt-5" type="submit" routerLink="/page/retailripple/edit" style="width: 30px; height: 36px;">Edit Profile</button>
            </div>
            <div class="" style="text-align: left;">
                <div class="table-responsive ms-5">
                    <div class="table table-borderless mb-0">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">First Name</th>
                                    <td class="text-muted">{{this.first_name}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Last Name</th>
                                    <td class="text-muted me-5">{{this.last_name}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">E-mail </th>
                                    <td class="text-muted">{{this.email}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Phone Number</th>
                                    <td class="text-muted">{{this.phone_number}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Portal Name</th>
                                    <td class="text-muted">{{this.portal_name}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">D2C Name</th>
                                    <td class="text-muted">{{this.d2c_name}}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">UserName</th>
                                    <td class="text-muted">{{this.username}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- <div class="row">
        <div style="display: flex; justify-content: end;" class="mb-5">
            <button class="btn btn-success w-md waves-effect waves-light me-3" type="submit" routerLink="/page/retailripple/edit">Edit Profile</button>
        </div>
    </div> -->

</div>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>