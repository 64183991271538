import { Injectable } from '@angular/core';
import { AuthenticationService } from './auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class CountService {

  showOverlay = false
  order:any;
  support:any;
  superuser:any;
  portal_name:any;
  portal_id:any;
  portal_account_id:any;
  d2c_name:any
  is_whatsapp:any;
  is_whatsapp1:any;
  is_whatsapp2:any;
  is_whatsapp3:any;

  constructor(
    private router: Router, 
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private _shared : SharedService

  ) { }

  ngOnInit(): void {
    this.unread_ticket();
  }

  unread_ticket() {
    this.superuser = localStorage.getItem('is_superuser')

    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data ={
      'portal_id':this.portal_id,
      'portal_account_id':this.portal_account_id
    }
    this.showOverlay=true;
    this.authService.unread_tickets(data).subscribe((res: any) => {
      let mes = JSON.parse(res);
      if(mes.statusCode === 200){
        console.log(mes)
        this.order = mes.order_ticket_count
        this.support = mes.support_ticket_count
        console.log(this.order, this.support)
        this.is_whatsapp = localStorage.getItem('is_whatsapp')
        this.is_whatsapp1 = localStorage.getItem('is_whatsapp_marketing')
        this.is_whatsapp2 = localStorage.getItem('order_notifications')
        this.is_whatsapp3 = localStorage.getItem('custom_notifications')
        localStorage.setItem('ticketss', JSON.stringify(mes.order_ticket)); 
        localStorage.setItem('ticketss1', JSON.stringify(mes.support_ticket))

        // localStorage.setItem('order',mes.order_ticket_count)
        // localStorage.setItem('support',mes.support_ticket_count)
  
  
      }
      else{
        this.toastr.error(res.error, 'Error')
        this.showOverlay=false
        // this.modalService.dismissAll('Close click');
  
  
  
  
      }
    })
      // .catch(error => {
        //   this.error = error ? error : '';
        // });
    
  }
}
