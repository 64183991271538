import { Component,inject,TemplateRef } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators,FormArray} from '@angular/forms';


@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent {
  showOverlay = false
  data:any
  fullData:any
  searchdata:any
  order_id:any
  searchdata1:any
  searchdata2:any
  superuser:any;
  d2c_name:any;
  portal_name:any;
  portal_account_id: any;
  payment_method:any;
  portal_id:any;
  reason = new FormControl(null,[Validators.required]);
  userHolidayCreate: FormGroup;
  loading:boolean = false




  private modalService = inject(NgbModal);
  // reason = new FormControl();




  constructor(private authService:AuthenticationService,private modalServicee: NgbModal,private router: Router,private toastr: ToastrService,private fb: FormBuilder){

  }


  ngOnInit(): void {
    this.fullData = JSON.parse(localStorage.getItem('order_data') || '[]');
    console.log('this.fullDatahgfdsdfghlkjhgf',this.fullData.order_id)
    this.payment_method = this.fullData.payment_method

    this.order_status();
    this.order_id = this.fullData.order_id
    this.modalService.dismissAll('Close click');
    this.superuser = localStorage.getItem('is_superuser')
    this.userHolidayCreate = this.fb.group({
      reason: ['', Validators.required],
      name:[this.fullData.customer_name,Validators.required],
      phone:[this.fullData.phone,Validators.required],
      email: [this.fullData.email_id, [Validators.required]],
      address:[this.fullData.address,Validators.required],
      ifsc:[''],
      account_id:[''],
      pincode:[this.fullData.pincode],
    });





  }


  private initForm(){
    this.userHolidayCreate=new FormGroup({
      // holiday_id:this.holiday_id,
      reason:this.reason,
   
    
    })
    
  }
  order_status(){
    this.showOverlay = true; 
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else{
      this.portal_name = localStorage.getItem('portal_name')
       this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data ={
      'portal_id':this.portal_id,
      'portal_account_id':this.portal_account_id,
      'order_id': this.fullData.order_id,
      // 'payment_method':this.fullData[0].payment_method,
      // 'reason': this.order_id
    }
    console.log(data,'kjhgfdsasdfghjklkjhgfd')
    this.authService.order_status(data).subscribe((res: any) => {
      console.log(res);
      let mes = JSON.parse(res);
      console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        // this.toastr.success(mes.message, ''); 
        this.searchdata1 = mes.is_return_avialable
        this.searchdata2 = mes.is_cancel_avialable
        console.log(mes.is_return_avialable,'this.searchdata1')

  }
  else{
    if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(res.error, "");
      this.showOverlay = false; 
      // console.log('error mesg',res)
    }


  }
})


}
update_status(order:any){
  this.showOverlay = true; 
  this.loading = true
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  
  let data ={
    'portal_id':this.portal_id,
    'portal_account_id':this.portal_account_id,
    'type': order,
    'order_id': this.fullData.order_id,
    'payment_method':this.fullData.payment_method,
    'reason':  this.userHolidayCreate.value.reason,
    'ifsc':  this.userHolidayCreate.value.ifsc,
    'address':  this.userHolidayCreate.value.address,
    'name':  this.userHolidayCreate.value.name,
    'email':  this.userHolidayCreate.value.email,
    'phone':  this.userHolidayCreate.value.phone,
    'pincode':this.userHolidayCreate.value.pincode,
    'account_id':this.userHolidayCreate.value.account_id


  }
  console.log(data,'aaaaaaaaaaaa')
  this.authService.update_status(data).subscribe((res: any) => {
    console.log(res);
    let mes = JSON.parse(res);
    console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      console.log(mes,'this.searchdata1')
      this.toastr.success(res.success, ''); 
      this.modalService.dismissAll('Close click');
      this.userHolidayCreate.reset()
      this.order_status()
      this.router.navigate(['page/retailripple/order'])
      this.loading = false



}
else{
  if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
    this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
    this.loading = false

   
  }
  else{
    this.toastr.error(res.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.userHolidayCreate.reset()
    this.loading = false

    // console.log('error mesg',res)
  }

  // this.showOverlay = false;

}
})

  

}

open(content: TemplateRef<any>) {
  this.modalService.open(content, { centered: true,size: 's'});
  this.showReasonInput = false; // Initialize the input field visibility

}
open1(content1: TemplateRef<any>) {
  this.modalService.open(content1, { centered: true,size: 's'});
}
route(){
  this.router.navigate(['page/retailripple/order'])
}

cancelReason: string = '';
showReasonInput: boolean = false;




showInput() {
  this.showReasonInput = true;
}

numericOnly(event:any): boolean {    
  let patt = /^[0-9]+$/;
  let result = patt.test(event.key);
  return result;
}
alphanumericOnly1(event: KeyboardEvent): void {
  const inputElement = event.target as HTMLInputElement;
  const currentValue = inputElement.value;

  const allowedCharsPattern = /^[a-zA-Z0-9]+$/;
  const isAllowedChar = allowedCharsPattern.test(event.key);
  if (!isAllowedChar) {
    event.preventDefault();
    return;
  }
  const newValue = currentValue + event.key.toUpperCase(); // Convert to uppercase and add to the current value
  inputElement.value = newValue; // Update the input field value
  inputElement.dispatchEvent(new Event('input')); // Trigger an 'input' event to update Angular model
  event.preventDefault(); // Prevent the default keypress behavior
}

send_details(){
  this.showOverlay = true; 
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = {
    'portal_name': this.portal_name,
    'customer_name':this.fullData.customer_name,
    'email_id':this.fullData.email_id,
    'awb':this.fullData.awb,
    'tracking_url': this.fullData.tracking_url,
    'address':this.fullData.address,
    'phone': this.fullData.phone
  }
  this.authService.send_details(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.toastr.success(mes.success, ''); 



}
else{
  if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
    this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(res.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.userHolidayCreate.reset()
    // console.log('error mesg',res)
  }

  // this.showOverlay = false;

}
})
}

resend_invoice(){
  this.showOverlay = true
  this.superuser = localStorage.getItem('is_superuser')
 if(this.superuser == 'true'){
   this.portal_name = localStorage.getItem('portal_name2')
   this.portal_id = localStorage.getItem('portal_id2')
   this.portal_account_id = localStorage.getItem('portal_account_id2')
   this.d2c_name = localStorage.getItem('d2c_name2')

 }
 else{
   this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
   this.portal_account_id = localStorage.getItem('portal_account_id')
   this.d2c_name = localStorage.getItem('d2c_name')
 }
 let data = {
   'portal_name': this.portal_name,
   'customer_name':this.fullData.customer_name,
   'email_id':this.fullData.email_id,
   'awb':this.fullData.awb,
   'order_id': this.fullData.order_id,
   'phone': this.fullData.phone,
   'portal_id':this.portal_id,
   'portal_account_id':this.portal_account_id
 }
 this.authService.resend_invoice(data).subscribe((res: any) => {
   let mes = JSON.parse(res);
   if (mes.statusCode === 200) {
     this.showOverlay = false;
     this.toastr.success(mes.success, ''); 



}
else{
 if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
   this.authService.logout()
   this.showOverlay=false
   this.router.navigate(['/auth/login'])
 }
 else{
   this.toastr.error(res.error, "");
   this.showOverlay = false; 
   this.modalService.dismissAll('Close click');
   this.userHolidayCreate.reset()
   // console.log('error mesg',res)
 }

 // this.showOverlay = false;

}
})

}
not_allow_only_num_and_spl_char(value: any,inpu_feild_name:any){
  if (value) {
    if (/^[0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only numbers. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
    if (/^[^a-zA-Z0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only special characters. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
  }
  return null;
}
only_spl_and_num_not_accept(event: any,input_name:any,){
  const value = event.target.value;
    this.not_allow_only_num_and_spl_char(value,input_name); 
  
}




}
