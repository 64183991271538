import { Component,ViewChild,OnInit, OnDestroy  } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountService } from 'src/app/core/services/count.service';

@Component({
  selector: 'app-ticketview',
  templateUrl: './ticketview.component.html',
  styleUrls: ['./ticketview.component.scss']
})
export class TicketviewComponent {

  showOverlay=false
  fullData:any;
  inputText:any;
  createuserform1: UntypedFormGroup;
  searchdata:any;
  username:any
  myMessageData1:any;
  storedUsername:any
  private reloadTimer: any;
  portal_account_id:any;
  portal_id:any;
  d2c_name:any;
  portal_name:any;
  superuser:any;
  userdata:any;
  chatbot_msgs: any;
  assigned_to_ticket: any;
  task_details_disposition: any;
  task_details_status: any;



   

  constructor(
    private authService:AuthenticationService,
    private modalServicee: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private  countservice :CountService


    ){
  
  }
  ngOnInit(): void {
    this.ticket_details();
    this.assigned_to()

    this.createuserform1 = this.formBuilder.group({
      status: ['open', [Validators.required]],
      disposition: ['pending', [Validators.required]],
      assigned_to: [''],

  
    });
    this.reloadTimer = setInterval(() => {
      location.reload();
    }, 5 * 60 * 1000);

    this.storedUsername = localStorage.getItem('username');
    if (this.storedUsername) {
      this.myMessageData.push({ [this.storedUsername]: '' });
      console.log('this.myMessageData',this.myMessageData)
    }
    this.username = localStorage.getItem('username')


    



  
  
  }

  ngOnDestroy(): void {
    // Stop the reload timer when the component is destroyed
    clearInterval(this.reloadTimer);
  }
  ticket_details(){
    let data ={
      'ticket_id':localStorage.getItem('ticket_id')

    }
    this.showOverlay=true
    this.authService.ticket_details(data).subscribe((res: any) => {
      // console.log(res,'ticket data');
      let mes = JSON.parse(res);
      console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay=false
        this.fullData = mes.body[0]
        localStorage.setItem('ticket',mes.body[0].ticket_id) 
          this.searchdata = mes.body[0].support_chat;
          console.log(this.searchdata, 'ticketview data');  
          this.chatbot_msgs=mes.body[0].support_chat
          this.assigned_to_ticket=mes.body[0].assigned_to
          this.task_details_disposition=mes.body[0].disposition
          this.task_details_status=mes.body[0].status
          console.log(this.assigned_to_ticket);
          
        
            //         const storedUsername = localStorage.getItem('username');

            // this.searchdata = this.searchdata.filter(item => {
            //   if (item.hasOwnProperty(storedUsername)) {
            //     this.myMessageData.push(item);
            //     console.log('Data pushed to myMessageData:', item);
            //     return false; 
            //   }
            //   return true; 
            // });
            // console.log('searchdata after removal:', this.searchdata);
            // console.log('myMessageData:', this.myMessageData);
            const storedUsername = localStorage.getItem('username');

            if( this.searchdata && this.searchdata.length > 0){
              this.searchdata = this.searchdata.filter(item => {
                if (item.hasOwnProperty(storedUsername)) {
                  this.myMessageData.push(item);
                  console.log('Data pushed to myMessageData:', item);
                  return false;
                }
                return true;
              });
            }
            // console.log('searchdata after removal:', this.searchdata);
            // console.log('myMessageData:', this.myMessageData); 
                    // this.toastr.success(mes.message, "");
              }
              else{
                if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
                  // this.authService.logout()
                  this.showOverlay=false
                  this.router.navigate(['/auth/login'])
                }
                else{
                  this.toastr.error(mes.error, "");
                  this.showOverlay = false; 
              
                }
                
              }
        },
        (error) => {
          this.showOverlay = false;
          if (error.status === 502) {
            this.toastr.error("502 Bad Gateway", "An error occurred");
          } else {
            this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
          }
    });
  
  }

  name = 'Angular';

  data = [
    {title: 'Harvey Specter'},
    {title: 'Donna'},
    {title: 'Louis Litt'},
    {title: 'Jessica'},
    {title: 'Mike Ross'},
    {title: 'Rachel'},
    {title: 'Katrina'}
  ];

  receiverData = [
    {time : '2018-08-29 11:35:20', msg:'Hi'},
    {time : '2018-08-29 11:35:20', msg:'Hello'},
    {time : '2018-08-29 11:35:20', msg:'This is an automated message'},
    {time : '2018-08-29 11:35:20', msg:'This is an automated message'},
    {time : '2018-08-29 11:35:20', msg:'This is an automated message'},
    {time : '2018-08-29 11:35:20', msg:'This is an automated message'}
  ];
  myMessageData = [
    // {username: 'hi'}
  ];

  myMessage(text:any){
    const username = localStorage.getItem('username')
    // console.log('this.username',this.username)
    let messageObject:any = {};
    // console.log(text);
    messageObject[username] = messageObject[username] || [];
    messageObject[username]= this.inputText
    // console.log(messageObject,'iuytrsdfgh');

    // messageObject[username] = this.inputText
    this.myMessageData.push(messageObject);
    // console.log(this.myMessageData);
    this.inputText = '';

    this.myMessageData1 = this.myMessageData.concat(this.searchdata);
    // console.log(this.searchdata);
    
    this.ticket_update1(this.myMessageData1)



    // this.myMessageData.push(this.searchdata);
        // console.log(this.myMessageData);




    // console.log(this.el.nativeElement)
    // setTimeout(()=>{
    //   this.el.nativeElement.scrollTop[0].scrollHeight;
    // }, 100)
    // if(!shouldScroll){
    //   messages.scrollTop;
    //   console.log('hello')
    //   messages.scrollTop = messages.scrollHeight;
    // }
  }
  ticket_update(){
    this.showOverlay=true
    let data = this.createuserform1.value
    data['ticket_id']= localStorage.getItem('ticket')
    this.authService.ticket_update(data).subscribe((res: any) => {
      // console.log(res,'ticket data');
      let mes = JSON.parse(res);
      // console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay=false
        this.toastr.success(mes.success, "");
        this.countservice.unread_ticket();



  }
  else{
    if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(mes.error, "");
      this.showOverlay = false; 
      this.toastr.error(mes.error, "");


  
    }
  
  
  }
  },
  (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });

  }
  ticket_update1(list:any){
    this.showOverlay=true


    let data = {
      'ticket_id': localStorage.getItem('ticket'),
      'is_superuser': localStorage.getItem('is_superuser'),
      'support_chat':list

    }
    // let data = list
    // const ticket_id = localStorage.getItem('ticket')
    this.authService.ticket_update(data).subscribe((res: any) => {
      // console.log(res,'ticket data');
      let mes = JSON.parse(res);
      console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay=false

  }
  else{
    if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(mes.error, "");
      this.showOverlay = false; 
  
    }
  
  
  }
  },
  (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });

  }
  assigned_to(){
    this.superuser = localStorage.getItem('is_superuser')

    if(this.superuser == 'true'){
            this.portal_name = localStorage.getItem('portal_name2')
            this.portal_id = localStorage.getItem('portal_id2')
            this.portal_account_id = localStorage.getItem('portal_account_id2')
            this.d2c_name = localStorage.getItem('d2c_name2')
      
          }
          else{
            this.portal_name = localStorage.getItem('portal_name')
             this.portal_id = localStorage.getItem('portal_id')
            this.portal_account_id = localStorage.getItem('portal_account_id')
            this.d2c_name = localStorage.getItem('d2c_name')
          }
    let data = {
      'portal_account_id': this.portal_account_id
    }
    this.authService.assigned_to(data).subscribe((res) => {
      let mes=JSON.parse(res)
      res =mes 
      if (res.statusCode>=200 && res.statusCode<=205) {
        this.showOverlay = false;
        console.log(res,'get data res')
        this.userdata = res.body

     
      //   console.log("mesg", res.message); 
      // console.log(this.length,'lengthhhhhhhh')
  
      } 
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          // this.authService.logout()
          this.showOverlay=false
          this.router.navigate(['/auth/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
          // console.log('error mesg',res)
        }
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    }); 

}

make_obj:any={}
show_make_my_msg:any[]=[]
addmsg(data:any){
  this.inputText=''
  const username = localStorage.getItem('username') 
  this.make_obj[username]=data   
  this.show_make_my_msg = this.show_make_my_msg || [];
  this.show_make_my_msg.push(data);
  this.chatbot_msgs = this.chatbot_msgs || [];
  this.chatbot_msgs.push({ ...this.make_obj });
  // this.chatbot_msgs.push(this.make_obj);
  console.log(this.show_make_my_msg,this.chatbot_msgs);   
  this.make_obj={}
  this.ticket_update1(this.chatbot_msgs)

}

 



}
  
