import { Component,inject,TemplateRef } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators,FormArray, UntypedFormGroup} from '@angular/forms';
interface Order {
  order_id: string;
  selling_price: number;
  order_date: string;
  dispatch_by_date: string;
  qty: number;
  payment_method: string;
  customer_name: string;
  phone: string;
  email_id: string;
  address: string;
  awb: string;
  status: string;
  pincode: string;
  warehouse_name: string;
  shopify_order_id: string;
  tracking_url: string;
}



@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {
  showOverlay = false
  data:any
  fullData:any
  searchdata:any
  order_id:any
  createuserform4: UntypedFormGroup;
  searchdata1:any
  searchdata2:any
  superuser:any;
  d2c_name:any;
  portal_name:any;
  portal_account_id: any;
  payment_method:any;
  portal_id:any;
  reason = new FormControl(null,[Validators.required]);
  userHolidayCreate: FormGroup;
  private modalService = inject(NgbModal);
  loading:boolean = false
  status: any;
  data99:any
  // reason = new FormControl();
  constructor(
    private authService:AuthenticationService,
    private modalServicee: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder){

  }


  ngOnInit(): void {
    this.isButtonVisible = new Array(10).fill(true); 
    this.fullData = JSON.parse(localStorage.getItem('serachdata') || '[]');
    console.log('this.fullDatahgfdsdfghlkjhgf',this.fullData)
    // this.order_status();
    this.order_id = this.fullData[0].order_id
    this.modalService.dismissAll('Close click');
    this.superuser = localStorage.getItem('is_superuser')
    this.payment_method = this.fullData[0].payment_method
    console.log(this.payment_method)
    this.userHolidayCreate = this.fb.group({
      reason: ['', Validators.required],
      name:['',Validators.required],
      phone:['',Validators.required],
      email: ['', [Validators.required]],
      address:['',Validators.required],
      ifsc:[''],
      account_id:[''],
      pincode:['']

    });

    this.createuserform4 = this.fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      email_id: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      awb: ['', [Validators.required]],
      location_latitude: ['', [Validators.required]],
      location_longitude: ['', [Validators.required]],
      status: ['', [Validators.required]],

    });

    const data = this.fullData;

    const groupedOrders: Record<string, Order[]> = data.reduce((acc, order) => {
      acc[order.shopify_order_id] = acc[order.shopify_order_id] || [];
      acc[order.shopify_order_id].push(order);
      return acc;
    }, {} as Record<string, Order[]>);

    const count = Object.values(groupedOrders).reduce((total, orders) => {
      const uniqueOrderIds = new Set(orders.map(order => order.order_id));
      if (uniqueOrderIds.size > 1) {
          total += orders.length; 
      }
      return total;
  }, 0);
  
  console.log(count); 
  this.data99 = count
  




  }

  initform1(){
    this.userHolidayCreate = this.fb.group({
      reason: ['', Validators.required],
      name:[this.name,Validators.required],
      phone:[this.phone,Validators.required],
      email: [this.email, [Validators.required]],
      address:[this.address,Validators.required],
      ifsc:[''],
      account_id:[''],
      pincode:['']

    });
  }


  private initForm(){
    this.userHolidayCreate=new FormGroup({
      // holiday_id:this.holiday_id,
      reason:this.reason,
   
    
    })
    
  }
  statusData: Array<{is_return_avialable: boolean, is_cancel_avialable: boolean}> = [];
  number:any
  public isButtonVisible: boolean[] = [];


  order_status(data1:any,index:any){

    console.log(data1,index)


    this.number = index
    localStorage.setItem('index',this.number)
    this.showOverlay = true;
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else{
      this.portal_name = localStorage.getItem('portal_name')
       this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let data ={
      'portal_id':this.portal_id,
      'portal_account_id':this.portal_account_id,
      'order_id': data1,
      // 'payment_method':this.fullData[0].payment_method,
      // 'reason': this.order_id
    }
    // console.log(data,'kjhgfdsasdfghjklkjhgfd')
    this.authService.order_status(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      // console.log(mes);
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.toastr.success(mes.message, ''); 


        this.isButtonVisible[index] = false;
      

        this.statusData[index] = {
          is_return_avialable: mes.is_return_avialable,
          is_cancel_avialable: mes.is_cancel_avialable
        };
        // // this.toastr.success(mes.message, ''); 
        // this.searchdata1 = mes.is_return_avialable
        // this.searchdata2 = mes.is_cancel_avialable
        // // console.log(mes.is_return_avialable,'this.searchdata1')

  }
  else{
    if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(res.error, "");
      this.showOverlay = false; 
      // console.log('error mesg',res)
    }


  }
})


}
update_status(order:any){
  this.loading = true
  this.showOverlay = true
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  
  
  let data ={
    'portal_id':this.portal_id,
    'portal_account_id':this.portal_account_id,
    'type': order,
    'order_id': localStorage.getItem('edit_id1'),
    'payment_method':localStorage.getItem('edit_id2'),
    'reason':  this.userHolidayCreate.value.reason,
    'ifsc':  this.userHolidayCreate.value.ifsc,
    'address':  this.userHolidayCreate.value.address,
    'name':  this.userHolidayCreate.value.name,
    'email':  this.userHolidayCreate.value.email,
    'phone':  this.userHolidayCreate.value.phone,
    'pincode':  localStorage.getItem('edit_id3')    ,
    'account_id':this.userHolidayCreate.value.account_id






  }
  // this.order_status(localStorage.getItem('edit_id1'),localStorage.getItem('index'));

  this.authService.update_status(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.toastr.success(mes.success, ''); 
      this.modalService.dismissAll('Close click');
this.userHolidayCreate.get('reason').reset(); 
this.ordersearch()
this.order_status(localStorage.getItem('edit_id1'),localStorage.getItem('index'));
this.loading = false



}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    this.authService.logout()
    this.showOverlay=false
    this.loading = false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.loading = false
    this.modalService.dismissAll('Close click');
    this.userHolidayCreate.get('reason').reset(); 
  }


}
}, (error) => {
  this.showOverlay = false;
});


  

}

open(content: TemplateRef<any>,data:any) {
  console.log(data)
  this.name = data.customer_name
  this.phone = data.phone
  this.email = data.email_id
  this.address = data.address
  this.pincode = data.pincode
  localStorage.setItem('edit_id1',data.order_id)
  localStorage.setItem('edit_id2',data.payment_method)
  localStorage.setItem('edit_id3',data.pincode)
  this.initform1()
  this.modalService.open(content, { centered: true,size: 's'});
  this.showReasonInput = false; // Initialize the input field visibility

}
name:any
phone:any;
email:any
address:any;
pincode:any;
open1(content1: TemplateRef<any>,data:any) {
  console.log(data)
  this.name = data.customer_name
  this.phone = data.phone
  this.email = data.email_id
  this.address = data.address
  this.pincode = data.pincode
  localStorage.setItem('edit_id1',data.order_id)
  localStorage.setItem('edit_id2',data.payment_method)
  localStorage.setItem('edit_id3',data.pincode)
  this.initform1()
  this.modalService.open(content1, { centered: true,size: 's'});
}
route(){
  this.router.navigate(['/page/retailripple'])
}

cancelReason: string = '';
showReasonInput: boolean = false;




showInput() {
  this.showReasonInput = true;
}
numericOnly(event:any): boolean {    
  let patt = /^[0-9]+$/;
  let result = patt.test(event.key);
  return result;
}
alphanumericOnly1(event: KeyboardEvent): void {
  const inputElement = event.target as HTMLInputElement;
  const currentValue = inputElement.value;

  const allowedCharsPattern = /^[a-zA-Z0-9]+$/;
  const isAllowedChar = allowedCharsPattern.test(event.key);
  if (!isAllowedChar) {
    event.preventDefault();
    return;
  }
  const newValue = currentValue + event.key.toUpperCase(); // Convert to uppercase and add to the current value
  inputElement.value = newValue; // Update the input field value
  inputElement.dispatchEvent(new Event('input')); // Trigger an 'input' event to update Angular model
  event.preventDefault(); // Prevent the default keypress behavior
}

send_details(data1:any){
  this.showOverlay = true
  console.log(data1)
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = {
    'portal_name': this.portal_name,
    'customer_name':data1.customer_name,
    'email_id': data1.email_id,
    'awb':data1.awb,
    'tracking_url': data1.tracking_url,
    'address':data1.address,
    'phone':data1.phone
  }
  console.log(data)
  this.authService.send_details(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.toastr.success(mes.success, ''); 



}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.userHolidayCreate.reset()
  }


}
})
}
resend_invoice(data1:any){
  this.showOverlay = true
  console.log(data1,'data1')
   this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = {
    'portal_name': this.portal_name,
    'customer_name':data1.customer_name,
    'email_id': data1.email_id,
    'awb':data1.awb,
    'order_id': data1.order_id,
    'phone': data1.phone,
    'portal_id':this.portal_id,
    'portal_account_id':this.portal_account_id
  }
  console.log(data)
  this.authService.resend_invoice(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.toastr.success(mes.success, ''); 



}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.userHolidayCreate.reset()
  }


}
})

}
not_allow_only_num_and_spl_char(value: any,inpu_feild_name:any){
  if (value) {
    if (/^[0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only numbers. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
    if (/^[^a-zA-Z0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only special characters. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
  }
  return null;
}
only_spl_and_num_not_accept(event: any,input_name:any,){
  const value = event.target.value;
    this.not_allow_only_num_and_spl_char(value,input_name); 
  
}


ordersearch(){
  this.showOverlay = true;

  this.superuser = localStorage.getItem('is_superuser')

  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }

  this.data =  JSON.parse(localStorage.getItem('dataforsearch') || '[]');
  console.log(this.data)
 this.authService.orders_search(this.data).subscribe((res: any) => {
  // console.log(res);
  let mes = JSON.parse(res);
  // console.log(mes);
  if (mes.statusCode === 200) {
    // this.modalButton.nativeElement.click();
    this.searchdata = mes.Data
    console.log(this.searchdata,'this.serachdata')
    localStorage.setItem('serachdata', JSON.stringify(this.searchdata)); 
    // this.router.navigate(['/page/retailripple/details'])
    this.showOverlay = false;

    if (this.router.url === '/page/retailripple/details') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/page/retailripple/details']);
      });
    } else {
      this.router.navigate(['/page/retailripple/details']);
    }
  


  
   
  } 
  else{
    this.showOverlay = false;
    this.toastr.error(mes.error, 'Error')
  



  }
 })

}



name11:any
phone11:any
email_id11:any;
awb11:any
address11:any
pincode11:any
status11:any

openVerticallyCentered2(content: TemplateRef<any>,data:any) {
  this.name11 = data.customer_name
  this.phone11 = data.phone
  this.email_id11 = data.email_id
  this.awb11 = data.awb
  this.address11 = data.address
  this.pincode11 = data.pincode
  this.status11 = data.status
  localStorage.setItem('edit_id',data.order_id)


  this.modalService.open(content, { centered: true,size: 'lg'});
}


update_order(){
  this.superuser = localStorage.getItem('is_superuser')

  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = this.createuserform4.value
 data['order_id'] = localStorage.getItem('edit_id')
 data['portal_id'] = this.portal_id
 data['portal_account_id'] = this.portal_account_id
  this.authService.update_order(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.toastr.success(res.success);
       this.modalService.dismissAll()

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}



}
