<h1 class="page col-md-6 mb-4 mt-2">Order Tickets View</h1>
<div class="d-flex justify-content-between mt-5 laptop">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup)="applyFilter1($event.target)">
        <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-end page-top">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="onPageChange($event)"></mat-paginator>
    </div>
</div>
<div class="justify-content-center mt-5 mobile">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup)="applyFilter1($event.target)">
        <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-center page-top mt-3">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="onPageChange($event)"></mat-paginator>
    </div>
</div>
<!-- <div class="d-flex justify-content-end"> 
    <div class="border text-whhite d-flex justify-content-between" >
        <div class="position-relative col-md-3">
            <input type="text" class="form-control search-input" placeholder="Search...." (keyup)="applyFilter1($event.target)">
            <span class="ri-search-line search-icon"></span>
        </div>
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="length"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="pageEvent=fetchData($event)"></mat-paginator>
    </div>
</div> -->
<button type="button" class="btn btn-sm btn-light mt-3"  [ngClass]="{ 'bg-primary1': currentFilter === 'open', 'bg-light1': currentFilter !== 'open' }" (click)="setFilter('open')" style="color: black!important;">Open</button>
<button type="button" class="btn btn-sm btn-light mt-3  ms-3"  [ngClass]="{ 'bg-primary1': currentFilter === 'close', 'bg-light1': currentFilter !== 'close' }" (click)="setFilter('close')" style="color: black!important;">Close</button>
<button type="button" class="btn btn-sm btn-light mt-3  ms-3"  [ngClass]="{ 'bg-primary1': currentFilter === 'all', 'bg-light1': currentFilter !== 'all' }" (click)="setFilter('all')" style="color: black!important;">All</button>
<div class="row d-flex justify-content-center p-4">
    <div class="card-body" style="background-color:transparent;">
        <div class="material-datatables">
            <div class="position-relative overflow-auto">
                <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers" matSort>
                    <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i"
                        [stickyEnd]="tableheader == 'actions'">
                        <!-- <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold"
                            mat-sort-header style="white-space: nowrap;">
                            {{ headerData[tableheader] }}
                        </th> -->
                        <ng-container *ngIf="tableheader === 'ticket_id'">
                            <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold"
                                mat-sort-header style="white-space: nowrap;">
                                Ticket Id & Order Id
                            </th>
                            <!-- <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span [style.color]="isUnreadTicket(row['ticket_id']) ? 'red' : 'blue'" (click)="ticket_details(row)">{{
                                    row['ticket_id'] }}</span><br>
                                <span>{{ row['order_id'] }}</span>

                            </td> -->
                            <!-- <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;" *matCellDef="let row; index as i">
                                <span [style.color]="isUnreadTicket(row['ticket_id']) ? 'red' : 'blue'" style="cursor: pointer;" 
                                      [ngClass]="{'clickable': isUnreadTicket(row['ticket_id'])}"
                                      (click)="isUnreadTicket(row['ticket_id']) ? ticket_update(row['ticket_id']) : ticket_details(row)">
                                  {{ row['ticket_id'] }}
                                </span><br>
                                <span>{{ row['order_id'] }}</span>
                            </td> -->
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;" *matCellDef="let row; index as i">
                                <span [style.color]="isUnreadTicket(row['ticket_id']) ? 'red' : 'blue'" style="cursor: pointer;" 
                                      [ngClass]="{'clickable': isUnreadTicket(row['ticket_id'])}"
                                      (click)="handleTicketClick(row)">
                                  {{ row['ticket_id'] }}
                                </span><br>
                                <span>{{ row['order_id'] }}</span>
                            </td>
                              
                              
                        </ng-container>
                        <ng-container *ngIf="tableheader !== 'ticket_id'">
                            <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold"
                                mat-sort-header style="white-space: nowrap;">
                                {{ headerData[tableheader] }}
                            </th>
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container>

                        <!-- <ng-container *ngIf="tableheader === 'ticket_id' || tableheader === 'order_id'">
                            <td mat-cell class="text-center align-middle"
                                style="white-space: nowrap; font-size: 12px; cursor: pointer; color: blue;"
                                *matCellDef="let row; index as i" (click)="ticket_details(row)">
                                <span>{{ row['ticket_id'] }}</span><br>
                                <span>{{ row['order_id'] }}</span>
                                    
                            </td>
                        </ng-container>
                        <ng-container *ngIf="tableheader !== 'ticket_id' && tableheader !== 'order_id'">
                            <td mat-cell class="text-center align-middle"
                                style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container> -->

                        <!-- <ng-container *ngIf="tableheader=='ticket_id'">
                            <td mat-cell class="text-center align-middle"
                            style="white-space: nowrap; font-size: 12px; cursor: pointer; color: blue;"
                            *matCellDef="let row; index as i" (click)="ticket_details(row)">
                            <span>{{ row[tableheader] }}</span>
                        </td>
                        </ng-container> -->
                        <!-- <ng-container *ngIf="tableheader=='raised_by'">
                            <td mat-cell class="text-center align-middle"
                            style="white-space: nowrap; font-size: 12px; cursor: pointer;"
                            *matCellDef="let row; index as i" >
                            <span>{{ row[tableheader] }}</span><span><img src="assets/images/edit22.png" height="20px" width="20px"></span>
                        </td>
                        </ng-container> -->
                        <!-- <ng-container *ngIf="tableheader !== 'ticket_id'">
                            <td mat-cell class="text-center align-middle"
                                style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container> -->

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

            </div>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom">
            <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
              [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="onPageChange($event)"></mat-paginator>
        </div>
    </div>


</div>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>