<div class="d-flex justify-content-between">
  <h1 class="page col-md-6 mb-4 mt-2">Refunds List</h1>
  <button class="bg-primary p-1 me-4"  style="border: 0px; border-radius: 5px; color: white; height: 45px;" (click)="openVerticallyCentered4(partialrefund)">Create Partial Refund</button>

</div>
<div class="d-flex justify-content-between mt-5 laptop">
  <div class="position-relative col-md-3">
    <input type="text" class="form-control search-input" placeholder="Search...." (keydown.enter)="applyFilter1($event.target.value)">
    <span class="ri-search-line search-icon"></span>
  </div>
  <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="searchchar === true">
    <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
      [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData1($event,value)"></mat-paginator>
  </div>
  <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="searchchar === false">
    <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
      [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
  </div>
</div>
<div class="justify-content-center mt-5 mobile">
  <div class="position-relative col-md-3">
    <input type="text" class="form-control search-input" placeholder="Search...." (keydown.enter)="applyFilter1($event.target.value)">
    <span class="ri-search-line search-icon"></span>
  </div>

  <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="searchchar === true">
    <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
      [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData1($event,value)"></mat-paginator>
  </div>
  <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="searchchar === false">
    <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
      [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
  </div>
</div>
<div class="row d-flex justify-content-center p-4">
  <div class="card-body" style="background-color:transparent;">
    <div class="material-datatables">
      <div class="position-relative overflow-auto">
        <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers" matSort>
          <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i">
            <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold" mat-sort-header
              style="white-space: nowrap;">
              {{ headerData[tableheader] }}
            </th>
            <ng-container *ngIf="tableheader === 'remarks'">
              <td mat-cell class="text-center align-middle" *matCellDef="let row; index as i"
                style="white-space: nowrap; font-size: 12px;">
                <ul>
                  <li *ngFor="let remark of row[tableheader]">
                    {{ remark }}
                  </li>
                </ul>
              </td>
            </ng-container>
            <ng-container *ngIf="tableheader=='reference_path'">
              <td mat-cell class="text-center align-middle"
                  *matCellDef="let row; index as i">
                  <div>
                      <img src="assets/images/download.png" width="24px" height="24px" title="Download" style="cursor: pointer;" (click)="downloadInvoice1(row)">
                  </div>
              </td>
            </ng-container>
            <ng-container *ngIf="tableheader=='actions'">
              <td mat-cell class="text-center align-middle" *matCellDef="let row; index as i" style="font-size: 12px;">
                <span style="cursor: pointer;" [ngClass]="{
                  'disabled-icon': ( row.payment_method === 'PREPAID') && get_supper_user !== 'true'
                }">
                  <img src="assets/images/edit22.png" height="20px" width="20px" (click)="open1(content1, row)">
                </span>
              </td>
            </ng-container>
            <ng-container *ngIf="tableheader !== 'actions'">
              <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                *matCellDef="let row; index as i">
                <span>{{ row[tableheader] }}</span>
              </td>
            </ng-container>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="searchchar === true">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData1($event,value)"></mat-paginator>
    </div>
    <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
  </div>
  <ng-template #content1 let-modal>
    <div class="modal-header">
      <div class="d-flex">
        <button  class="p-2"
          [ngClass]="{'active-button': activeButton === 'update'}" style="border: 0px;border-radius: 5px;"
          (click)="setActiveButton('update')">
          Update
        </button>
        <button 
          class="ms-2 p-2"   *ngIf="payment_method11 === 'Prepaid' && superuser === 'true'" 
          [ngClass]="{'active-button': activeButton === 'refund'}"
          (click)="setActiveButton('refund'); open2(content2)" 
          style="cursor: pointer; border: 0px;border-radius: 5px;">
          Refund
        </button>
      </div>
      <!-- <div class="d-flex">
        <button>Update</button>
        <button class="ms-2"  (click)="open2(content2)" style="cursor: pointer;">Refund</button>
        <h6 class="ms-2" *ngIf="payment_method11 === 'Prepaid'" (click)="open2(content2)">Refund</h6>
      </div> -->
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createuserform1">
        <div class="row col-md-12">
          <div class="col-md-6 mb-3"  *ngIf="superuser === 'true'" >
            <label for="status">Status</label>
            <select formControlName="status" class="form-select" id="stajtus1">
              <option value="open">Open</option>
              <option value="close">Close</option>
            </select>
          </div>
          <div class="col-md-6 mb-3" *ngIf="superuser === 'false'">
            <label for="status">Status</label>
            <input type="text" formControlName="status" class="form-control"  [(ngModel)]="order_status" readonly
              placeholder="Status"   />
          </div>          
          <div class="col-md-6 mb-3" *ngIf="rowData2 === null">
            <label for="agent_remark">Account Id</label>
            <input type="text" formControlName="account_id" class="form-control" id="agent_remark"
              placeholder="Account Id" (keypress)="numericOnly($event)"  />
            <div *ngIf="createuserform1!.get('account_id')?.invalid && createuserform1!.get('account_id')?.touched"
              class="text-danger">
              Account Id field is required
            </div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="rowData1 === null">
            <label for="agent_remark">IFSC Code</label>
            <input type="text" formControlName="ifsc" class="form-control" id="ifsc" placeholder="IFSC Code"
              (keypress)="alphanumericOnly1($event)"  />
            <div *ngIf="createuserform1!.get('ifsc')?.invalid && createuserform1!.get('ifsc')?.touched"
              class="text-danger">
              Please Enter Valid IFSC Code
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="agent_remark">Remarks <span style="color: red;">*</span></label>
            <input type="text" formControlName="remarks" class="form-control" id="remarks" placeholder="Remarks"
              (input)="onRemarksChange()" />
            <div *ngIf="createuserform1!.get('remark')?.invalid && createuserform1!.get('remark')?.touched"
              class="text-danger">
              Remarks field is required
            </div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="get_supper_user === 'true'">
            <label for="agent_remark">Reference Path</label>
            <input class="form-control" type="file" accept=".pdf"  id="fakeFileInput1" formControlName="reference_path" (change)="GetFileOnLoad1($event)">
            <div *ngIf="createuserform1!.get('reference_path')?.invalid && createuserform1!.get('reference_path')?.touched"
              class="text-danger">
              Reference Path field is required
            </div>
          </div>


        </div>


      </form>
    </div>

    <div class="modal-footer d-flex justify-content-center">

      <button type="button" class="btn btn-success" style="width: 80px;" (click)="update_refund()" [disabled]="loading">Update</button>
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinner [overlay]="true" id="loader"></app-spinner>
    </ng-container>

  </ng-template>
  <ng-template #content let-modal>
    <div class="modal-header">
      <button type="button" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
        <img src="assets/images/warning.png">

      </div>
      <h3 class="d-flex justify-content-center">Are You Sure !</h3>
      <p class="d-flex justify-content-center">You want to Delete this order</p>

    </div>
    <div class="modal-footer d-flex justify-content-center">

      <button type="button" class="btn btn-success" style="width: 80px;">Yes</button>
      <button type="button" class="btn btn-danger" style="width: 80px;">No</button>
    </div>
  </ng-template>
  <ng-template #content2 let-modal>
    <div class="modal-header">
      <div class="d-flex">
        <button  class="ms-2 p-2"
          [ngClass]="{'active-button': activeButton === 'update'}" style="border: 0px;border-radius: 5px;"
          (click)="setActiveButton('update'); open1(content1,datafrom)">
          Update
        </button>
        <button 
        class="ms-2 p-2"   *ngIf="payment_method11 === 'Prepaid'" 
        [ngClass]="{'active-button': activeButton === 'refund'}"
        (click)="setActiveButton('refund'); open2(content2)" 
        style="cursor: pointer; border: 0px;border-radius: 5px;">
        Refund
      </button>
      </div>
      <button type="button" class="btn-close my-auto" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createuserform2">
        <div class="row col-md-12">
          <div>
            <div class="col-md-12 d-flex justify-content-between">
              <div class="col-md-6 d-flex justify-content-between">
                <p class="col-md-6">Order Id</p>
                <p class="col-md-6"> : {{this.order_id11}}</p>
              </div>
              <div class="col-md-6 d-flex justify-content-between">
                <p class="col-md-6">Payment Method</p>
                <p class="col-md-6"> : {{this.fullData11.payment_method}}</p>
              </div>
            </div>
          
            <div class="col-md-12 d-flex justify-content-between">
              <div class="col-md-6 d-flex justify-content-between">
                <p class="col-md-6">Status</p>
                <p class="col-md-6"> : {{this.fullData11.status}}</p>
              </div>
              <div class="col-md-6 d-flex justify-content-between">
                <p class="col-md-6">Total Price</p>
                <p class="col-md-6"> : {{this.fullData11.total_price}}</p>
              </div>
             
            </div>
           
            <div class="col-md-12 d-flex justify-content-between">
              <div class="col-md-6 d-flex justify-content-between">
                <p class="col-md-6">Total Items</p>
                <p class="col-md-6"> : {{this.fullData11.total_items}}</p>
              </div>
              <div class="col-md-6 d-flex justify-content-between">
                <p class="col-md-6">Total Quantity</p>
                <p class="col-md-6"> : {{this.fullData11.total_quantity}}</p>
              </div>
              
            </div>
            <div class="col-md-12 d-flex justify-content-between">
              <div class="col-md-6 d-flex justify-content-between" *ngIf="fullData11.forward_awb !== '' || fullData11.forward_awb !== 'null' || fullData11.forward_awb !== null">
                <p class="col-md-6">Forward Awb</p>
                <p class="col-md-6"> : <a href="{{this.fullData11.forward_url}}" style="cursor: pointer; color: blue;" target="_blank">{{this.fullData11.forward_awb}}</a></p>
              </div>
              <div class="col-md-6 d-flex justify-content-between" *ngIf="fullData11.reverse_awb !== '' || fullData11.reverse_awb !== 'null' || fullData11.reverse_awb !== null">
                <p class="col-md-6">Reverse Awb</p>
                <p class="col-md-6"> : <a href="{{this.fullData11.reverse_url}}" style="cursor: pointer; color: blue;" target="_blank">{{this.fullData11.reverse_awb}}</a></p>
              </div>

            </div>
          
          
           
            <!-- <div class="col-md-12 d-flex justify-content-between">
              <p class="col-md-6">Tracking Url</p>
              <p class="col-md-6 url-container"> : <span class="url-text">{{this.fullData11.url}}</span></p>
            </div> -->
          </div>
          <div class="col-md-6 mb-3" *ngIf="d2c_name === 'woocommerce'">
            <label for="reason">Reason<span style="color: red;">*</span></label>
            <input type="text" formControlName="reason" class="form-control" id="reason1" placeholder="Reason"
                />
            <div *ngIf="createuserform2!.get('reason')?.invalid && createuserform2!.get('reason')?.touched"
              class="text-danger">
              Please Enter Reason
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="agent_remark">Refund Amount<span style="color: red;">*</span></label>
            <input type="text" formControlName="refund_amount" class="form-control" id="refund_amount" placeholder="Refund Amount"
               />
            <div *ngIf="createuserform2!.get('refund_amount')?.invalid && createuserform2!.get('refund_amount')?.touched"
              class="text-danger">
              Refund Amount field is required
            </div>
          </div>
        </div>
      </form>
      <p *ngIf="order_status === 'close'" style="color: red;">Kindly verify already refund has been processed</p>
    </div>
  
    <div class="modal-footer d-flex justify-content-center">
      <button type="button" class="btn btn-success" style="width: 80px;" (click)="open3(content3)" [disabled]="order_status === 'close'">Update</button>
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinner [overlay]="true" id="loader"></app-spinner>
    </ng-container>
  </ng-template>

  <ng-template #content3 let-modal>
    <div class="modal-header">
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Close click')"></button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
        <img src="assets/images/warning.png">

      </div>
      <h3 class="d-flex justify-content-center">Are You Sure !</h3>
      <p class="d-flex justify-content-center">You want to Refund this order</p>

    </div>
    <div class="modal-footer d-flex justify-content-center">

      <button type="button" class="btn btn-success" style="width: 80px;" (click)="ticket_create()" [disabled]="loading">Yes</button>
      <button type="button" class="btn btn-danger" style="width: 80px;" (click)="modal.close('Close click')">No</button>
    </div>
  </ng-template>


</div>


<ng-template #partialrefund let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Partial Refund</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform8">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="name">Order Id</label>
              <input type="text" formControlName="order_id" class="form-control" id="order_id" placeholder="Enter Order Id"
              />
              <div *ngIf="createuserform8!.get('order_id')?.invalid && createuserform8!.get('order_id')?.touched"
                class="text-danger">
                Order Id field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="last_name">Reason</label>
              <input type="text" formControlName="reason" class="form-control" id="reason" 
                placeholder="Enter Reason"  />
              <div
                *ngIf="createuserform8!.get('reason')?.invalid && createuserform8!.get('reason')?.touched"
                class="text-danger">
                Reason field is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="partial_order()"    [disabled]="createuserform8.invalid || loading" >Submit</button>
    </div>

  </div>
</ng-template>
<ng-container *ngIf="showOverlay" id="overlay-container">
  <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>