import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv' 
import * as AWS from 'aws-sdk';
import { BehaviorSubject, Observable, retry } from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private http: HttpClient,
    
    ) { }

   // Initialize AWS S3 client
  private s3 = new AWS.S3({
    accessKeyId: 'AKIA4UNHNTS6TSZG4MJN',
    secretAccessKey: 'PzMpgIpeG0xmvk30q4jstmSfty1kLIbuvyzxg0wk',
    region: 'ap-south-1', // e.g., 'us-west-1'
    signatureVersion: 'v4',
  });

  // download csv to the table data 
  csvExport(data: any, headerdata: string[], title: string) {
    let csvOptions = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      title: "",
      useBom: true,
      noDownload: false,
      headers: headerdata,
    };
    new AngularCsv(data, title, csvOptions);
  }
  // private sharedData="personal_details"
  private sharedData = new BehaviorSubject<string>('personal_details');
  sharedData$: Observable<string> = this.sharedData.asObservable();

  setData(data: string) {
    // this.sharedData = data;
    this.sharedData.next(data);
  }

  // getData(): string {
  //   return this.sharedData;
  // }

  async UploadFileToS3(bucketName:any,folderName:any,file:any ){
    
    console.log(file)
    try {
      const params = {
        Bucket: bucketName,
        Key: `${folderName}/${file.name}`,
        Body: file
      };
      console.log(params)
  
      const response = await this.s3.upload(params).promise();
      console.log('File uploaded successfully:', response);
      // You can use the 'response.Location' to display the URL of the uploaded file or do other operations.
      return response.Key
    } catch (error) {
      console.error('Error uploading file:', error);
      return error
    }
  }

  // async s3Delete(bucketName:any,folder_path:any) {  
  //   try {
  //     const params = {
  //       Bucket: bucketName,
  //       Key: folder_path,
  //     };
  //     const response =await this.s3.deleteObject(params).promise(); 
  //     console.log("File delete successfully");
  //     return response
      
  //   } 
  //   catch (error) { 
  //     console.log("error occurs while deleteing file ");  
  // } 
  // }

  // downloadFileFromS3(s3Link:any,fileName:any){
  //   const headers = new HttpHeaders()
  //   this.http.get(s3Link, { headers, responseType: 'blob' }).subscribe(response => {
  //     console.log("response",response);
      
  //     const file = new Blob([response], { type: 'application/pdf' });
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(file);
  //     link.download = fileName
  //     link.click();

  //     // Clean up after the download
  //     URL.revokeObjectURL(link.href);
  //   }, error => {
  //     console.error('Error downloading the file:', error);
  //   });
  // }

  async downloadFileFromS3(bucket: string, key: string): Promise<Blob> {
    const s3 = new AWS.S3();
    
    const params = {
      Bucket: bucket,
      Key: key
    }; 
    console.log(params)
    
    try {
      const response:any = await this.s3.getObject(params).promise();
      console.log('response',response);
      
      return new Blob([response.Body], { type: response.ContentType });
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  }

  getImageUrl(bucketName: string, key: string): string {
    return this.s3.getSignedUrl('getObject', {
      Bucket: bucketName,
      Key: key
    });
  }
}
 