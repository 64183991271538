import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  fullData:any;
  showOverlay=true;
  first_name:any;
  last_name:any;
  phone_number:any;
  email:any;
  d2c_name:any;
  portal_name:any;
  image_path:any;
  imageURL:any;
  username:any;



  constructor(private router: Router, 
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private profile_Service :ProfileService,
    private _shared : SharedService
    ){

  }

  ngOnInit(): void {
    this.fetchdata();
    this.profile_Service.fetchdata();


  }


  fetchdata(){
    this.showOverlay=true;
    this.authService.user_details().subscribe((res: any) => {
      // console.log(res)
      let mes=JSON.parse(res)
      // console.log(mes.statusCode)
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.fullData = mes.body[0];
        localStorage.setItem('fullData', JSON.stringify(this.fullData)); 
        // console.log('fullData', mes.body);
        this.first_name = this.fullData.first_name
        this.last_name = this.fullData.last_name
        this.portal_name = this.fullData.portal_name
        this.d2c_name = this.fullData.d2c_name
        this.phone_number = this.fullData.phone_number
        this.email = this.fullData.email
        this.username = this.fullData.username
        // this.image_path = this.fullData.image_path
        if (this.fullData.image_path &&this.fullData.image_path.length !== 0) {
          this.showOverlay=false
        this.imageURL = this._shared.getImageUrl('d2c-crm', this.fullData.image_path);
      } else {
          this.showOverlay=false
          this.imageURL = 'assets/images/multi-user.jpg';}
        // this.imageURL = this._shared.getImageUrl('d2c-crm', this.fullData.image_path);




      }
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          this.authService.logout1()
          this.showOverlay=false
          this.router.navigate(['/auth/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
          // console.log('error mesg',res)
        }
      }
      // else{
      //   this.showOverlay=false




      // }
    },
    (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });

  }





}
