import { Component,inject,TemplateRef,ViewChild} from '@angular/core';
import {  Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { MatOption } from '@angular/material/core';









@Component({
  selector: 'app-createuser',
  templateUrl: './createuser.component.html',
  styleUrls: ['./createuser.component.scss']
})
export class CreateuserComponent {

  createuserform: UntypedFormGroup;
  showForm: boolean = false;
  start_date = new FormControl('',[Validators.required]);
  end_date = new FormControl('',[Validators.required]);
  showOverlay = false;
  portal_name:any;
  portal_id:any;
  portal_account_id:any;
  d2c_name:any;
  superuser:any;
  username:any;
  startdate:any;
  enddate:any;
  startt_date:any;
  endd_date:any;
  option = 'all'
  private modalService = inject(NgbModal);
  @ViewChild('allSelected') private allSelected!: MatOption; 
  @ViewChild('allSelectedDepartment') private allSelectedDepatment!: MatOption; 



  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService:AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient
  ){}

  ngOnInit(): void { 
    this.initform();
  }

  initform(){
    this.createuserform = this.formBuilder.group({
      start_date: this.start_date,
      end_date: this.end_date,
      status: ['', [Validators.required]],
      warehouse: ['', [Validators.required]],

      
    });
  }


  toggleForm() {
    this.showOverlay = true
    this.showForm = !this.showForm;
    this.showOverlay = false

  }

  onStartDateSelected(event: MatDatepickerInputEvent<Date>) {
    const startDate = event.value;
    const maxEndDate = this.calculateMaxEndDate(startDate);
    this.end_date.setValue(null); // Reset the end date when start date changes
    this.end_date.enable(); // Enable the end date input
    this.end_date.setValidators([
      Validators.required,
      this.endDateValidator.bind(this, startDate, maxEndDate)
    ]);
  }
  private calculateMaxEndDate(startDate: Date): Date {
    const maxEndDate = new Date(startDate);
    maxEndDate.setDate(maxEndDate.getDate() + 30);
    return maxEndDate;
  }
  private endDateValidator(startDate: Date, maxEndDate: Date, control: FormControl): { [key: string]: any } | null {
    const endDate = control.value;
    if (endDate && endDate > maxEndDate) {
      return { maxEndDateExceeded: true };
    }
    return null;
  }

  download_orders(){
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
  
    }
    else{
      this.portal_name = localStorage.getItem('portal_name')
       this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let stmonth: number;
    let edmonth: number;
    let sstart_date: string;
    let eendDate: string;
   
   
    this.username = localStorage.getItem('username')
    this.startdate = this.createuserform.value.start_date
    this.enddate = this.createuserform.value.end_date
    let data = this.createuserform.value
    if (this.createuserform.value.start_date && this.createuserform.value.end_date) {
      const startDate = new Date(this.createuserform.value.start_date);
      const endDate = new Date(this.createuserform.value.end_date);

      stmonth = startDate.getMonth() + 1;
      edmonth = endDate.getMonth() + 1;
      sstart_date =
        startDate.getFullYear() + "-" + stmonth + "-" + startDate.getDate();
      eendDate =
        endDate.getFullYear() + "-" + edmonth + "-" + endDate.getDate();
      this.endd_date = eendDate;
      this.startt_date = sstart_date;
      console.log("check weather date is comming ", sstart_date, eendDate);
    }
    else{ 
      this.startt_date=""; 
      this.endd_date=""; 
    } 
    data['start_date'] = this.startt_date
    data['end_date'] = this.endd_date
    data['portal_id'] = this.portal_id
    data['portal_account_id'] = this.portal_account_id
    this.showOverlay=true
    this.authService.order_reports(data).subscribe((res: any) => {
      let mes = JSON.parse(res);
      if (mes.statusCode === 200) {
        this.showOverlay=false
        this.downloadFile(mes.link) 
        this.toastr.success(mes.success, "");
        this.createuserform.reset();
        this.showForm = false;

        
  
        
  
  }
  else{
    if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(mes.error, "");
      this.showOverlay = false; 
      // this.createuserform.reset();

  
    }
  
  
  }
  },
  (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
  
  }
  download_products(){
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
  
    }
    else{
      this.portal_name = localStorage.getItem('portal_name')
       this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    let stmonth: number;
    let edmonth: number;
    let sstart_date: string;
    let eendDate: string;
   
   
    this.username = localStorage.getItem('username')
    let data = {
      'portal_account_id': this.portal_account_id
    }
  
    this.showOverlay=true
    this.authService.product_reports(data).subscribe((res: any) => {
      let mes = JSON.parse(res);
      if (mes.statusCode === 200) {
        this.showOverlay=false
        this.downloadFile1(mes.link) 
        this.toastr.success(mes.success, "");
        
  
        
  
  }
  else{
    if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
      // this.authService.logout()
      this.showOverlay=false
      this.router.navigate(['/auth/login'])
    }
    else{
      this.toastr.error(mes.error, "");
      this.showOverlay = false; 
  
    }
  
  
  }
  },
  (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
  
  }

  onCancel() {
    this.showForm = false;
    this.createuserform.reset();

  }
  

 
  downloadFile(link:any) {
    const fileUrl = link;
    const headers = new HttpHeaders({
      'Content-Type': 'text/csv', 
    });
  
    this.http.get(fileUrl, { headers, responseType: 'text' })
      .subscribe((response: string) => {
        const blob = new Blob([response], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'downloaded_file.csv';
          document.body.appendChild(link);
          link.click();
        document.body.removeChild(link);
      }, error => {
        console.error('Error downloading the file', error);
      });
  }
  downloadFile1(link:any) {
    const fileUrl = link;
    const headers = new HttpHeaders({
      'Content-Type': 'text/csv', 
    });
  
    this.http.get(fileUrl, { headers, responseType: 'text' })
      .subscribe((response: string) => {
        const blob = new Blob([response], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'downloaded_file.csv';
          document.body.appendChild(link);
          link.click();
        document.body.removeChild(link);
      }, error => {
        console.error('Error downloading the file', error);
      });
  }
  
open(content1: TemplateRef<any>) {
  this.modalService.open(content1, { ariaLabelledBy: 'modal-basic-title',size: 'xs' }).result.then(
    
  );
}
selectAllState = false;  

// toggleAllSelection(set_data: any) {
//   console.log(set_data);
  
//   const projectControl = this.createuserform.get('status');
//   const allProjectIds = set_data.map((project: any) => project.value); 
//   if (this.selectAllState) {
//     projectControl?.patchValue([]);
//   } else {
//     projectControl?.patchValue(allProjectIds);
//   } 
//   this.selectAllState = !this.selectAllState;
// }
// selectAllState = false;

toggleAllSelection(set_data: any) {
  const projectControl = this.createuserform.get('status');
  const allProjectIds = set_data.map((project: any) => project.value);

  if (this.selectAllState) {
    projectControl?.patchValue([]);
  } else {
    projectControl?.patchValue(allProjectIds);

    const selectAllOption = set_data.find((option: any) => option.value === '');
    if (selectAllOption) {
      selectAllOption.selected = true;
    }
  }

  this.selectAllState = !this.selectAllState;
}


status = [
  { value: 'payment_success', label: 'Payment Success' },
  { value: 'in_transit', label: 'In Transit' },
  { value: 'payment_pending', label: 'Payment Pending' },
  { value: 'created', label: 'Created' },
  { value: 'dispatched', label: 'Dispatched' },
  { value: 'cancelled/canceled', label: 'Cancelled' },
  { value: 'rto', label: 'RTO' },
  { value: 'rto_intransit', label: 'RTO Intransit' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'shipment_issue', label: 'Shipment Issue' },
];
warehouse = [
  { value: '4', label: 'Bengaluru' },
  { value: '7', label: 'Kolkata' },
  { value: '3', label: 'Mumbai' },
  { value: '10', label: 'Delhi' },
  { value: '12', label: 'Hyderabad' },

];
selectAllStateDepartment = false;
toggleAllSelectiondepartment(set_data: any) {
  console.log(set_data);
  const projectControl = this.createuserform.get('warehouse');
  const allProjectIds = set_data.map((project: any) => project.value); 
  if (this.selectAllStateDepartment) {
    projectControl?.patchValue([]);
  } else {
    projectControl?.patchValue(allProjectIds);
  } 
  this.selectAllStateDepartment = !this.selectAllStateDepartment;
}
  

}
