<ng-container *ngIf="overlay; else progressSpinner">
    <div class="overlay">
      <div class="center d-inline">
        <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
  
      </div>
    </div>
  </ng-container>
  <ng-template #progressSpinner>
    <div class="twitter-like">
        <!-- <div class="container">
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
        </div> -->
        <div class="loader">
          <div class="cell d-0"></div>
          <div class="cell d-1"></div>
          <div class="cell d-2"></div>
        
          <div class="cell d-1"></div>
          <div class="cell d-2"></div>
          
          
          <div class="cell d-2"></div>
          <div class="cell d-3"></div>
          
          
          <div class="cell d-3"></div>
          <div class="cell d-4"></div>
          
          
        </div>
        <!-- <img src="../../../assets/images/animation_500_ljdt1kco (1).gif"  alt="" height="100px" id="loader"> -->

     
    </div>
  </ng-template>