<div class="row">
    <div class="d-flex justify-content-between mb-3"  *ngIf="this.apiData && this.apiData.length > 0">
        <div>
            <p><b>Credited Amount : </b>{{amount}}</p>
            <p><b>Used Credited Amount : </b>{{amount1}}</p>
        </div>
    </div>
    <div class="col-lg-12 d-flex justify-content-between laptop">
      <div class="col-lg-3 text-center mb-3">
          <label for="optionSelect">Select Option:</label>
          <select  id="select" class="form-select" [(ngModel)]="selectedOption" (change)="onSelectChange($event)">
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
          </select>
      </div>
      <div class="d-flex justify-content-between">
        <div class="mt-5">
          <h5 style="text-transform: capitalize;">{{selectedOption}} Analysis</h5>
        </div>
        <div class="d-flex justify-content-end mb-3">
          <div>
              <mat-form-field appearance="outline">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate [formControl]="start_date" placeholder="Start date"
                    (dateInput)="onStartDateSelected($event)">
                  <input matEndDate [formControl]="end_date" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
        
              </mat-form-field>
              <!-- <div *ngIf="end_date.hasError('maxEndDateExceeded') && end_date.touched">
                <mat-error>End date must be within 30 days from the start date.</mat-error>
              </div> -->
            </div>
            <button class="bg-primary mt-1 me-2 ms-2" (click)="fetchData2()"
              style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"
              >
              Submit
            </button>
      </div>
      </div>
     
  </div>
  <div class="col-lg-12 justify-content-center text-center mobile mt-2 mb-3">
    <div class="col-lg-3 text-center mb-3">
        <label for="optionSelect">Select Option:</label>
        <select  id="select" class="form-select" [(ngModel)]="selectedOption" (change)="onSelectChange($event)">
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
        </select>
    </div>
    <div class="">
      <div class="d-flex justify-content-center">
        <h1>{{selectedOption}} Analysis</h1>
      </div>
      <div class="col-lg-6 mt-2 d-flex justify-content-center mt-2">
        <div>
            <mat-form-field appearance="outline">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [formControl]="start_date" placeholder="Start date"
                  (dateInput)="onStartDateSelected($event)">
                <input matEndDate [formControl]="end_date" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
      
            </mat-form-field>
            <!-- <div *ngIf="end_date.hasError('maxEndDateExceeded') && end_date.touched">
              <mat-error>End date must be within 30 days from the start date.</mat-error>
            </div> -->
          </div>
          <button class="bg-primary mt-1 me-2 ms-2" (click)="fetchData2()"
            style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"
            >
            Submit
          </button>
    </div>
       
    </div>
    
</div>
   <!-- <div>
    <h1></h1>
   </div> -->
    <div *ngFor="let chartObj of splineAreaCharts" class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ chartObj.section }}</h4>
          <apx-chart class="apex-charts" dir="ltr" [series]="chartObj.chart.series" [chart]="chartObj.chart.chart"
            [dataLabels]="chartObj.chart.dataLabels" [stroke]="chartObj.chart.stroke"
            [colors]="chartObj.chart.colors" [xaxis]="chartObj.chart.xaxis" [grid]="chartObj.chart.grid"
            [tooltip]="chartObj.chart.tooltip"></apx-chart>
        </div>
      </div>
      <!--end card-->
    </div>
</div>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>
  

  
  
  