<div class="container">
    <div class="row col-md-12">
        <h1 class="d-flex justify-content-center mb-3 mt-3">Update Profile</h1>
        <div class="row d-flex justify-content-center col-md-12">
            <form  [formGroup]="updateform">
                <div class="text-center">
                    <div class="profile-user position-relative d-inline-block mx-auto mb-4" style="width: 150px; position: relative;">
                        <img src="{{this.imageURL}}" class="img-thumbnail user-profile-image" alt="user-profile-image" id="companylogo-img" style="border-radius: 50%; width: 150px!important; height: 150px!important;" >
                        <label for="profile-img-file-input" class="profile-photo-edit avatar-xs" style="position: absolute; top: 100px; right: 0; cursor: pointer;">
                          <span class="avatar-title rounded-circle bg-light text-body">
                            <i class="ri-camera-fill"></i>
                          </span>
                        </label>
                        <input id="profile-img-file-input" type="file" class="profile-img-file-input" accept=".png, .jpeg, .jpg" (change)="fileChange($event)" style="display: none;" >
                    </div>
                      
                </div>
                <div class="row mb-2 ms-4">
                    <div class="col-md-6">
                        <label for="firstname">First Name</label>
                        <input type="text" formControlName="first_name" class="form-control" id="firstname" placeholder="First Name" [(ngModel)]="firstname"   (keypress)="albhabetsOnly($event)"/>
                        <div *ngIf="updateform!.get('first_name')?.invalid && updateform!.get('first_name')?.touched" class="text-danger" >
                            First Name field is required 
                        </div> 
                      
                    </div>
                    <div class="col-md-6">
                        <label for="lastname">Last Name</label>
                        <input type="text" formControlName="last_name" class="form-control" id="lastname" placeholder="Last Name" [(ngModel)]="lastname"  (keypress)="albhabetsOnly($event)" />
                        <div *ngIf="updateform!.get('last_name')?.invalid && updateform!.get('last_name')?.touched" class="text-danger" >
                            Last Name field is required 
                        </div> 
                       
                    </div>
                </div>
                <div class="row mb-2 ms-4">
                    <div class="col-md-6">
                        <label for="email">Email</label>
                        <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email"  [(ngModel)]="email1"/>
                        <div class="text-danger" *ngIf="updateform.get('email').invalid && (updateform.get('email').dirty || updateform.get('email').touched)">
                            Please enter a valid email address.
                        </div>
                       
                    </div>
                    <div class="col-md-6">
                        <label for="phonenumber">Phone Number</label>
                        <input type="phone number" formControlName="phone_number" class="form-control" id="phone_number" placeholder="Phone Number" maxlength="10" minlength="10" [(ngModel)]="phonenumber" (keypress)="numericOnly($event)"  />
                        <div *ngIf="updateform!.get('phone_number')?.invalid && updateform!.get('phone_number')?.touched" class="text-danger" >
                            Phone Number must contain 10 digits
                          </div>  
                    </div>
    
                </div>
                <!-- <div class="row mb-2 ms-4">
                    <div class="col-md-12">
                        <label for="username">Username</label>
                        <input type="text" formControlName="username" class="form-control" id="username" placeholder="User Name"  [(ngModel)]="username"/>
                       
                    </div>
                </div> -->
                <div class="row mt-5 mb-5">
                    <div style="display: flex; justify-content: end;">
                        <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit" [disabled]="!updateform.valid" (click)="onSubmit()">Update</button>
                        <button class="btn btn-danger w-md waves-effect waves-light me-1" type="submit" routerLink="/page/retailripple/profile">Cancel</button>
                    </div>
                </div>
    
            </form>
        </div>
      

    </div>

</div>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>