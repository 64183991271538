import { Component,ViewChild ,TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { CountService } from 'src/app/core/services/count.service';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent {
  showOverlay=false
  headerData: any;
  ogData:any;
  customers: any;
  total_product:any
  superuser:any;
  d2c_name:any;
  portal_id:any;
  portal_account_id:any;
  searchdata:any;  
  portal_name:any;
  isCancelled: boolean = false;
  isOptionSelected: boolean = false
  unread_tickets:any;
  startIndex:any
  fullData: any;
  apiData: any = [];
  apiData1:any = [];
  pageIndex:number = 0;
  createuserform2: UntypedFormGroup;
pageSize:any;
pageSizeOptions: number[] = [10, 20, 50, 100]; 
page:any;     
length:any;
page_no:number=1;
config:any;
paginate: any = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: 1,

  }
  status11:any;
  portal_id1:any;
  portal_account_id1:any
pageEvent:any;

displayedColumns: string[] | undefined;
@ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined; 

constructor(
  private authService:AuthenticationService,
  private modalServicee: NgbModal,
  private router: Router,
  private toastr: ToastrService,
  private formBuilder: UntypedFormBuilder,
  private countservice :CountService,
  ){

}

ngOnInit(): void {
  this.fetchData(this.paginate,'open');
  this.superuser = localStorage.getItem('superuser')

  this.unread_tickets = JSON.parse(localStorage.getItem('ticketss1') || '[]');
  console.log('unread_tickets',this.unread_tickets)
  this.countservice.unread_ticket();


 

  // console.log(this.length,'lengthhhhhhhh')

  this.createuserform2 = this.formBuilder.group({
    category: ['', [Validators.required]],
    // status: ['open', [Validators.required]],
    // disposition: ['pending', [Validators.required]],
    order_id: [''],
    awb: [''],
    voice_of_customer: [''],
    agent_remark: ['', [Validators.required]],
    is_order_issue: ['false', [Validators.required]],
    support_chat: ['', [Validators.required]],

    


  });

}
isUnreadTicket(ticketId: number): boolean {
  return this.unread_tickets.some(ticket => ticket.ticket_id === ticketId);
}

fetchData(event:PageEvent,status:any) {
  this.status11 = status
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
 this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
//  console.log(this.pageSize,'this.pagesize')
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

  let payload_data = {
   "page_number":page_no,
   "page_size":this.pageSize,
   "portal_account_id":this.portal_account_id,
   "ticket":'support',
   "status": this.status11
  
  };
  this.authService.ticket_view1(payload_data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    // console.log(mes)
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.length=mes.total_data
        this.fullData = res.body; 
        this.ogData =res.body
      this.headerData = res.headers;
      this.getData(this.fullData); 
    //   console.log("mesg", res.message); 
    // console.log(this.length,'lengthhhhhhhh')

    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}

getData(data:any) {
  this.apiData = data;
  // console.log("this.apiData",this.apiData); 
  // console.log(this.apiData);
  this.displayedColumns = Object.keys(this.headerData);
  this.customers = new MatTableDataSource(this.apiData);
  this.customers.sort = this.sort;
  // this.setFilter('open');

  
}
applyFilter1(filterValue: any) {
  // console.log(filterValue.value)
  this.customers.filter = filterValue.value.trim().toLowerCase();
  if (this.customers.paginator) {
    this.customers.paginator.firstPage();
  }
}

ticket_details(row:any){
  localStorage.setItem('ticket_id3',row.ticket_id)
  let data ={
    'ticket_id':row.ticket_id
  }
  this.showOverlay=true
  this.authService.ticket_details(data).subscribe((res: any) => {
    // console.log(res,'ticket data');
    let mes = JSON.parse(res);
    // console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay=false
      this.router.navigate(['/page/retailripple/supportticket'])

      // this.toastr.success(mes.message, "");

      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 

  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}


filteredData: any; 
currentFilter: string | null = 'open'; 

  filterData() {
    if (this.apiData) {
      if (this.currentFilter === null) {
        this.customers.data = this.apiData;
      } else {
        this.customers.data = this.apiData.filter(record => record.status === this.currentFilter);
      }
    } else {
      this.customers.data = [];
    }
  }

setFilter(status: string | null) {
  this.currentFilter = status;
  this.fetchData(this.paginate, this.currentFilter);


}
onPageChange(event: PageEvent) {
  this.fetchData(event, this.currentFilter);
}
openVerticallyCentered1(content3: TemplateRef<any>) {
  this.modalServicee.open(content3, { centered: true,size: 'lg'});
}

support_ticket_create(){
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')


  }
  else{
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
  }
  this.portal_id1 = this.createuserform2.value.order_id.replace(/\t/g, '');
  this.portal_account_id1 = this.createuserform2.value.awb.replace(/\t/g, '');
  const username = localStorage.getItem('username')

  let data = this.createuserform2.value
  data['raised_by'] = username
  data['portal_id'] = this.portal_id
  data['portal_account_id']= this.portal_account_id
  data['is_notification_read']= false
  data['status'] = 'open'
  data['disposition'] = 'pending'

  data['support_chat'] = {
    [username] :this.createuserform2.value.support_chat
  } 
  data['order_id'] = this.portal_id1
  data['awb'] = this.portal_account_id1
  this.showOverlay=true
  this.authService.support_ticket_create(data).subscribe((res: any) => {
    console.log(res,'support ticket data');
    let mes = JSON.parse(res);
    // console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay=false
      this.toastr.success(mes.success, "");
      this.modalServicee.dismissAll('Close click');
      this.createuserform2.reset();
      this.onPageChange(this.paginate)


      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalServicee.dismissAll('Close click');
    this.createuserform2.reset();


  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}

ticket_update(row){
  console.log(row)
  let data ={
    'ticket_id':row
  }
  this.showOverlay=true
  this.authService.unread(data).subscribe((res: any) => {
    // console.log(res,'ticket data');
    let mes = JSON.parse(res);
    console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay=false
      this.countservice.unread_ticket();
      // location.reload();

      // this.router.navigate(['page/retailripple/ticketview'])

      // this.toastr.success(mes.message, "");

      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 

  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});



}

handleTicketClick(row: any): void {
  if (this.isUnreadTicket(row['ticket_id'])) {
    this.ticket_update(row['ticket_id']);
    this.ticket_details(row);
  } else {
    this.ticket_details(row);
  }
}
not_allow_only_num_and_spl_char(value: any,inpu_feild_name:any){
  if (value) {
    if (/^[0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only numbers. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
    if (/^[^a-zA-Z0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only special characters. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
  }
  return null;
}
only_spl_and_num_not_accept(event: any,input_name:any,){
  const value = event.target.value;
    this.not_allow_only_num_and_spl_char(value,input_name); 
  
}



}
