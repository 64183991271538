import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent {
  
  createuserform: UntypedFormGroup;
  selectedPortalId:any;
  selectedportal:any
  fullData:any;
  showOverlay = false
  d2c_name:any
  portal_name:any
  selectedPortalName:any
  portal_id:any;
  portal_account_id:any;
  superuser:any;
  fullData2:any

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private authService: AuthenticationService){

  }

  ngOnInit(): void {
    this.initform();
    this. fetchdata();
  }


  initform(){
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')



    this.selectedPortalName = this.portal_name; 
    this.selectedportal = this.portal_id;
    this.selectedPortalId = this.portal_account_id
    this.d2c_name = this.d2c_name

    this.createuserform = this.formBuilder.group({
      portal_name: ['this.portal_name', [Validators.required]],
      portal_id: ['', [Validators.required]],
      portal_account_id: ['', [Validators.required]],
      d2c_name: ['', [Validators.required]],


    });


    const shouldNavigateAfterReload = localStorage.getItem('shouldNavigateAfterReload');

    if (shouldNavigateAfterReload === 'true') {
      localStorage.removeItem('shouldNavigateAfterReload');
      this.router.navigate(['/page/retailripple']);
    }
  }

  onPortalNameChange() {
    const selectedPortal = this.fullData.find((portal) => portal.portal_name === this.selectedPortalName);
    this.selectedPortalId = selectedPortal ? selectedPortal.portal_account_id : 0;
    this.selectedportal = selectedPortal ? selectedPortal.portal_id : 0;
    this.d2c_name = selectedPortal ? selectedPortal.d2c_name : 0;

  }

  fetchdata(){
    this.showOverlay=true;
    this.authService.portal_details_get().subscribe((res: any) => {
      // console.log(res)
      let mes=JSON.parse(res)
      // console.log(mes.statusCode)
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.fullData = mes.body;
        // console.log('fullData', mes.body);


      }
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          // this.authService.logout1()
          this.showOverlay=false
          // this.router.navigate(['/auth/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
        }
      }
      
    },
    (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });

  }
  route(){
    this.router.navigate(['/page/retailripple'])

  }

  profile_update(){
    let data = this.createuserform.value
    this.showOverlay=true;
    this.authService.profile_update(data).subscribe((res: any) => {
      // console.log(res)
      let mes=JSON.parse(res)
      // console.log(mes.statusCode)
      if (mes.statusCode === 200) {
        // this.showOverlay = false;
            setTimeout(() => {
              // this.router.navigate(['/page/retailripple'])
              this.onSubmit()
              this.fetchData()


    }, 1000);
        // this.router.navigate(['/'])
        this.onSubmit()
        this.toastr.success(res.success, 'Success')


        // this.fullData = mes.body;
        // console.log('fullData', mes.body);


      }
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          this.authService.logout1()
          this.showOverlay=false
          this.router.navigate(['/auth/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
        }
      }
      
    })

  }
  onSubmit() {
    this.showOverlay = true;
    let data = {
      'username' :localStorage.getItem('username'),
      'password': localStorage.getItem('password'),
    }
   
    this.authService.signin(data).subscribe((res: any) => {
      if(res.statusCode === 200){
        // console.log('res',res)
        // this.toastr.success('Logged in Successfully', 'Success')
        // this.showOverlay = false;
        localStorage.setItem('portal_name2',res.body.portal_name)
        localStorage.setItem('portal_id2',res.body.portal_id)
        localStorage.setItem('d2c_name2',res.body.d2c_name)
        localStorage.setItem('portal_account_id2',res.body.portal_account_id)
        // console.log( res.body.portal_name,res.body.portal_id,res.body.portal_account_id,'res.body.portal_account_idres.body.portal_account_id')
      }
      else{
        this.toastr.error(res.error, 'Error')
        this.showOverlay = false;



      }
    },
    (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });
      // .catch(error => {
        //   this.error = error ? error : '';
        // });
    
  }


  fetchData() {
    this.superuser = localStorage.getItem('is_superuser')
  
    if(this.superuser == 'true'){
            this.portal_name = localStorage.getItem('portal_name2')
            this.portal_id = localStorage.getItem('portal_id2')
            this.portal_account_id = localStorage.getItem('portal_account_id2')
            this.d2c_name = localStorage.getItem('d2c_name2')
      
          }
          else{
            this.portal_name = localStorage.getItem('portal_name')
             this.portal_id = localStorage.getItem('portal_id')
            this.portal_account_id = localStorage.getItem('portal_account_id')
            this.d2c_name = localStorage.getItem('d2c_name')
          }
       this.showOverlay=true 

  
    let payload_data = {
      'portal_account_id':this.portal_account_id,
     "page_number": 1,
     "page_size": 10,
    };
    this.authService.permission_get111(payload_data,localStorage.getItem('token')).subscribe((res) => {
      let mes=JSON.parse(res)
      res =mes 
      // this.total_product = mes.total_product
      if (res.statusCode>=200 && res.statusCode<=205) {
        // this.showOverlay = false;
        // this.length=mes.total_length
        //   this.fullData = res.body; 
        //   this.ogData =res.body
        // this.headerData = res.headers;
        // this.getData(this.fullData); 
        this.fullData2 = res.body[0].permission;
        // Otherwise, set localStorage values from fullData
        localStorage.setItem('is_whatsapp', this.fullData2.is_whatsapp);
        localStorage.setItem('is_whatsapp_marketing', this.fullData2.is_whatsapp_marketing);
        localStorage.setItem('order_notifications', this.fullData2.order_notifications);
        localStorage.setItem('custom_notifications', this.fullData2.custom_notifications);
        // this.transformPermission(this.fullData[0].permission);
        // this.transform(this.fullData[0].transaction)
        // this.countservice.unread_ticket();
        localStorage.setItem('shouldNavigateAfterReload', 'true');
  
        setTimeout(function() {
          window.location.reload();
      }, 1000);
  
  
  
      } 
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          // this.authService.logout()
          this.showOverlay=false
          this.router.navigate(['/account/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
          // console.log('error mesg',res)
        }
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });
  }
  

}
