<div class="container">
    <div class="row">
      <div class="d-flex justify-content-between">
        <h1 class="page mt-3 ms-2">Order View</h1>
        <h1 style="color: blue;" class="page mt-3"  *ngIf="fullData.length > 1">Partial Orders : {{data99}}</h1>
      </div>
        <div class="mt-3">
          <div class="container col-md-12 d-flex justify-content-center">
            <div class="row d-flex justify-content-center col-md-12">
              <div *ngFor="let order of fullData ;let i = index" class="card d-flex justify-content-center mb-3">
                <div class="d-flex justify-content-end laptop">
                  <button class="bg-primary mt-3 me-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 123px; height: 40px;" (click)="resend_invoice(order)">Resend Invoice</button>
                  <button class="bg-primary mt-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 166px; height: 40px;" (click)="send_details(order)"  [disabled]="order.awb === null || order.awb === ''">Send Tracking Details</button>
                </div>
                <div class="mobile">
                  <button class="bg-primary mt-3 me-3 d-flex justify-content-center" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 123px; height: 40px;" (click)="resend_invoice(order)">Resend Invoice</button>
                </div>
                <div class="mobile">
                  <button class="bg-primary mt-3 d-flex justify-content-center" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 166px; height: 40px;" (click)="send_details(order)">Send Tracking Details</button>
                </div>
                <div class="d-flex justify-content-between p-3 laptop">
                  <div class="col-md-6">
                    <div>
                      <p style="font-size: 18px; font-weight: 700;">Order Details :</p>
                      <div class="d-flex ms-2">
                        <div class="col-md-4">Order Id</div>
                        <div class="col-md-6">: {{order.order_id}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Quantity</div>
                        <div class="col-md-6">: {{order.qty}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Order Date</div>
                        <div class="col-md-6">: {{order.order_date}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Dispatched By Date</div>
                        <div class="col-md-6">: {{order.dispatch_by_date}}</div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <p style="font-size: 18px;">Contact Information :</p>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Customer Name</div>
                        <div class="col-md-6">: {{order.customer_name}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Phone</div>
                        <div class="col-md-6">: {{order.phone}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Email Id</div>
                        <div class="col-md-6">: {{order.email_id}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex mt-4" style="margin-top: 34px!important;">
                      <div class="col-md-4 mt-1">Delivery Tracking</div>
                      <div class="col-md-6 mt-1">: <span><a href="{{order.tracking_url}}" class="dec" target="_blank">{{order.awb}}</a></span></div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Payment Method</div>
                      <div class="col-md-6">: {{order.payment_method}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Status</div>
                      <div class="col-md-6">: {{order.status}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Shipping Address</div>
                      <div class="col-md-6">: {{order.address}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Pincode</div>
                      <div class="col-md-6">: {{order.pincode}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Warehouse Name</div>
                      <div class="col-md-6">: {{order.warehouse_name}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Shopify Order Id</div>
                      <div class="col-md-6">: {{order.shopify_order_id}}</div>
                    </div>
                  </div>
                </div>
                <div class="p-3 mobile m-5">
                  <div class="col-md-6">
                    <div>
                      <p style="font-size: 18px; font-weight: 700;">Order Details :</p>
                      <div class="d-flex ms-2">
                        <div class="col-md-4">Order Id</div>
                        <div class="col-md-6">: {{order.order_id}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Quantity</div>
                        <div class="col-md-6">: {{order.qty}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Order Date</div>
                        <div class="col-md-6">: {{order.order_date}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Dispatched By Date</div>
                        <div class="col-md-6">: {{order.dispatch_by_date}}</div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <p style="font-size: 18px;">Contact Information :</p>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Customer Name</div>
                        <div class="col-md-6">: {{order.customer_name}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Phone</div>
                        <div class="col-md-6">: {{order.phone}}</div>
                      </div>
                      <div class="d-flex ms-2 mt-3">
                        <div class="col-md-4">Email Id</div>
                        <div class="col-md-6">: {{order.email_id}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex mt-4" style="margin-top: 34px!important;">
                      <div class="col-md-4 mt-1">Delivery Tracking</div>
                      <div class="col-md-6 mt-1">: <span><a href="{{order.tracking_url}}" class="dec" target="_blank">{{order.awb}}</a></span></div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Payment Method</div>
                      <div class="col-md-6">: {{order.payment_method}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Status</div>
                      <div class="col-md-6">: {{order.status}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Shipping Address</div>
                      <div class="col-md-6">: {{order.address}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Pincode</div>
                      <div class="col-md-6">: {{order.pincode}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Warehouse Name</div>
                      <div class="col-md-6">: {{order.warehouse_name}}</div>
                    </div>
                    <div class="d-flex mt-3">
                      <div class="col-md-4">Shopify Order Id</div>
                      <div class="col-md-6">: {{order.shopify_order_id}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-flex justify-content-between mt-3 mb-5">
                  <div class="ms-4 d-flex justify-content-between">
                       <button class="mt-1"  style="background-color: rgb(245, 117, 117); color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"  (click)="route()">Back</button>
                       <button class="btn btn-primary btn-sm ms-2 mt-1" *ngIf="superuser === 'true'" (click)="openVerticallyCentered2(updateorder,order)" style="border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;" 
                      >
                         Edit
                       </button>
                 </div>
                 <div class="d-flex me-5">
                  <div>
                    <button class="mt-1 me-2 ms-4" *ngIf="isButtonVisible[i]" style="background-color:  rgb(104 138 104); color: white; border-radius: 6px; padding: 8px; border: none; width: 112px; height: 40px;" (click)="order_status(order.order_id,i)">Check Status</button>
                  </div>
                  <div *ngIf="statusData[i]?.is_return_avialable">
                    <button class="bg-primary mt-1" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 112px; height: 40px;" 
                            (click)="open1(content1, order)">Return Order</button>
                  </div>
                  <div *ngIf="statusData[i]?.is_cancel_avialable">
                    <button class="mt-1 me-2 ms-4" style="background-color: rgb(245, 117, 117); color: white; border-radius: 6px; padding: 8px; border: none; width: 112px; height: 40px;" 
                            (click)="open(content, order)">Cancel Order</button>
                  </div>
                  <div *ngIf="!statusData[i]?.is_return_avialable && !statusData[i]?.is_cancel_avialable && !isButtonVisible[i]">
                    <p style="color: red;" class="mt-4">Order is not available for return or cancellation</p>
                  </div>
                </div>
               </div>
              </div>              
            </div>
          </div>
        </div>
    </div>
    <ng-template #content1 let-modal>
        <div class="modal-header">
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
             <img src="assets/images/warning.png">

          </div>
          <h3 class="d-flex justify-content-center">Are You Sure !</h3>
          <p class="d-flex justify-content-center">You want to Return this order</p>
          <form [formGroup]="userHolidayCreate">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="">Name</label>
                <input type="text" formControlName="name" class="form-control" id="name" placeholder="Name" readonly   [(ngModel)]="name" />
                <div *ngIf="userHolidayCreate!.get('name')?.invalid && userHolidayCreate!.get('name')?.touched" class="text-danger" >
                  Name field is required
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Email</label>
                <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email" readonly   [(ngModel)]="email" />
                <div *ngIf="userHolidayCreate!.get('email')?.invalid && userHolidayCreate!.get('email')?.touched" class="text-danger">
                  Email field is required
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Phone Number</label>
                <input type="text" formControlName="phone" class="form-control" id="phone" placeholder="Phone Number" (keypress)="numericOnly($event)" minlength="10" maxlength="10"  [(ngModel)]="phone"  readonly/>
                <div class="text-danger" *ngIf="userHolidayCreate.get('phone').invalid && (userHolidayCreate.get('phone').dirty || userHolidayCreate.get('phone').touched)">
                 Phone Number must contain 10 digits
              </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Address</label>
                <input type="text" formControlName="address" class="form-control" id="address" placeholder="address"  [(ngModel)]="address" readonly />
                <div *ngIf="userHolidayCreate!.get('address')?.invalid && userHolidayCreate!.get('address')?.touched" class="text-danger" >
                  Address field is required
                </div>
              </div>
              <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
                <label for="">Account Id</label>
                <input type="text" formControlName="account_id" class="form-control" id="account_id" placeholder="Account Id" (keypress)="numericOnly($event)"  />
                <div *ngIf="userHolidayCreate!.get('account_id')?.invalid && userHolidayCreate!.get('account_id')?.touched" class="text-danger" >
                  Account field is required
                </div>
              </div>
              <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
                <label for="">IFSC Code</label>
                <input type="text" formControlName="ifsc" class="form-control" id="ifsc" placeholder="IFSC Code" (keypress)="alphanumericOnly1($event)" />
                <div *ngIf="userHolidayCreate!.get('ifsc')?.invalid && userHolidayCreate!.get('ifsc')?.touched" class="text-danger" >
                  IFSC code field is required
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Pincode</label>
                <input type="text" formControlName="pincode" class="form-control" id="pincode" placeholder="Pincode"  [(ngModel)]="pincode" (keypress)="numericOnly($event)" maxlength="6" minlength="6"  readonly/>
                <div *ngIf="userHolidayCreate!.get('ifsc')?.invalid && userHolidayCreate!.get('ifsc')?.touched" class="text-danger" >
                  Pincode field is required
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Reason</label>
                <input type="text" formControlName="reason" class="form-control" id="reason" placeholder="Reason" (blur)="only_spl_and_num_not_accept($event,'Reason')" />
                <div *ngIf="userHolidayCreate!.get('reason')?.invalid && userHolidayCreate!.get('reason')?.touched" class="text-danger" >
                  Reason field is required
                </div>
              </div>
            </div>
            
          </form>
    
        </div>
        <div class="modal-footer d-flex justify-content-center">
        
         <button type="button" class="btn btn-success" [disabled]="userHolidayCreate.invalid || loading" (click)="update_status('return_order')" style="width: 80px;">Yes</button>
          <button type="button" class="btn btn-danger" (click)="modal.close('Save click')" style="width: 80px;">No</button>
        </div>
        <ng-container *ngIf="showOverlay" id="overlay-container">
          <app-spinner [overlay]="true" id="loader"></app-spinner>
       </ng-container>
    </ng-template>
    <ng-template #content let-modal>
        <div class="modal-header">
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
             <img src="assets/images/warning.png">

          </div>
          <h3 class="d-flex justify-content-center">Are You Sure !</h3>
          <p class="d-flex justify-content-center">You want to cancel this order</p>
          <form [formGroup]="userHolidayCreate">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="">Name</label>
                <input type="text" formControlName="name" class="form-control" id="name" placeholder="Name" readonly/>
                <div *ngIf="userHolidayCreate!.get('name')?.invalid && userHolidayCreate!.get('name')?.touched" class="text-danger" >
                  Name field is required
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Email</label>
                <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email" readonly />
                <div *ngIf="userHolidayCreate!.get('email')?.invalid && userHolidayCreate!.get('email')?.touched" class="text-danger" >
                  Email field is required
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Phone Number</label>
                <input type="text" formControlName="phone" class="form-control" id="phone" placeholder="Phone Number" (keypress)="numericOnly($event)" minlength="10" maxlength="10" readonly />
                <div class="text-danger" *ngIf="userHolidayCreate.get('phone').invalid && (userHolidayCreate.get('phone').dirty || userHolidayCreate.get('phone').touched)">
                  Phone Number must contain 10 digits
              </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Address</label>
                <input type="text" formControlName="address" class="form-control" id="address" placeholder="address" readonly />
                <div *ngIf="userHolidayCreate!.get('address')?.invalid && userHolidayCreate!.get('address')?.touched" class="text-danger" >
                  Address field is required
                </div>
              </div>
              <!-- <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
                <label for="">Account Id</label>
                <input type="text" formControlName="account_id" class="form-control" id="account_id" placeholder="Account Id" (keypress)="numericOnly($event)" minlength="15" maxlength="15" />
                <div *ngIf="userHolidayCreate!.get('account_id')?.invalid && userHolidayCreate!.get('account_id')?.touched" class="text-danger" >
                  Account filed is required
                </div>
              </div>
              <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
                <label for="">IFSC Code</label>
                <input type="text" formControlName="ifsc" class="form-control" id="ifsc" placeholder="IFSC Code" (keypress)="alphanumericOnly1($event)" maxlength="12" minlength="12" />
                <div *ngIf="userHolidayCreate!.get('ifsc')?.invalid && userHolidayCreate!.get('ifsc')?.touched" class="text-danger" >
                  IFSC code filed is required
                </div>
              </div> -->
              <div class="col-md-6 mb-3">
                <label for="">Reason</label>
                <input type="text" formControlName="reason" class="form-control" id="reason" placeholder="Reason" (blur)="only_spl_and_num_not_accept($event,'Reason')" />
                <div *ngIf="userHolidayCreate!.get('reason')?.invalid && userHolidayCreate!.get('reason')?.touched" class="text-danger" >
                  Reason field is required
                </div>
              </div>
            </div>
            
          </form>
         
       
    
        </div>
        <div class="modal-footer d-flex justify-content-center">
        
         <button type="button" class="btn btn-success"  style="width: 80px;" [disabled]="userHolidayCreate.invalid" (click)="update_status('cancel_order')">Yes</button>
          <button type="button" class="btn btn-danger" (click)="modal.close('Save click')" style="width: 80px;">No</button>
        </div>
        <ng-container *ngIf="showOverlay" id="overlay-container">
          <app-spinner [overlay]="true" id="loader"></app-spinner>
      </ng-container>
    </ng-template>
    <!-- <button  class="btn btn-lg btn-outline-primary"   (click)="open(content)">Launch demo modal</button> -->

</div>

<ng-template #updateorder let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Update Order</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform4">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="name">Name</label>
              <input type="text" formControlName="name" class="form-control" id="name" placeholder="Enter Name"  [(ngModel)]="name11"
              />
              <div *ngIf="createuserform4!.get('name')?.invalid && createuserform4!.get('name')?.touched"
                class="text-danger">
                Name field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="last_name">Email</label>
              <input type="text" formControlName="email_id" class="form-control" id="email" [(ngModel)]="email_id11"
                placeholder="Enter Email"  />
              <div
                *ngIf="createuserform4!.get('email_id')?.invalid && createuserform4!.get('email_id')?.touched"
                class="text-danger">
                Email field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="address1">Address</label>
              <input type="text" formControlName="address" class="form-control" id="address" [(ngModel)]="address11"
                placeholder="Enter Address"  />
              <div
                *ngIf="createuserform4!.get('address')?.invalid && createuserform4!.get('address')?.touched"
                class="text-danger">
                Address field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="pincode">Pincode</label>
              <input type="text" formControlName="pincode" class="form-control" id="pincode"  maxlength="6" minlength="6" (keypress)="numericOnly($event)" [(ngModel)]="pincode11"
                placeholder="Enter Pincode"  />
              <div
                *ngIf="createuserform4!.get('pincode')?.invalid && createuserform4!.get('pincode')?.touched"
                class="text-danger">
                Pincode field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
          <div class="col-md-6 mb-3">
            <label for="phone">Phone</label>
            <input type="text" formControlName="phone" class="form-control" id="phone"  maxlength="10" minlength="10" (keypress)="numericOnly($event)" [(ngModel)]="phone11"
              placeholder="Enter Phone" />
            <div
              *ngIf="createuserform4!.get('phone')?.invalid && createuserform4!.get('phone')?.touched"
              class="text-danger">
              Phone field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="title">Awb</label>
            <input type="text" formControlName="awb" class="form-control" id="awb" [(ngModel)]="awb11"
              placeholder="Enter Awb"  />
            <div
              *ngIf="createuserform4!.get('awb')?.invalid && createuserform4!.get('awb')?.touched"
              class="text-danger">
              Awb field is required
            </div>
          </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="title">Location Latitude</label>
              <input type="text" formControlName="location_latitude" class="form-control" id="location_latitude"
                placeholder="Enter Location Latitude"  />
              <div
                *ngIf="createuserform4!.get('location_latitude')?.invalid && createuserform4!.get('location_latitude')?.touched"
                class="text-danger">
                Location Latitude field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="shipping_charge">Location Longitude</label>
              <input type="text" formControlName="location_longitude" class="form-control" id="location_longitude"
                placeholder="Enter Location Longitud"  />
              <div
                *ngIf="createuserform4!.get('location_longitude')?.invalid && createuserform4!.get('location_longitude')?.touched"
                class="text-danger">
                Location Longitud field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-12 mb-3">
              <label for="title">Status</label>
              <input type="text" formControlName="status" class="form-control"  [(ngModel)]="status11"
                placeholder="Enter status"  />
              <div
                *ngIf="createuserform4!.get('status')?.invalid && createuserform4!.get('status')?.touched"
                class="text-danger">
                Status field is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
 (click)="update_order()"    [disabled]="createuserform4.invalid" >Submit</button>
    </div>

  </div>
</ng-template>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>

