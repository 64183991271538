import { Component,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent {

  showOverlay=false
  headerData: any;
  ogData:any;
  customers: any;
  total_product:any
  superuser:any;
  d2c_name:any;
  portal_id:any;
  portal_account_id:any;
  searchdata:any;  
  portal_name:any;
  isCancelled: boolean = false;
  isOptionSelected: boolean = false
  startIndex:any
  fullData: any;
  apiData: any = [];
  apiData1:any = [];
  displayedColumns: string[] | undefined;
  pageIndex:number = 0;
pageSize:any;

pageSizeOptions: number[] = [10, 20, 50, 100]; 
page:any;     
length:any;
page_no:number=1;
config:any;
paginate: any = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: 1
  }
pageEvent:any;


    @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined; 

  constructor(
    private authService:AuthenticationService,
    private modalServicee: NgbModal,
    private router: Router,
    private toastr: ToastrService){

  }

  ngOnInit(): void {
    this.fetchData(this.paginate);
    this.superuser = localStorage.getItem('superuser')

  }

  fetchData(event:PageEvent) {
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
            this.portal_name = localStorage.getItem('portal_name2')
            this.portal_id = localStorage.getItem('portal_id2')
            this.portal_account_id = localStorage.getItem('portal_account_id2')
            this.d2c_name = localStorage.getItem('d2c_name2')
      
          }
          else{
            this.portal_name = localStorage.getItem('portal_name')
             this.portal_id = localStorage.getItem('portal_id')
            this.portal_account_id = localStorage.getItem('portal_account_id')
            this.d2c_name = localStorage.getItem('d2c_name')
          }
       this.showOverlay=true 
   this.paginate = event;
   this.length = +event.length;
   this.pageIndex = +event.pageIndex;
   this.pageSize = +event.pageSize;
   this.startIndex = +event.pageIndex * event.pageSize
   let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
  
    let payload_data = {
     "page_number":page_no,
     "page_size":this.pageSize,
    };
    this.authService.scanner_view(payload_data).subscribe((res) => {
      let mes=JSON.parse(res)
      res =mes 
      this.total_product = mes.total_product
      if (res.statusCode>=200 && res.statusCode<=205) {
        this.showOverlay = false;
        this.length=mes.total_length
          this.fullData = res.body; 
          this.ogData =res.body
        this.headerData = res.header;
        this.getData(this.fullData); 

      } 
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          this.showOverlay=false
          this.router.navigate(['/account/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
        }
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });
  }

  getData(data:any) {
    this.apiData = data;
   
  
    delete this.headerData.emp_leave_app_id;
    delete this.headerData.emp_id_id;
  
    this.displayedColumns = Object.keys(this.headerData);
    this.customers = new MatTableDataSource(this.apiData);
    this.customers.sort = this.sort;
    
  }

}
