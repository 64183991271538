import { Component,ElementRef,HostListener,ViewChild,TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment'; 
import { CountService } from 'src/app/core/services/count.service';


@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent {
  showOverlay=false
  // pageSizeOptions: number[] = [10, 20, 50, 100]; 
  headerData: any;
  ogData:any;
  customers: any;
  // pageEvent:any;
  total_product:any
  superuser:any;
  d2c_name:any;
  portal_id:any;
  portal_account_id:any;
  searchdata:any;  
  portal_name:any;
  isCancelled: boolean = false;
  isOptionSelected: boolean = false
  startIndex:any
  editingData:any
  searchchar:boolean = false
  value:any
  createuserform4: UntypedFormGroup;
  isWhatsApp:any;
  isWhatsAppMarketing:any;
  order:any;
  marketing:any




  fullData: any;
  apiData: any = [];
  apiData1:any = [];
  editingData1:any

    displayedColumns: string[] | undefined;


    @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined; 
    @ViewChild('inputField') inputField!: ElementRef;
    @ViewChild('inputField1') inputField1!: ElementRef;
    @ViewChild('inputFieldDivision') inputFieldDivision: ElementRef;
@ViewChild('inputFieldBrand') inputFieldBrand: ElementRef;






  constructor(
    private authService:AuthenticationService,
    private modalServicee: NgbModal,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public countservice :CountService,
    private toastr: ToastrService){

  }

  ngOnInit(): void {

    // const reloaded = localStorage.getItem('pageReloaded');

    // if (!reloaded) {
    //   localStorage.setItem('pageReloaded', 'true');
    //   location.reload(); 
    // }

    this.fetchData(this.paginate);
    this.fetchdata()
    this.superuser = localStorage.getItem('superuser')
    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
            this.portal_name = localStorage.getItem('portal_name2')
            this.portal_id = localStorage.getItem('portal_id2')
            this.portal_account_id = localStorage.getItem('portal_account_id2')
            this.d2c_name = localStorage.getItem('d2c_name2')
      
          }
          else{
            this.portal_name = localStorage.getItem('portal_name')
             this.portal_id = localStorage.getItem('portal_id')
            this.portal_account_id = localStorage.getItem('portal_account_id')
            this.d2c_name = localStorage.getItem('d2c_name')
          }

    this.createuserform4 = this.formBuilder.group({
      is_whatsapp: ['', [Validators.required]],
      is_whatsapp_marketing: ['',[Validators.required]],
      credit_amount: ['', [Validators.required]],
      portal_account_id: [this.portal_account_id1,[Validators.required]],
      order_notifications:['', [Validators.required]],
      custom_notifications:['', [Validators.required]],
      order_amount:['', [Validators.required]],
      marketing_amount:['', [Validators.required]],




    });

  }
  
pageIndex:number = 0;
pageSize:any;
fullData2:any

pageSizeOptions: number[] = [10, 20, 50, 100]; 
page:any;     
length:any;
page_no:number=1;
config:any;
paginate: any = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: 1
  }
pageEvent:any;

fetchData(event:PageEvent) {
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
 this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

  let payload_data = {
    'portal_account_id':this.portal_account_id,
   "page_number":page_no,
   "page_size":this.pageSize,
  };
  this.authService.permission_get111(payload_data,localStorage.getItem('token')).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.length=mes.total_length
        this.fullData = res.body; 
        this.ogData =res.body
      this.headerData = res.headers;
      this.getData(this.fullData); 
      this.fullData2 = res.body[0].permission;
      // Otherwise, set localStorage values from fullData
      localStorage.setItem('is_whatsapp', this.fullData2.is_whatsapp);
      localStorage.setItem('is_whatsapp_marketing', this.fullData2.is_whatsapp_marketing);
      localStorage.setItem('order_notifications', this.fullData2.order_notifications);
      localStorage.setItem('custom_notifications', this.fullData2.custom_notifications);
      this.transformPermission(this.fullData[0].permission);
      this.transform(this.fullData[0].transaction)
      this.countservice.unread_ticket();

    //   setTimeout(function() {
    //     window.location.reload();
    // }, 5000);



    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/account/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}

getData(data:any) {
  this.apiData = data;
  // console.log("this.apiData",this.apiData); 
  // console.log(this.apiData);

  this.headerData.actions = "Action"; 
  delete this.headerData.emp_leave_app_id;
  delete this.headerData.emp_id_id;


  this.displayedColumns = Object.keys(this.headerData);
  this.customers = new MatTableDataSource(this.apiData);
  this.customers.sort = this.sort;
  // if (this.headerData && this.headerData.permission) {
  //   this.transformPermission(this.headerData.permission);
  // }
  // this.customers.paginator = this.paginator;
  
}
order_notifications:any;
custom_notifications:any
transformPermission(permission: any): void {
  console.log(permission,'permission')
  this.isWhatsApp = permission.is_whatsapp ;
  this.isWhatsAppMarketing = permission.is_whatsapp_marketing;
  this.order_notifications = permission.order_notifications
  this.custom_notifications = permission.custom_notifications
  localStorage.setItem('is_whatsapp',permission.is_whatsapp)
  localStorage.setItem('is_whatsapp_marketing',permission.is_whatsapp_marketing)
  localStorage.setItem('order_notifications',permission.order_notifications)
  localStorage.setItem('custom_notifications',permission.custom_notifications)

  // console.log(this.isWhatsApp,this.isWhatsAppMarketing)
}
transform(permission: any): void {
  console.log(permission,'permission')
  this.order = permission.order_notification ;
  this.marketing = permission.marketing_notification;
}
applyFilter1(filterValue: any) {
  if(filterValue!=''){
    this.searchchar = true
    this.value = filterValue
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData1(this.paginate,filterValue)
  }
  else{
    this.searchchar = false
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData(this.paginate)
  }
  // this.customers.filter = filterValue.value.trim().toLowerCase();
  // if (this.customers.paginator) {
  //   this.customers.paginator.firstPage();
  // }
}

startEditing(data: any, event: MouseEvent) {
  this.editingData = data; 
  event.stopPropagation();
  data.isEditing = true;
  setTimeout(() => this.inputField.nativeElement.focus()); 

}
data1:any
data2:any

saveEdit(data: any) {
  this.data1 = data.division
  console.log(this.data1)
  this.data2 = data.store_group_id
  
  // this.update_store_name(this.data1,this.data2)


  data.isEditing = false;
}
// getWidth(text: string): number {
//   // Assuming a base width for the td element
//   let baseWidth = 100; 
//   let additionalWidth = text ? text.length * 10 : 0; 
//   return baseWidth + additionalWidth;
// }
cancelEdit(data: any) { 
      data.isEditing = false;  
      this.fetchData(this.paginate)
}


startEditing1(data: any, event: MouseEvent) {
  this.editingData1 = data; 
  event.stopPropagation();
  data.isEditing = true;
  setTimeout(() => this.inputField1.nativeElement.focus()); 

}
data3:any
data4:any

saveEdit1(data: any) {
  this.data3 = data.brand
  console.log(this.data3)
  
  // this.update_store_name(this.data1,this.data2)


  data.isEditing = false;
}
// getWidth1(text: string): number {
//   // Assuming a base width for the td element
//   let baseWidth = 100; 
//   let additionalWidth = text ? text.length * 10 : 0; 
//   return baseWidth + additionalWidth;
// }
cancelEdit1(data: any) { 
      data.isEditing = false;  
      this.fetchData(this.paginate)
}
@HostListener('document:click', ['$event'])
handleClickOutside(event: MouseEvent) {
  if (this.editingData && !this.inputFieldDivision.nativeElement.contains(event.target)) {
    this.cancelEdit(this.editingData);
  }
}
handleClickOutside1(event: MouseEvent) {
  if (this.editingData1 && !this.inputFieldBrand.nativeElement.contains(event.target)) {
    this.cancelEdit(this.editingData1);
  }
}

startEditingDivision(data: any, event: MouseEvent) {
  this.editingData = data; 
  data.isEditingDivision = true;
  event.stopPropagation();
  setTimeout(() => this.inputFieldDivision.nativeElement.focus());
}

saveEditDivision(data: any) {
  this.data1 = data.division;
  this.data2 = data.product_id;
  console.log(this.data1, this.data2);
  this.product_edit(this.data1,this.data2)
  data.isEditingDivision = false;
}

getWidth(text: string): number {
  let baseWidth = 100;
  let additionalWidth = text ? text.length * 10 : 0;
  return baseWidth + additionalWidth;
}

cancelEditDivision(data: any) {
  data.isEditingDivision = false;
  this.fetchData(this.paginate);
}

startEditingBrand(data: any, event: MouseEvent) {
  this.editingData1 = data; 
  data.isEditingBrand = true;
  event.stopPropagation();
  setTimeout(() => this.inputFieldBrand.nativeElement.focus());
}

saveEditBrand(data: any) {
  this.data3 = data.brand;
  this.data4 = data.product_id
  console.log(this.data3,this.data4);
  this.product_edit1(this.data3,this.data4)
  data.isEditingBrand = false;
}

getWidth1(text: string): number {
  let baseWidth = 100;
  let additionalWidth = text ? text.length * 10 : 0;
  return baseWidth + additionalWidth;
}

cancelEditBrand(data: any) {
  data.isEditingBrand = false;
  this.fetchData(this.paginate);
}
product_edit(data1:any,data2:any){
  this.showOverlay = true
  let data = {
    'product_id':data2,
    'column':'division',
    'value':data1

  }
  this.authService.product_update(data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.toastr.success(res.success, "");
      this.fetchData(this.paginate);
  
    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        this.toastr.error(res.error, "");

      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}
product_edit1(data1:any,data2:any){
  this.showOverlay = true
  let data = {
    'product_id':data2,
    'column':'brand',
    'value':data1

  }
  this.authService.product_update(data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.toastr.success(res.success, "");
      this.fetchData(this.paginate);
  
    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        this.toastr.error(res.error, "");

      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}



fetchData1(event:PageEvent,data1:any) {
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
 this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
//  console.log(this.pageSize,'this.pagesize')
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

  let payload_data = {
    'portal_account_id':this.portal_account_id,
   "page_number":page_no,
   "page_size":this.pageSize,
   "search":data1
  };
  this.authService.product_details1(payload_data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.length=mes.total_product
        this.fullData = res.body; 
        this.ogData =res.body
      this.headerData = res.header;
      this.getData(this.fullData); 
    //   console.log("mesg", res.message); 
    // console.log(this.length,'lengthhhhhhhh')

    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/account/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}

permission_create(){
  let formData = this.createuserform4.value;
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }

  let data = {
    'credit_amount': formData.credit_amount,
    'portal_account_id': this.portal_account_id,
    'is_whatsapp': formData.is_whatsapp,
    'is_whatsapp_marketing': formData.is_whatsapp_marketing,
    'order_notifications':formData.order_notifications,
    'custom_notifications':formData.custom_notifications,
    'order_amount':formData.order_amount,
    'marketing_amount':formData.marketing_amount

  }
  
  let formattedData = {
    'portal_account_id': data.portal_account_id,
    'permission': {
      'is_whatsapp': data.is_whatsapp,
      'is_whatsapp_marketing': data.is_whatsapp_marketing,
      'order_notifications':data.order_notifications,
      'custom_notifications':data.custom_notifications
    },
    'credit_amount': data.credit_amount,
    'transaction': {
      'order_notification':data.order_amount,
      'marketing_notification':data.marketing_amount
    }


  };

  this.showOverlay=true
  this.authService.permission_update(formattedData).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay=false
      this.toastr.success(mes.success, "");
      this.modalService.dismissAll('Close click');
      this.fetchData(this.paginate);
      this.createuserform4.reset();
      this.countservice.unread_ticket();
      setTimeout(function() {
        window.location.reload();
    }, 5000);
    


      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.createuserform4.reset();


  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}

credit_amount:any;
portal_account_id1:any


openVerticallyCentered2(content3: TemplateRef<any>,data:any) {
  console.log(data)
  this.credit_amount = data.credit_amount
  this.portal_account_id1 = data.portal_account_id
  console.log(this.portal_account_id1,'portal_account_id1')
  this.modalService.open(content3, { centered: true,size: 'lg'});
}

fullData1:any

fetchdata(){
  this.showOverlay=true;
  this.authService.portal_details_get().subscribe((res: any) => {
    // console.log(res)
    let mes=JSON.parse(res)
    // console.log(mes.statusCode)
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.fullData1 = mes.body;
      // console.log('fullData', mes.body);


    }
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        this.authService.logout1()
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
      }
    }
    
  })

}

}
