<div class="container">
    <div class="row">
       <div class="d-flex justify-content-center laptop">
        <div class="col-md-6 d-flex justify-content-between">
          <button class="bg-primary mt-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 190px; height: 40px;" (click)="toggleForm()">Download Order Reports</button>
          <button class="bg-primary mt-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 200px; height: 40px;" (click)="download_products()">Download Product Reports</button>   
 
        </div>
       </div>
       <div class="justify-content-center mobile">
        <div class="col-md-6  justify-content-center text-center">
          <button class="bg-primary mt-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 190px; height: 40px;" (click)="toggleForm()">Download Order Reports</button>
          <button class="bg-primary mt-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 200px; height: 40px;" (click)="download_products()">Download Product Reports</button>   
 
        </div>
       </div>

    </div>
    <div class="row card p-3 mt-5 m-1 col-md-12 d-flex justify-content-center"  style="box-shadow: 1px 1px 1px 1px grey">
        <p class="page mb-4">Generate Orders</p>
        <form  [formGroup]="createuserform">
            <div class="row col-md-12">
                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="col-md-12">
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName ="start_date" placeholder="Start date"
                          (dateInput)="onStartDateSelected($event)">
                        <input matEndDate formControlName="end_date" placeholder="End date" [disabled]="end_date.disabled">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
              
                    </mat-form-field>
                    <div *ngIf="end_date.hasError('maxEndDateExceeded') && end_date.touched">
                      <mat-error>End date must be within 30 days from the start date.</mat-error>
                    </div>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="full-width col-md-12">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" placeholder="Select Status" multiple>
                      <mat-option #allSelected (click)="toggleAllSelection(status)" [value]="">Select
                        All</mat-option>
                        <mat-option *ngFor="let statusOption of status" [value]="statusOption.value">
                          {{ statusOption.label }}
                        </mat-option>
                      <!-- <mat-option value="all">All</mat-option> -->
                    </mat-select>
                  </mat-form-field>
                </div>
              
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="full-width col-md-12">
                    <mat-label>Location</mat-label>
                    <mat-select formControlName="warehouse" placeholder="Select Location" multiple>
                      <mat-option #allSelectedDepartment  (click)="toggleAllSelectiondepartment(warehouse)" [value]="true">Select
                        All</mat-option>
                        <mat-option *ngFor="let statusOption of warehouse" [value]="statusOption.value">
                          {{ statusOption.label }}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 d-flex laptop">
                    <button class="bg-success  ms-5" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;" (click)="download_orders()"  [disabled]="!createuserform.valid">Generate</button>
                    <button class="bg-danger  ms-5" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;" (click)="onCancel()">Cancel</button>   
                </div>
                <div class="col-md-3 mobile">
                  <button class="bg-success  ms-5 mb-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;" (click)="download_orders()"  [disabled]="!createuserform.valid">Generate</button>
                  <button class="bg-danger  ms-5" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;" (click)="onCancel()">Cancel</button>   
              </div>
            </div>
        </form>
    </div>

</div>
<ng-container *ngIf="showOverlay" id="overlay-container">
  <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>