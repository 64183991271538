import { Component,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators,FormArray} from '@angular/forms';



@Component({
  selector: 'app-repeated',
  templateUrl: './repeated.component.html',
  styleUrls: ['./repeated.component.scss']
})
export class RepeatedComponent {
  superuser:any;
  portal_name:any;
  portal_id:any;
  portal_account_id:any;
  d2c_name:any;
  showOverlay = false;
  repeated_count:any;
  startIndex:any
  fullData: any;
  apiData: any = [];
  apiData1:any = [];
  pageIndex:number = 0;
  lastPageIndex:number;
pageSize:any;
pageSizeOptions: number[] = [10, 20, 50, 100]; 
page:any;     
length:any;
page_no:number=1;
config:any;
paginate: any = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: 1
  }
pageEvent:any;
headerData: any;
myForm: FormGroup;
ogData:any;
customers: any;
next_url:any;
isOptionSelected = false;
prev_url:any;
searchTerm ='';
selectedOption = 'order_id';
order_count:any;
fullData1:any;
fullData2:any
showNextButton: boolean = false;
showPrevButton: boolean = false;



displayedColumns: string[] | undefined;
@ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined; 


  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private formBuilder: UntypedFormBuilder,

  ){

  }

  ngOnInit(): void {

    this.myForm = this.formBuilder.group({
      order_count: ['', [Validators.required]],
  
    });
   
    this.superuser = localStorage.getItem('is_superuser')
    if (this.superuser === 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    if (this.d2c_name === 'shopify'){
      this.count22(this.paginate, 'default');
    }
    if (this.d2c_name !== 'shopify'){
      this.count222(this.paginate);
    }
    // else{
    //   this.count222(this.paginate);
    // }
  }

  count222(event:PageEvent) {
    this.showOverlay = true
    this.superuser = localStorage.getItem('is_superuser')


    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }

    this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
//  console.log(this.pageSize,'this.pagesize')
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
    let data = {
      'portal_account_id': this.portal_account_id,
      'portal_id': this.portal_id,
      "page_number":page_no,
      "page_size":this.pageSize,


    }
    this.authService.repeated1(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      console.log(mes,'repeated data of wooo');
      // this.count1 = mes.shopify_cutomer_count
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.fullData = mes.body; 
        this.headerData = mes.header;
        this.getData(this.fullData); 
        this.repeated_count = mes.count
          
        

      } else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          // this.authService.logout()
          this.showOverlay = false
          this.router.navigate(['/account/login'])

        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
          // console.log('error mesg',res)
        }

        // this.showOverlay = false;
        // console.error("'monthly' array is not present or is empty.");
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        // this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        // this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });

    

  }
 url:any



 count22(event: PageEvent, direction: 'prev' | 'next' | 'default') {
  this.paginate = event;
    this.length = +event.length;
    this.pageIndex = +event.pageIndex;
    this.pageSize = +event.pageSize;
    this.lastPageIndex = this.pageIndex;
   //  console.log(this.pageSize,'this.pagesize')
    this.startIndex = +event.pageIndex * event.pageSize
    let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
    this.showOverlay = true
    this.superuser = localStorage.getItem('is_superuser')


    if (this.superuser == 'true') {
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')

    }
    else {
      this.portal_name = localStorage.getItem('portal_name')
      this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    if (direction === 'default') {
      this.url =''
    } else {
      this.url = direction === 'next' ? this.next_url : (direction === 'prev' ? this.prev_url : '');

    }
    let data = {
      'portal_account_id': this.portal_account_id,
      'd2c_name': this.d2c_name,
      'url': this.url,
      page_size: this.pageSize
      
      
      
    }
    this.authService.repeated(data).subscribe((res: any) => {
      // console.log(res);
      let mes = JSON.parse(res);
      console.log(mes,'repeated data');
      // this.count1 = mes.shopify_cutomer_count
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.length=mes.total_length
        this.fullData = mes.body; 
      this.headerData = mes.header;
      this.getData(this.fullData); 
      this.next_url = mes.next_url;
      this.prev_url = mes.prev_url
        

      } else {
        if (mes.error == 'Token has expired' || mes.error == 'Invalid token' || mes.error == 'Token is required') {
          this.showOverlay = false
          this.router.navigate(['/account/login'])

        }
        else {
          this.toastr.error(res.error, "");
          this.showOverlay = false;
        }

      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
      } else {
      }
    });

    

  }
  getData(data:any) {
    this.apiData = data;
    // console.log("this.apiData",this.apiData); 
    // console.log(this.apiData);
    this.displayedColumns = Object.keys(this.headerData);
    this.customers = new MatTableDataSource(this.apiData);
    this.customers.sort = this.sort;
  
    
  }
  applyFilter1(filterValue: any) {
    // console.log(filterValue.value)
    this.customers.filter = filterValue.value.trim().toLowerCase();
    if (this.customers.paginator) {
      this.customers.paginator.firstPage();
    }
  }

  applyFilter(filterValue: string) {
    this.searchTerm = filterValue; 
    this.searchTerm1( this.searchTerm )
      // this.combinedValue = `${this.selectedOption}:${this.searchTerm}`;
      // this.ordersearch(this.searchTerm,this.selectedOption);
    
  }
  
placeholder_name = 'Search For Order Id'
setplaceholder(data:any){
    this.placeholder_name=data
    this.isOptionSelected = true;

}
search_shopify(){
  this.showOverlay = true
  this.superuser = localStorage.getItem('is_superuser')
  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = {
    'order_count':this.myForm.value.order_count,
    'portal_account_id':this.portal_account_id,
    'd2c_name':this.d2c_name,
    'url':'',
    'page_size':'',
  }

  this.authService.repeated2(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.searchTerm ='';
      this.fullData1 = mes.body
      this.getData(this.fullData1)
      this.prev_url = mes.prev_url
      this.next_url = mes.next_url
  
  
    
     
    } 
    else{
      this.showOverlay = false;
      this.toastr.error(mes.error, 'Error')
  
  
  
  
    }
   })

}
cancel(){
  this.myForm.get('order_count').setValue('');
  this.count22(this.paginate, 'default');

}
searchTerm1(data1:any){

  this.showOverlay = true
  this.superuser = localStorage.getItem('is_superuser')
  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = {
    'name':data1,
     'page_number':1,
     'page_size':10,
     'portal_id':this.portal_id,
     'portal_account_id':this.portal_account_id
     

  }

  this.authService.repeated3(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.showOverlay = false;
      this.searchTerm ='';
      this.fullData2 = mes.body
      this.getData(this.fullData2)
      this.repeated_count = mes.count
  
  
    
     
    } 
    else{
      this.showOverlay = false;
      this.toastr.error(mes.error, 'Error')
  
  
  
  
    }
   })

}
loadPreviousPage(){
  this.count22(this.paginate, 'prev')
}
loadNextPage(){
  this.count22(this.paginate, 'next')

}
cancel1(){
  this.myForm.reset()
  this.count222(this.paginate);

}

}
