<div class="container">
  <div class="row">
      <h1 class="page mt-3 ms-2">Order View</h1>
      <div class="mt-3">
        <div class="container col-md-12 d-flex justify-content-center">
          <div class="row d-flex justify-content-center col-md-12">
            <div class="card d-flex justify-content-center">
              <div class="d-flex justify-content-end">
                <button class="bg-primary mt-3 me-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 123px; height: 40px;" (click)="resend_invoice()">Resend Invoice</button>
                <button class="bg-primary mt-3" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width: 166px; height: 40px;" (click)="send_details()" [disabled]="fullData.awb === null || fullData.awb === ''">Send Tracking Details</button>
              </div>
              <div class="d-flex justify-content-between p-3 laptop">
                <div class="col-md-6">
                  <div>
                    <p style="font-size: 18px; font-weight: 700;">Ordered Details :</p>
                    <div class="d-flex ms-2">
                      <div class="col-md-4"> Order Id</div>
                      <div class="col-md-6"> : {{fullData.order_id}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Quantity </div>
                      <div class="col-md-6"> : {{fullData.qty}}</div>
                    </div>
                    <!-- <p class="ms-2 d-flex">Order Id : <span class="d-flex justify-content-center">{{fullData.order_id}}</span></p> -->
                    <!-- <p class="ms-2">Quantity : {{fullData.qty}}</p> -->
                    <!-- <p class="ms-2">Order Date : {{fullData.order_date}}</p> -->
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Order Date</div>
                      <div class="col-md-6"> : {{fullData.order_date}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Dispatched By Date</div>
                      <div class="col-md-6"> : {{fullData.dispatch_by_date}}</div>
                    </div>
                    
                    <!-- <p class="ms-2">Dispatched By Date : {{fullData.dispatch_by_date}}</p> -->
                  </div>
                  <div class="mt-3">
                    <p style="font-size: 18px;">Contact Information :</p>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Customer Name</div>
                      <div class="col-md-6"> : {{fullData.customer_name}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Phone</div>
                      <div class="col-md-6"> : {{fullData.phone}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Email Id</div>
                      <div class="col-md-6"> : {{fullData.email_id}}</div>
                    </div>
                    <!-- <p class="ms-2">Customer Name : {{fullData.customer_name}}</p>
                    <p class="ms-2">Phone : {{fullData.phone}}</p>
                    <p class="ms-2">Email Id :{{fullData.email_id}}</p> -->
  
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex  mt-4" style="margin-top: 34px!important;">
                    <div class="col-md-4 mt-1">Delivery Tracking</div>
                    <div class="col-md-6 mt-1"> : <span><a href="{{fullData.tracking_url}}" class="dec" target="_blank">{{fullData.awb}}</a>
                    </span></div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Payment Method</div>
                    <div class="col-md-6"> : {{fullData.payment_method}}</div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Status</div>
                    <div class="col-md-6"> : {{fullData.status}}</div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Shipping Address</div>
                    <div class="col-md-6"> : {{fullData.address}}</div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Pincode</div>
                    <div class="col-md-6"> : {{fullData.pincode}}</div>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="col-md-4">Warehouse Name</div>
                    <div class="col-md-6">: {{fullData.warehouse_name}}</div>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="col-md-4">Shopify Order Id</div>
                    <div class="col-md-6">: {{fullData.shopify_order_id}}</div>
                  </div>
                  <!-- <div class="mt-4" style="margin-top: 34px!important;">

                    <p class="mt-1">Delivery Tracking : <span><a href="https://www.delhivery.com/track/package/{{fullData.awb}}" class="dec" target="_blank">{{fullData.awb}}</a>
                    </span></p>
                  </div> -->
                  <!-- <div>
                    <p>Quantity : {{fullData[0].qty}}</p>
                  </div> -->
                  <!-- <div>
                    <p>Payment Method : {{fullData.payment_method}}</p>
                  </div>
                  <div>
                    <p>Status : {{fullData.status}}</p>
                  </div>
                  <div>
                    <p>Shipping Address: {{fullData.address}}</p>
                  </div>
                  <div>
                    <p>Pincode : {{fullData.pincode}}</p>
                  </div> -->
                </div>
              </div>
              <div class="justify-content-between p-3 mobile">
                <div class="col-md-6">
                  <div>
                    <p style="font-size: 18px; font-weight: 700;">Ordered Details :</p>
                    <div class="d-flex ms-2">
                      <div class="col-md-4"> Order Id</div>
                      <div class="col-md-6"> : {{fullData.order_id}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Quantity </div>
                      <div class="col-md-6"> : {{fullData.qty}}</div>
                    </div>
                    <!-- <p class="ms-2 d-flex">Order Id : <span class="d-flex justify-content-center">{{fullData.order_id}}</span></p> -->
                    <!-- <p class="ms-2">Quantity : {{fullData.qty}}</p> -->
                    <!-- <p class="ms-2">Order Date : {{fullData.order_date}}</p> -->
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Order Date</div>
                      <div class="col-md-6"> : {{fullData.order_date}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Dispatched By Date</div>
                      <div class="col-md-6"> : {{fullData.dispatch_by_date}}</div>
                    </div>
                    
                    <!-- <p class="ms-2">Dispatched By Date : {{fullData.dispatch_by_date}}</p> -->
                  </div>
                  <div class="mt-3">
                    <p style="font-size: 18px;">Contact Information :</p>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Customer Name</div>
                      <div class="col-md-6"> : {{fullData.customer_name}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Phone</div>
                      <div class="col-md-6"> : {{fullData.phone}}</div>
                    </div>
                    <div class="d-flex ms-2 mt-3">
                      <div class="col-md-4">Email Id</div>
                      <div class="col-md-6"> : {{fullData.email_id}}</div>
                    </div>
                    <!-- <p class="ms-2">Customer Name : {{fullData.customer_name}}</p>
                    <p class="ms-2">Phone : {{fullData.phone}}</p>
                    <p class="ms-2">Email Id :{{fullData.email_id}}</p> -->
  
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex  mt-4" style="margin-top: 34px!important;">
                    <div class="col-md-4 mt-1">Delivery Tracking</div>
                    <div class="col-md-6 mt-1"> : <span><a href="{{fullData.tracking_url}}" class="dec" target="_blank">{{fullData.awb}}</a>
                    </span></div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Payment Method</div>
                    <div class="col-md-6"> : {{fullData.payment_method}}</div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Status</div>
                    <div class="col-md-6"> : {{fullData.status}}</div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Shipping Address</div>
                    <div class="col-md-6"> : {{fullData.address}}</div>
                  </div>
                  <div class="d-flex  mt-3">
                    <div class="col-md-4">Pincode</div>
                    <div class="col-md-6"> : {{fullData.pincode}}</div>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="col-md-4">Warehouse Name</div>
                    <div class="col-md-6">: {{fullData.warehouse_name}}</div>
                  </div>
                  <div class="d-flex mt-3">
                    <div class="col-md-4">Shopify Order Id</div>
                    <div class="col-md-6">: {{fullData.shopify_order_id}}</div>
                  </div>
                  <!-- <div 
                   class="mt-4" style="margin-top: 34px!important;">

                    <p class="mt-1">Delivery Tracking : <span><a href="https://www.delhivery.com/track/package/{{fullData.awb}}" class="dec" target="_blank">{{fullData.awb}}</a>
                    </span></p>
                  </div> -->
                  <!-- <div>
                    <p>Quantity : {{fullData[0].qty}}</p>
                  </div> -->
                  <!-- <div>
                    <p>Payment Method : {{fullData.payment_method}}</p>
                  </div>
                  <div>
                    <p>Status : {{fullData.status}}</p>
                  </div>
                  <div>
                    <p>Shipping Address: {{fullData.address}}</p>
                  </div>
                  <div>
                    <p>Pincode : {{fullData.pincode}}</p>
                  </div> -->
                </div>
              </div>
              <div class="col-md-12 d-flex justify-content-between mt-3 mb-5">
                <div class="ms-4">
                     <button class="mt-1"  style="background-color: rgb(245, 117, 117); color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"  (click)="route()">Back</button>
               </div>
               <div class="d-flex me-5" *ngIf="searchdata1 || searchdata2; else showMessage">
                 <div *ngIf="searchdata1">
                   <button class="bg-primary mt-1" style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width:112px; height: 40px;" (click)="open1(content1)">Return Order</button>
                 </div>
                 <div *ngIf="searchdata2">
                   <button class="mt-1 me-2 ms-4" style="background-color: rgb(245, 117, 117); color: white; border-radius: 6px; padding: 8px; border: none; width: 112px; height: 40px;" (click)="open(content)">Cancel Order</button>
                 </div>
               </div>
             </div>
               <ng-template #showMessage>
                 <p style="color: red;" class="mt-4">order is not available for return or cancellation</p>
               </ng-template>

            </div>
          </div>

        </div>
        

      </div>
  </div>
  <ng-template #content1 let-modal>
      <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center">
           <img src="assets/images/warning.png">

        </div>
        <h3 class="d-flex justify-content-center">Are You Sure !</h3>
        <p class="d-flex justify-content-center">You want to Return this order</p>
        <form [formGroup]="userHolidayCreate">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="">Name</label>
              <input type="text" formControlName="name" class="form-control" id="name" placeholder="Name" readonly />
              <div *ngIf="userHolidayCreate!.get('name')?.invalid && userHolidayCreate!.get('name')?.touched" class="text-danger" >
                Name field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Email</label>
              <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email" readonly />
              <div *ngIf="userHolidayCreate!.get('email')?.invalid && userHolidayCreate!.get('email')?.touched" class="text-danger" >
                Email field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Phone Number</label>
              <input type="text" formControlName="phone" class="form-control" id="phone" placeholder="Phone Number" (keypress)="numericOnly($event)" minlength="10" maxlength="10"  readonly/>
              <div class="text-danger" *ngIf="userHolidayCreate.get('phone').invalid && (userHolidayCreate.get('phone').dirty || userHolidayCreate.get('phone').touched)">
                Phone Number must contain 10 digits
            </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Address</label>
              <input type="text" formControlName="address" class="form-control" id="address" placeholder="address" readonly />
              <div *ngIf="userHolidayCreate!.get('address')?.invalid && userHolidayCreate!.get('address')?.touched" class="text-danger" >
                Address field is required
              </div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
              <label for="">Account Id</label>
              <input type="text" formControlName="account_id" class="form-control" id="account_id" placeholder="Account Id" (keypress)="numericOnly($event)"  />
              <div *ngIf="userHolidayCreate!.get('account_id')?.invalid && userHolidayCreate!.get('account_id')?.touched" class="text-danger" >
                Account Id must contain 15 digits
              </div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
              <label for="">IFSC Code</label>
              <input type="text" formControlName="ifsc" class="form-control" id="ifsc" placeholder="IFSC Code" (keypress)="alphanumericOnly1($event)"  />
              <div *ngIf="userHolidayCreate!.get('ifsc')?.invalid && userHolidayCreate!.get('ifsc')?.touched" class="text-danger" >
                IFSC code must contain 12 characters
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Pincode</label>
              <input type="text" formControlName="pincode" class="form-control" id="pincode" placeholder="Pincode" (keypress)="numericOnly($event)" maxlength="6" minlength="6" readonly />
              <div *ngIf="userHolidayCreate!.get('pincode')?.invalid && userHolidayCreate!.get('pincode')?.touched" class="text-danger" >
                Pincode must contain 6 digits
              </div>
            </div>
            
            <div class="col-md-6 mb-3">
              <label for="">Reason</label>
              <input type="text" formControlName="reason" class="form-control" id="reason" placeholder="Reason" (blur)="only_spl_and_num_not_accept($event,'Reason')" />
              <div *ngIf="userHolidayCreate!.get('reason')?.invalid && userHolidayCreate!.get('reason')?.touched" class="text-danger" >
                Reason field is required
              </div>
            </div>
          </div>
          
        </form>
       
  
      </div>
      <div class="modal-footer d-flex justify-content-center">
      
       <button type="button" class="btn btn-success" [disabled]="userHolidayCreate.invalid" (click)="update_status('cancel_return')" style="width: 80px;" [disabled]="!userHolidayCreate.valid">Yes</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')" style="width: 80px;">No</button>
      </div>
      <ng-container *ngIf="showOverlay" id="overlay-container">
        <app-spinner [overlay]="true" id="loader"></app-spinner>
      </ng-container>
  </ng-template>
  <ng-template #content let-modal>
      <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center">
           <img src="assets/images/warning.png">

        </div>
        <h3 class="d-flex justify-content-center">Are You Sure !</h3>
        <p class="d-flex justify-content-center">You want to cancel this order</p>
        <form [formGroup]="userHolidayCreate">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="">Name</label>
              <input type="text" formControlName="name" class="form-control" id="name" placeholder="Name"  readonly/>
              <div *ngIf="userHolidayCreate!.get('name')?.invalid && userHolidayCreate!.get('name')?.touched" class="text-danger" >
                Name field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Email</label>
              <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email"  readonly/>
              <div *ngIf="userHolidayCreate!.get('email')?.invalid && userHolidayCreate!.get('email')?.touched" class="text-danger" >
                Email field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Phone Number</label>
              <input type="text" formControlName="phone" class="form-control" id="phone" placeholder="Phone Number" (keypress)="numericOnly($event)" minlength="10" maxlength="10" readonly />
              <div class="text-danger" *ngIf="userHolidayCreate.get('phone').invalid && (userHolidayCreate.get('phone').dirty || userHolidayCreate.get('phone').touched)">
                Phone Number must contain 10 numbers
            </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="">Address</label>
              <input type="text" formControlName="address" class="form-control" id="address" placeholder="address" readonly />
              <div *ngIf="userHolidayCreate!.get('address')?.invalid && userHolidayCreate!.get('address')?.touched" class="text-danger" >
                Address field is required
              </div>
            </div>
            <!-- <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
              <label for="">Account Id</label>
              <input type="text" formControlName="account_id" class="form-control" id="account_id" placeholder="Account Id" (keypress)="numericOnly($event)" minlength="15" maxlength="15" />
              <div *ngIf="userHolidayCreate!.get('account_id')?.invalid && userHolidayCreate!.get('account_id')?.touched" class="text-danger" >
                Account filed is required
              </div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="this.payment_method === 'COD'">
              <label for="">IFSC Code</label>
              <input type="text" formControlName="ifsc" class="form-control" id="ifsc" placeholder="IFSC Code" (keypress)="alphanumericOnly1($event)" maxlength="12" minlength="12" />
              <div *ngIf="userHolidayCreate!.get('ifsc')?.invalid && userHolidayCreate!.get('ifsc')?.touched" class="text-danger" >
                IFSC code filed is required
              </div>
            </div> -->
            <div class="col-md-6 mb-3">
              <label for="">Reason</label>
              <input type="text" formControlName="reason" class="form-control" id="reason" placeholder="Reason" (blur)="only_spl_and_num_not_accept($event,'Reason')" />
              <div *ngIf="userHolidayCreate!.get('reason')?.invalid && userHolidayCreate!.get('reason')?.touched" class="text-danger" >
                Reason field is required
              </div>
            </div>
          </div>
          
        </form>
    
     
  
      </div>
      <div class="modal-footer d-flex justify-content-center">
      
       <button type="button" class="btn btn-success"  style="width: 80px;" [disabled]="userHolidayCreate.invalid || loading" (click)="update_status('cancel_order')">Yes</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')" style="width: 80px;">No</button>
      </div>
      <ng-container *ngIf="showOverlay" id="overlay-container">
        <app-spinner [overlay]="true" id="loader"></app-spinner>
      </ng-container>
  </ng-template>
  <!-- <button  class="btn btn-lg btn-outline-primary"   (click)="open(content)">Launch demo modal</button> -->

</div>


