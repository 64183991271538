<div class="container">
  <div class="row">
    <h1 class="page mt-3 ms-2">Order Ticket View</h1>
    <div class="mt-3">
      <div class="container col-md-12 d-flex justify-content-between laptop">
        <div class="row d-flex col-md-12 justify-content-between">
          <div class="col-md-6">
            <div>
              <div class="d-flex justify-content-between">
                <!-- <img src="assets/images/ticket.png" width="50px" height="50px"> -->
                <div>
                  <span class="d-block">
                    <span style="font-weight: 700; font-size: 20px;">Ticket Id : <span
                        style="color: blue;">{{fullData?.ticket_id}}</span></span>
                    <p class="updated_at_text" style="font-size: 9px; font-weight: 900;"
                      [matTooltip]="fullData?.updated_at.length > 26 ? fullData?.updated_at : null">
                      Updated At : &nbsp;{{ fullData?.updated_at | slice:0:26 }}{{ fullData?.updated_at.length > 26 ?
                      '...' : ''
                      }}
                    </p>
                  </span>
                </div>
                <div>
                  <span class="updated_at_text" style="font-size: 9px; font-weight: 900;"
                  [matTooltip]="fullData?.updated_at.length > 26 ? fullData?.updated_at : null">
                  Order Id : &nbsp;{{ fullData?.order_id | slice:0:26 }}{{ fullData?.order_id.length > 26 ?
                  '...' : ''
                  }}
                </span><br>
                <span class="updated_at_text" style="font-size: 9px; font-weight: 900;"
                [matTooltip]="fullData?.updated_at.length > 26 ? fullData?.updated_at : null">
                Awb : &nbsp;{{ fullData?.awb | slice:0:26 }}{{ fullData?.awb.length > 26 ?
                '...' : ''
                }}
              </span>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col-md-12">
                  <div class="d-block">
                    <div class="d-flex  p-2 ps-0">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Category
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.category.length > 26 ? fullData?.category : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.category | slice:0:26 }}{{ fullData?.category.length > 26 ? '...' : ''
                        }}
                      </div>
                    </div>
                    <div class="d-flex p-2 ps-0">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Voice Of Customer
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.voice_of_customer.length > 26 ? fullData?.voice_of_customer : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.voice_of_customer | slice:0:30 }}{{ fullData?.voice_of_customer.length > 30
                        ? '...' : ''
                        }}
                      </div>
                    </div>
                    <div class="d-flex p-2 ps-0">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Agent Remark
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.agent_remark.length > 26 ? fullData?.agent_remark : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.agent_remark | slice:0:30 }}{{ fullData?.agent_remark.length > 30
                        ? '...' : ''
                        }}
                      </div>
                    </div>
                    <div class="d-flex p-2 ps-0" *ngIf="superuser === 'false' && fullData?.assigned_to !== null">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Assigned To
                      </div>
                      <div class="col-md-6 tick_value"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.assigned_to | slice:0:30 }}
                      </div>
                    </div>
                    
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div class="d-block">
                    <div class="d-flex p-3">
                      <div class="col-md-6 tick_key" style="cursor: pointer;">
                        Voice Of Customer
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.voice_of_customer.length > 26 ? fullData?.voice_of_customer : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.voice_of_customer | slice:0:14 }}{{ fullData?.voice_of_customer.length > 14}}
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div>
                <div class="d-flex justify-content-between col-md-12">
                  <p><span>{{fullData?.created_at}}</span></p>
                  <div class="d-flex justify-content-center">
                    <p>Updated At : <span>{{fullData?.updated_at}}</span></p>
                  </div> 
                </div>
              </div> -->
              <div style="display: none;">
                <div class="d-flex justify-content-between col-md-12">
                  <p>Agent Remark : <span>{{fullData?.agent_remark}}</span></p>
                  <div class="d-flex justify-content-center">
                    <p>Voice Of Customer : <span>{{fullData?.voice_of_customer}}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-between col-md-12">
              <p>Category : <span>{{fullData?.category}}</span></p>
            </div> -->
            <form class="col-md-12" [formGroup]="createuserform1">
              <div class="row  d-flex justify-content-between">
                <div class="col-md-6 mb-3">
                  <label for="status">Status</label>
                  <select formControlName="status" class="form-select" id="status1" [(ngModel)]="task_details_status">
                    <option value="open">Open</option>
                    <option value="close">Close</option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="disposition">Disposition</label>
                  <select formControlName="disposition" class="form-select" id="disposition"
                    [(ngModel)]="task_details_disposition" *ngIf="superuser === 'true'">
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                    <option value="in progress">In Progress</option>
                    <option value="resolved">Resolved</option>
                  </select>

                  <input type="text" formControlName="disposition" readonly [(ngModel)]="task_details_disposition"
                    class="form-control" placeholder="Disposition" *ngIf="superuser === 'false'" />

                </div>
                <div class="col-md-12 mb-3" *ngIf="superuser === 'true'">
                  <label for="portalname mb-5">Assigned To</label>
                  <!-- <select class="form-select" aria-label="Default select example" formControlName="assigned_to"
                    [(ngModel)]="assigned_to_ticket">
                    <option *ngFor="let name of userdata" [value]="name.username">
                      {{ name.username }}
                    </option>
                  </select> -->
                  <select class="form-select" aria-label="Default select example" formControlName="assigned_to"
                    *ngIf="superuser === 'true'" [(ngModel)]="assigned_to_ticket">
                    <option *ngFor="let name of userdata" [value]="name.username">
                      {{ name.username }}
                    </option>
                  </select>

                  <input type="text" formControlName="assigned_to" readonly [(ngModel)]="assigned_to_ticket"
                    class="form-control" placeholder="Assigned TO" *ngIf="superuser === 'false'" />

                  <div
                    *ngIf="createuserform1!.get('assigned_to')?.invalid && createuserform1!.get('assigned_to')?.touched"
                    class="text-danger">
                    Portal Name field is required
                  </div>
                </div>
              </div>

            </form>
            <div class="d-flex justify-content-end mt-3 mb-3">
              <button class="bg-primary"
                style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width:112px; height: 40px;"
                (click)="ticket_update()">Submit</button>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="mainPanel col-md-12 mb-5 d-flex justify-content-center" height="300px">
              <div class="panel">
                <div class="headerPanel"> 
                  <span class="userName">Support Chat</span>
                  <div class="actions"><i class="anticon anticon-close"></i></div>
                </div>
                <div class="chatPresent" id="chatPresent" #chatPresent>
                  <div class="chatMainPanel" *ngFor="let item of searchdata"> 
                    <div class="" *ngFor="let entry of item | keyvalue">
                      <span class="metaMainPanel" *ngFor="let entry of item | keyvalue">
                        <span class="timeMainPanel">{{entry.key}}</span>
                      </span>
                      <div class="bubble"><span>{{entry.value}}</span></div> 
                    </div>
                  </div>

                  <div class="my-message" *ngFor="let msge of myMessageData">
                    <span class="meta-my-message"> 
                    </span>
                    <div class="bubble-my-message"><span>{{msge[storedUsername]}}</span></div>
                  </div>
                </div>
                <div class="input">
                  <input type="text" class="form-control" [(ngModel)]="inputText" id="support_chart"
                    placeholder="Send Message" #text /> 
                  <span class="suffix">
                    <span class="send" (click)="myMessage(text.value)">send</span>
                  </span>
                </div>
              </div>
            </div>


          </div> -->

          <div class="col-md-6">
            <div class="mainPanel col-md-12 mb-5 d-flex justify-content-center" height="300px">
              <div class="panel">
                <div class="headerPanel">
                  <span class="userName">Support Chat</span>
                  <div class="actions"><i class="anticon anticon-close"></i></div>
                </div>
                <div class="chatPresent" id="chatPresent" #chatPresent>
                  <div class="chatMainPanel" *ngFor="let item of chatbot_msgs" style="overflow: hidden !important;">
                    <div class="message-scroll-container">
                      <div *ngFor="let entry of item | keyvalue" class="message-container"
                        [ngClass]="{'left': username != entry.key, 'right': username === entry.key}">
                        <div class="justify-content-start" *ngIf="username != entry.key">
                          <span class="metaMainPanel">
                            <span class="timeMainPanel">{{ entry.key }}</span>
                          </span>
                          <div class="bubble" *ngIf="entry.value"><span>{{ entry.value }}</span></div>
                        </div>

                        <div class="justify-content-end" *ngIf="username === entry.key">
                          <!-- <span class="metaMainPanel">
                            <span class="timeMainPanel">{{ entry.key }}</span>
                          </span> -->
                          <div class="bubble-my-message" *ngIf="entry.value"><span>{{ entry.value }}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="my-message" *ngFor="let msge of show_make_my_msg">
                    <span class="meta-my-message">
                    </span>
                    <div class="bubble-my-message"><span>{{msge}}</span></div>
                  </div> -->
                </div>
                <div class="input">
                  <input type="text" class="form-control" [(ngModel)]="inputText" id="support_chart"
                    placeholder="Send Message" (keydown.enter)="addmsg(text.value)" #text />
                  <span class="suffix">
                    <!-- <span class="send" (click)="addmsg(text.value)">send</span> -->
                    <span class="send" (click)="addmsg(text.value)"><img src="assets/images/sendicon.png" width="24px" height="24px"></span>
                  </span>
                </div>
              </div>
            </div>


          </div>

        </div>


      </div>
      <div class="mobile">
        <div>
          <div>
            <div>
              <div class="d-flex justify-content-between">
                <!-- <img src="assets/images/ticket.png" width="50px" height="50px"> -->
                <div>
                  <span class="d-block">
                    <span style="font-weight: 700; font-size: 20px;">Ticket Id : <span
                        style="color: blue;">{{fullData?.ticket_id}}</span></span>
                    <p class="updated_at_text" style="font-size: 9px; font-weight: 900;"
                      [matTooltip]="fullData?.updated_at.length > 26 ? fullData?.updated_at : null">
                      Updated At : &nbsp;{{ fullData?.updated_at | slice:0:26 }}{{ fullData?.updated_at.length > 26 ?
                      '...' : ''
                      }}
                    </p>
                  </span>
                </div>
                <div>
                  <span class="updated_at_text" style="font-size: 9px; font-weight: 900;"
                  [matTooltip]="fullData?.updated_at.length > 26 ? fullData?.updated_at : null">
                  Order Id : &nbsp;{{ fullData?.order_id | slice:0:26 }}{{ fullData?.order_id.length > 26 ?
                  '...' : ''
                  }}
                </span><br>
                <span class="updated_at_text" style="font-size: 9px; font-weight: 900;"
                [matTooltip]="fullData?.updated_at.length > 26 ? fullData?.updated_at : null">
                Awb : &nbsp;{{ fullData?.awb | slice:0:26 }}{{ fullData?.awb.length > 26 ?
                '...' : ''
                }}
              </span>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col-md-12">
                  <div class="d-block">
                    <div class="d-flex  p-2 ps-0">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Category
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.category.length > 26 ? fullData?.category : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.category | slice:0:26 }}{{ fullData?.category.length > 26 ? '...' : ''
                        }}
                      </div>
                    </div>
                    <div class="d-flex p-2 ps-0">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Voice Of Customer
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.voice_of_customer.length > 26 ? fullData?.voice_of_customer : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.voice_of_customer | slice:0:30 }}{{ fullData?.voice_of_customer.length > 30
                        ? '...' : ''
                        }}
                      </div>
                    </div>
                    <div class="d-flex p-2 ps-0">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Agent Remark
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.agent_remark.length > 26 ? fullData?.agent_remark : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.agent_remark | slice:0:30 }}{{ fullData?.agent_remark.length > 30
                        ? '...' : ''
                        }}
                      </div>
                    </div>
                    <div class="d-flex p-2 ps-0" *ngIf="superuser === 'false' && fullData?.assigned_to !== null">
                      <div class="col-md-4 tick_key" style="cursor: pointer;">
                        Assigned To
                      </div>
                      <div class="col-md-6 tick_value"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.assigned_to | slice:0:30 }}
                      </div>
                    </div>
                    
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div class="d-block">
                    <div class="d-flex p-3">
                      <div class="col-md-6 tick_key" style="cursor: pointer;">
                        Voice Of Customer
                      </div>
                      <div class="col-md-6 tick_value"
                        [matTooltip]="fullData?.voice_of_customer.length > 26 ? fullData?.voice_of_customer : null"
                        style="cursor: pointer;">
                        : &nbsp;{{ fullData?.voice_of_customer | slice:0:14 }}{{ fullData?.voice_of_customer.length > 14}}
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div>
                <div class="d-flex justify-content-between col-md-12">
                  <p><span>{{fullData?.created_at}}</span></p>
                  <div class="d-flex justify-content-center">
                    <p>Updated At : <span>{{fullData?.updated_at}}</span></p>
                  </div> 
                </div>
              </div> -->
              <div style="display: none;">
                <div class="d-flex justify-content-between col-md-12">
                  <p>Agent Remark : <span>{{fullData?.agent_remark}}</span></p>
                  <div class="d-flex justify-content-center">
                    <p>Voice Of Customer : <span>{{fullData?.voice_of_customer}}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-between col-md-12">
              <p>Category : <span>{{fullData?.category}}</span></p>
            </div> -->
            <form class="col-md-12" [formGroup]="createuserform1">
              <div class="row  d-flex justify-content-between">
                <div class="col-md-6 mb-3">
                  <label for="status">Status</label>
                  <select formControlName="status" class="form-select" id="status1" [(ngModel)]="task_details_status">
                    <option value="open">Open</option>
                    <option value="close">Close</option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="disposition">Disposition</label>
                  <select formControlName="disposition" class="form-select" id="disposition"
                    [(ngModel)]="task_details_disposition" *ngIf="superuser === 'true'">
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                    <option value="in progress">In Progress</option>
                    <option value="resolved">Resolved</option>
                  </select>

                  <input type="text" formControlName="disposition" readonly [(ngModel)]="task_details_disposition"
                    class="form-control" placeholder="Disposition" *ngIf="superuser === 'false'" />

                </div>
                <div class="col-md-12 mb-3" *ngIf="superuser === 'true'">
                  <label for="portalname mb-5">Assigned To</label>
                  <!-- <select class="form-select" aria-label="Default select example" formControlName="assigned_to"
                    [(ngModel)]="assigned_to_ticket">
                    <option *ngFor="let name of userdata" [value]="name.username">
                      {{ name.username }}
                    </option>
                  </select> -->
                  <select class="form-select" aria-label="Default select example" formControlName="assigned_to"
                    *ngIf="superuser === 'true'" [(ngModel)]="assigned_to_ticket">
                    <option *ngFor="let name of userdata" [value]="name.username">
                      {{ name.username }}
                    </option>
                  </select>

                  <input type="text" formControlName="assigned_to" readonly [(ngModel)]="assigned_to_ticket"
                    class="form-control" placeholder="Assigned TO" *ngIf="superuser === 'false'" />

                  <div
                    *ngIf="createuserform1!.get('assigned_to')?.invalid && createuserform1!.get('assigned_to')?.touched"
                    class="text-danger">
                    Portal Name field is required
                  </div>
                </div>
              </div>

            </form>
            <div class="d-flex justify-content-end mt-3 mb-3">
              <button class="bg-primary"
                style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width:112px; height: 40px;"
                (click)="ticket_update()">Submit</button>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="mainPanel col-md-12 mb-5 d-flex justify-content-center" height="300px">
              <div class="panel">
                <div class="headerPanel"> 
                  <span class="userName">Support Chat</span>
                  <div class="actions"><i class="anticon anticon-close"></i></div>
                </div>
                <div class="chatPresent" id="chatPresent" #chatPresent>
                  <div class="chatMainPanel" *ngFor="let item of searchdata"> 
                    <div class="" *ngFor="let entry of item | keyvalue">
                      <span class="metaMainPanel" *ngFor="let entry of item | keyvalue">
                        <span class="timeMainPanel">{{entry.key}}</span>
                      </span>
                      <div class="bubble"><span>{{entry.value}}</span></div> 
                    </div>
                  </div>

                  <div class="my-message" *ngFor="let msge of myMessageData">
                    <span class="meta-my-message"> 
                    </span>
                    <div class="bubble-my-message"><span>{{msge[storedUsername]}}</span></div>
                  </div>
                </div>
                <div class="input">
                  <input type="text" class="form-control" [(ngModel)]="inputText" id="support_chart"
                    placeholder="Send Message" #text /> 
                  <span class="suffix">
                    <span class="send" (click)="myMessage(text.value)">send</span>
                  </span>
                </div>
              </div>
            </div>


          </div> -->

          <div>
            <div class="mainPanel col-md-12 mb-5 d-flex justify-content-center" height="300px">
              <div class="panel">
                <div class="headerPanel">
                  <span class="userName">Support Chat</span>
                  <div class="actions"><i class="anticon anticon-close"></i></div>
                </div>
                <div class="chatPresent" id="chatPresent" #chatPresent>
                  <div class="chatMainPanel" *ngFor="let item of chatbot_msgs" style="overflow: hidden !important;">
                    <div class="message-scroll-container">
                      <div *ngFor="let entry of item | keyvalue" class="message-container"
                        [ngClass]="{'left': username != entry.key, 'right': username === entry.key}">
                        <div class="justify-content-start" *ngIf="username != entry.key">
                          <span class="metaMainPanel">
                            <span class="timeMainPanel">{{ entry.key }}</span>
                          </span>
                          <div class="bubble" *ngIf="entry.value"><span>{{ entry.value }}</span></div>
                        </div>

                        <div class="justify-content-end" *ngIf="username === entry.key">
                          <!-- <span class="metaMainPanel">
                            <span class="timeMainPanel">{{ entry.key }}</span>
                          </span> -->
                          <div class="bubble-my-message" *ngIf="entry.value"><span>{{ entry.value }}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="my-message" *ngFor="let msge of show_make_my_msg">
                    <span class="meta-my-message">
                    </span>
                    <div class="bubble-my-message"><span>{{msge}}</span></div>
                  </div> -->
                </div>
                <div class="input">
                  <input type="text" class="form-control" [(ngModel)]="inputText" id="support_chart"
                    placeholder="Send Message" (keydown.enter)="addmsg(text.value)" #text />
                  <span class="suffix">
                    <!-- <span class="send" (click)="addmsg(text.value)">send</span> -->
                    <span class="send" (click)="addmsg(text.value)"><img src="assets/images/sendicon.png" width="24px" height="24px"></span>
                  </span>
                </div>
              </div>
            </div>


          </div>

        </div>


      </div>


    </div>
  </div>

</div>

<ng-container *ngIf="showOverlay" id="overlay-container">
  <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>