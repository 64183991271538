import { Component, OnInit, Inject, Output, EventEmitter ,TemplateRef,ViewEncapsulation, inject,ViewChild, ElementRef} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { OpenComponent } from 'src/app/pages/open/open.component';
import { ModeServicesService } from 'src/app/core/services/mode-services.service';



import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
// import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { LanguageService } from '../../../core/services/language.service';
import { environment } from '../../../../environments/environment';
import { CreateuserComponent } from 'src/app/pages/createuser/createuser.component';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Support } from 'aws-sdk';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  element: any;
  selectedPortalName: string = 'Select Portal Name';
  selectedPortalId: number = 0;
  selectedportal:number = 0;
  portal_account_id:any;
  configData: any;
  cookieValue;
  flagvalue;
  superuser:any;
  countryName;
  valueset: string;
  createuserform: UntypedFormGroup;
  createuserform1: UntypedFormGroup;
  createuserform4: UntypedFormGroup;
  createuserform5: UntypedFormGroup;
  showOverlay=false;
  fullData:any;
  portalname:any;
  portalid:any;
  portalaccountid:any;
  d2cname:any;
  username:any
  count:any;
  isLightModeOn: boolean = true; 
  isDarkModeOn: boolean = false;
  isDarkMode = false;
  selectedOption = 'order_id';
  searchTerm ='';
  data:any;
  isOptionSelected = false;
  isCancelled = false;
  portal_id1:any;
  portal_account_id1:any
  isDropdownOpen = false;
  portal_id:any
  dropdownVisible = false;
  username1:any
  is_whatsapp:any
  order_notifications:any
  is_whatsapp_marketing:any
  custom_notifications:any

  showPassword: boolean = false;

  searchdata:any;
  @ViewChild(ProfileComponent) profileComponent!: ProfileComponent;  
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();
  @ViewChild('modalButton') modalButton!: ElementRef;
  @ViewChild('modalButton1') modalButton1!: ElementRef;

  @Output() drakmodeChanged: EventEmitter<any> = new EventEmitter<any>();

  onLightModeChange1(): void {
    this.drakmodeChanged.emit(this.isDarkModeOn);
  }

  onDarkModeChange1(): void {
    this.drakmodeChanged.emit(this.isDarkModeOn);
  }




  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  // tslint:disable-next-line: max-line-length
  private modalService = inject(NgbModal);

  constructor(@Inject(DOCUMENT)
   private document: any, 
   private router: Router, 
   private authService: AuthenticationService,
   private authFackservice: AuthfakeauthenticationService, 
   public languageService: LanguageService, 
   public cookiesService: CookieService,    
   private _dialog:MatDialog,
   private formBuilder: UntypedFormBuilder,
   private toastr: ToastrService,
   private modalServicee: NgbModal,
   public profile_Service :ProfileService,
   private mode_service : ModeServicesService,
  public dialog: MatDialog) { 

 



  }

 

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password') as HTMLInputElement;
    passwordInput.type = this.showPassword ? 'text' : 'password';
  }
  

  ngOnInit(): void {
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.initform();
    this.fetchdata();
    // this.unread_ticket()
    this.is_whatsapp = localStorage.getItem('is_whatsapp')
    this.is_whatsapp_marketing = localStorage.getItem('is_whatsapp_marketing')
    this.order_notifications = localStorage.getItem('order_notifications')
    this.custom_notifications = localStorage.getItem('custom_notifications')
    
    this.profile_Service.fetchdata();
  this.superuser = localStorage.getItem('is_superuser')
  this.portalname = localStorage.getItem('portal_name')
  this.portalid = localStorage.getItem('portal_id')
  this.portalaccountid = localStorage.getItem('portal_account_id')
  this.d2cname = localStorage.getItem('d2c_name')
  this.count = localStorage.getItem('count')
  // console.log('count',this.count)
  }
  
  

  initform(){
    this.createuserform = this.formBuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required]],
      username: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      is_active: ['true', [Validators.required]],
      is_superuser: ['false', [Validators.required]],
      portal_name: ['', [Validators.required]],
      portal_id: ['', [Validators.required]],
      portal_account_id: ['', [Validators.required]],
      d2c_name: ['', [Validators.required]],


    });
    this.createuserform1 = this.formBuilder.group({
      category: ['', [Validators.required]],
      // status: ['open', [Validators.required]],
      // disposition: ['pending', [Validators.required]],
      order_id: [''],
      awb: [''],
      voice_of_customer: [''],
      agent_remark: ['', [Validators.required]],
      is_order_issue: ['false', [Validators.required]],
      support_chat: ['', [Validators.required]],
    });

    this.createuserform4 = this.formBuilder.group({
      is_whatsapp: ['', [Validators.required]],
      is_whatsapp_marketing: ['',[Validators.required]],
      order_notifications: ['',[Validators.required]],
      custom_notifications: ['',[Validators.required]],
      credit_amount: ['', [Validators.required]],
      portal_account_id: ['',[Validators.required]],
      order_amount: ['', [Validators.required]],
      marketing_amount:['', [Validators.required]]

    });

    this.createuserform5 = this.formBuilder.group({
      type: ['', [Validators.required]],
      subtype: ['',[Validators.required]],
 
    });
  }



  // Listen to changes in the selected portal name
  onPortalNameChange() {
    const selectedPortal = this.fullData.find((portal) => portal.portal_name === this.selectedPortalName);
    this.selectedPortalId = selectedPortal ? selectedPortal.portal_account_id : 0;
    this.selectedportal = selectedPortal ? selectedPortal.portal_id : 0;

  }



  
  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
    console.log('data')
  }


  
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  @Output() darkModeChanged = new EventEmitter<boolean>();


  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Translate language
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }


  /**
   * Logout the user
   */
  logout() {
    // if (environment.defaultauth === 'firebase') {
    //   this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    this.router.navigate(['/auth/login']);
  }


  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(CreateuserComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
    
  openVerticallyCentered(content: TemplateRef<any>) {
		this.modalService.open(content, { centered: true,size: 'lg'});
	}
  openVerticallyCentered1(content3: TemplateRef<any>) {
		this.modalService.open(content3, { centered: true,size: 'lg'});
	}
  openVerticallyCentered2(content3: TemplateRef<any>) {
		this.modalService.open(content3, { centered: true,size: 'lg'});
	}

  openVerticallyCentered3(content3: TemplateRef<any>) {
		this.modalService.open(content3, { centered: true,size: 'md'});
	}


  onSubmit() {
    this.showOverlay=true;
    let data = this.createuserform.value
    this.authService.signup(data).subscribe((res: any) => {
      if(res.statusCode === 201){
        this.toastr.success(res.success, 'Success')
        this.showOverlay=false
        this.modalService.dismissAll('Close click');
        this.createuserform.reset()



      }
      else{
        this.toastr.error(res.error, 'Error')
        this.showOverlay=false
        // this.modalService.dismissAll('Close click');




      }
    })
      // .catch(error => {
        //   this.error = error ? error : '';
        // });
    
  }
  numericOnly(event): boolean {    
    let patt = /^[0-9 .]+$/;
    let result = patt.test(event.key);
    return result;
  }
 
  fetchdata(){
    this.showOverlay=true;
    this.authService.portal_details_get1().subscribe((res: any) => {
      // console.log(res)
      let mes=JSON.parse(res)
      // console.log(mes.statusCode)
      if (mes.statusCode === 200) {
        this.showOverlay = false;
        this.fullData = mes.body;
        // console.log('fullData', mes.body);


      }
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          this.authService.logout1()
          this.showOverlay=false
          this.router.navigate(['/auth/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
        }
      }
      
    })

  }
  // onInput(inputId: string) {
  //   if (!this.isInputReadonly(inputId)) {
  //     this.toastr.warning(`Editing ${this.getLabel(inputId)} is not allowed.`, 'Warning');
  //   }
  // }
  showToastrMessage(inputId: string) {
    // const label = this.getLabel(inputId);
    this.toastr.warning(`You cannot edit this field`, 'Warning');
  }

  private isInputReadonly(inputId: string): boolean {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    return inputElement && inputElement.readOnly;
  }

  // private getLabel(inputId: string): string {
  //   switch (inputId) {
  //     case 'portal_id':
  //       return 'Portal Id';
  //     case 'another_input_id':
  //       return 'Another Input Label';
  //     // Add cases for other input fields
  //     default:
  //       return '';
  //   }
  // }

  onLightModeChange(): void {
    // When the light mode switch changes, disable the dark mode switch
    this.isDarkModeOn = false;
    document.body.setAttribute('data-bs-theme','light')
    this.isLightModeOn = true;
    this.mode_service.setIsDarkModeOn(this.isDarkModeOn); // Update the value in the shared service


  }

  onDarkModeChange(): void {
    // When the dark mode switch changes, disable the light mode switch
    this.isLightModeOn = false;
    document.body.setAttribute('data-bs-theme','dark')
    this.isDarkModeOn = true;
    this.mode_service.setIsDarkModeOn(this.isDarkModeOn); // Update the value in the shared service


  }
  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
  }

  toggleTheme() {
    this.isDarkMode = !this.isDarkMode;
    // Implement your theme toggling logic here
  }

  





placeholder_name = 'Search For Order Id'
setplaceholder(data:any){
    this.placeholder_name=data
    this.isOptionSelected = true;
    console.log(this.placeholder_name)

}

onSelectChange(event: any) {
  const selectedValue = event.target.value;
  this.setPlaceholder(selectedValue);
}
placeholderText = 'Search For Order id'

setPlaceholder(selectedValue: string) {
  switch (selectedValue) {
    case 'order_id':
      this.placeholderText = 'Search For Order id';
      break;
    case 'awb':
      this.placeholderText = 'Search For Awb';
      break;
    case 'phone':
      this.placeholderText = 'Search With Number';
      break;
    default:
      this.placeholderText = '';
      break;
  }
}
applyFilter(filterValue: string) {
  this.searchTerm = filterValue; 
  if (this.selectedOption && this.searchTerm) {
    // this.combinedValue = `${this.selectedOption}:${this.searchTerm}`;
    this.ordersearch(this.searchTerm,this.selectedOption);
  }
}
  // console.log(filterValue.value);
  // this.customers.filter = filterValue.value.trim().toLowerCase();
  // //  this. refreshIcon=true
  // if (this.customers.paginator) {
  //   this.customers.paginator.firstPage();
  // }
  portal_name:any;
  d2c_name:any;

onSearchChange() {
  // Trigger API call when the search term changes
}
ordersearch(value:any,key:any){
  this.showOverlay = true;

  this.superuser = localStorage.getItem('is_superuser')

  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }

  // console.log(key);

  if (key === 'order_id') {
    const cleanedOrderId = value.replace(/\t/g, ''); // Remove tab characters
    this.data = {
      'order_id': cleanedOrderId,
      'portal_account_id': this.portal_account_id
    };
  } 
  else if(key === 'awb'){
    const cleanedOrderId = value.replace(/\t/g, ''); // Remove tab characters
    this.data = {
      'awb': cleanedOrderId,
      'portal_account_id': this.portal_account_id
    };
  }
  else{
    const cleanedOrderId = value.replace(/\t/g, ''); // Remove tab characters
    this.data = {
      'phone': cleanedOrderId,
      'portal_account_id': this.portal_account_id
    };

  }
  
  // console.log(this.data)
  localStorage.setItem('dataforsearch',JSON.stringify(this.data))
 
 this.authService.orders_search(this.data).subscribe((res: any) => {
  // console.log(res);
  let mes = JSON.parse(res);
  // console.log(mes);
  if (mes.statusCode === 200) {
    // this.modalButton.nativeElement.click();
    this.searchdata = mes.Data
    console.log(this.searchdata,'this.serachdata')
    localStorage.setItem('serachdata', JSON.stringify(this.searchdata)); 
    // this.router.navigate(['/page/retailripple/details'])
    this.showOverlay = false;

    if (this.router.url === '/page/retailripple/details') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/page/retailripple/details']);
      });
    } else {
      this.router.navigate(['/page/retailripple/details']);
    }
    this.isCancelled = false;
    // this.openModal(this.searchdata);
    this.selectedOption = 'order_id'
    this.searchTerm ='';


  
   
  } 
  else{
    this.showOverlay = false;
    this.toastr.error(mes.error, 'Error')
    this.isCancelled = true;
    this.selectedOption = 'order_id'
    this.searchTerm = '';
    // this.modalButton1.nativeElement.click();




  }
 })

}
cancelSearch() {
  this.isCancelled = true;
  this.selectedOption = ''
  this.searchTerm = '';
  // You can add additional logic here based on your requirements
}

// handleDocumentClick(event: Event): void {
//   if (!event.target.closest('.card11')) {
//     this.cancelSearch();
//   }
// }

// handleCardClick(event: Event): void {
//   event.stopPropagation();
// }
handleDocumentClick(event: Event): void {
  const targetElement = event.target as HTMLElement;
  if (!targetElement.closest('.card11')) {
    this.cancelSearch();
  }
}

handleCardClick(event: Event): void {
  event.stopPropagation();
}

// openModal(data: any): void {
//   console.log(data,'kjdfgdh')
//   const dialogRef = this.dialog.open(OpenComponent, {
//     width: '400px', // Adjust the width as needed
//     height:'auto',
//     data: data, // Pass data to the modal
//   });

//   dialogRef.afterClosed().subscribe(result => {
//     console.log('The dialog was closed', result);
//   });
// }


open(content1: TemplateRef<any>) {
  this.modalService.open(content1, { ariaLabelledBy: 'modal-basic-title',size: 'xs' }).result.then(
    
  );
}
open1(content2: TemplateRef<any>) {
  this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title'}).result.then(
    
  );
}
route(){
  this.modalService.dismissAll('Close click');
  this.router.navigate(['/page/retailripple/details'])
}
support_ticket_create(){
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')


  }
  else{
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
  }
  this.portal_id1 = this.createuserform1.value.order_id.replace(/\t/g, '');
  this.portal_account_id1 = this.createuserform1.value.awb.replace(/\t/g, '');
  const username = localStorage.getItem('username')

  let data = this.createuserform1.value
  data['raised_by'] = username
  data['portal_id'] = this.portal_id
  data['portal_account_id']= this.portal_account_id
  data['is_notification_read']= false
  data['status'] = 'open'
  data['disposition'] = 'pending'

  data['support_chat'] = {
    [username] :this.createuserform1.value.support_chat
  } 
  data['order_id'] = this.portal_id1
  data['awb'] = this.portal_account_id1
  this.showOverlay=true
  this.authService.support_ticket_create(data).subscribe((res: any) => {
    console.log(res,'support ticket data');
    let mes = JSON.parse(res);
    // console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay=false
      this.toastr.success(mes.success, "");
      this.modalService.dismissAll('Close click');
      this.createuserform1.reset();

      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.createuserform1.reset();


  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}

// openDropdown() {
//   this.dropdown.open();
// }

// closeDropdown() {
//   this.dropdown.close();
// }
openDropdown() {

  this.dropdownVisible = true;
}

closeDropdown() {
  this.dropdownVisible = false;
}

// unread_ticket() {
//   this.showOverlay=true;
//   this.authService.unread_tickets().subscribe((res: any) => {
//     let mes = JSON.parse(res);
//     if(mes.statusCode === 200){
//       console.log(mes)
//       localStorage.setItem('order',mes.order_ticket_count)
//       localStorage.setItem('support',mes.support_ticket_count)


//     }
//     else{
//       this.toastr.error(res.error, 'Error')
//       this.showOverlay=false




//     }
//   })
//     // .catch(error => {
//       //   this.error = error ? error : '';
//       // });
  
// }
stopPropagation(event: Event): void {
  event.stopPropagation();
}
not_allow_only_num_and_spl_char(value: any,inpu_feild_name:any){
  if (value) {
    if (/^[0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only numbers. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
    if (/^[^a-zA-Z0-9]*$/.test(value)) {
      this.toastr.error(""+inpu_feild_name+" is containing only special characters. Please fill correctly.");
      return { nonAlphanumeric: true };
    }
  }
  return null;
}
only_spl_and_num_not_accept(event: any,input_name:any,){
  const value = event.target.value;
    this.not_allow_only_num_and_spl_char(value,input_name); 
  
}


permission_create(){
  let formData = this.createuserform4.value;

  let data = {
    'credit_amount': formData.credit_amount,
    'portal_account_id': formData.portal_account_id,
    'is_whatsapp': formData.is_whatsapp,
    'is_whatsapp_marketing': formData.is_whatsapp_marketing,
    'order_notification':formData.order_amount,
    'marketing_notification':formData.marketing_amount,
    'order_notifications':formData.order_notifications,
    'custom_notifications':formData.custom_notifications
  }
  
  let formattedData = {
    'portal_account_id': data.portal_account_id,
    'permission': {
      'is_whatsapp': data.is_whatsapp,
      'is_whatsapp_marketing': data.is_whatsapp_marketing,
      'order_notifications':data.order_notifications,
      'custom_notifications':data.custom_notifications

    },
    'credit_amount': data.credit_amount,
    'transaction': {
      'order_notification':data.order_notification,
      'marketing_notification':data.marketing_notification
    }
  };

  this.showOverlay=true
  this.authService.permission_create(formattedData).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 201) {
      this.showOverlay=false
      this.toastr.success(mes.success, "");
      this.modalService.dismissAll('Close click');
      this.createuserform4.reset();

      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.createuserform1.reset();


  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}

create_template(){
  localStorage.setItem('type',this.createuserform5.value.type)
  localStorage.setItem('subtype',this.createuserform5.value.subtype)
  this.modalService.dismissAll('Close click');
  this.createuserform5.reset()
  this.router.navigate(['/page/retailripple/whatsapp/template-preview']);

}


   

}
