import { Component,ElementRef,HostListener,ViewChild,TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment'; 
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent {

  showOverlay=false
  // pageSizeOptions: number[] = [10, 20, 50, 100]; 
  headerData: any;
  ogData:any;
  customers: any;
  // pageEvent:any;
  total_product:any
  superuser:any;
  d2c_name:any;
  portal_id:any;
  portal_account_id:any;
  searchdata:any;  
  portal_name:any;
  isCancelled: boolean = false;
  isOptionSelected: boolean = false
  startIndex:any
  editingData:any
  searchchar:boolean = false
  value:any
  createuserform4: UntypedFormGroup;
  isWhatsApp:any;
  isWhatsAppMarketing:any;
  createuserform2: UntypedFormGroup;
  showPassword:boolean = false




  fullData: any;
  apiData: any = [];
  apiData1:any = [];
  editingData1:any

    displayedColumns: string[] | undefined;


    @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined; 
    @ViewChild('inputField') inputField!: ElementRef;
    @ViewChild('inputField1') inputField1!: ElementRef;
    @ViewChild('inputFieldDivision') inputFieldDivision: ElementRef;
@ViewChild('inputFieldBrand') inputFieldBrand: ElementRef;






  constructor(
    private authService:AuthenticationService,
    private modalServicee: NgbModal,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService){

  }

  ngOnInit(): void {


    this.fetchData(this.paginate);
    this.superuser = localStorage.getItem('superuser')

    this.createuserform2 = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      username: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      email: ['', [Validators.required]],
      is_active: ['', [Validators.required]],
      is_superuser: ['', [Validators.required]],
      password: ['', [Validators.required]],
  

 
    });


  }
  
pageIndex:number = 0;
pageSize:any;

pageSizeOptions: number[] = [10, 20, 50, 100]; 
page:any;     
length:any;
page_no:number=1;
config:any;
paginate: any = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: 1
  }
pageEvent:any;

fetchData(event:PageEvent) {
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
 this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

  let payload_data = {
    'portal_account_id':this.portal_account_id,
   "page_number":page_no,
   "page_size":this.pageSize,
  };
  this.authService.users_list(payload_data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.length=mes.total_length
        this.fullData = res.body; 
        this.ogData =res.body
      this.headerData = res.header;
      this.getData(this.fullData); 


    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/account/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}

getData(data:any) {
  this.apiData = data;
  delete this.headerData.emp_leave_app_id;
  delete this.headerData.emp_id_id;
  delete this.headerData.image_path;
  delete this.headerData.id;
  this.headerData.actions = "Action"; 
  this.displayedColumns = Object.keys(this.headerData);
  this.customers = new MatTableDataSource(this.apiData);
  this.customers.sort = this.sort;

  
}

username:any
first_name:any;
last_name:any
password:any
email:any
phone_number:any
is_active:any
is_superuser:any

openVerticallyCentered1(content: TemplateRef<any>,data:any) {
  console.log(data)
  this.username = data.username
  this.first_name = data.first_name
  this.last_name = data.last_name
  this.password = data.password
  this.email = data.email
  this.phone_number = data.phone_number
  this.is_active = data.is_active
  this.is_superuser = data.is_superuser
  localStorage.setItem('idd',data.id)
  this.modalService.open(content, { centered: true,size: 'lg'});
}

numericOnly(event): boolean {    
  let patt = /^[0-9 .]+$/;
  let result = patt.test(event.key);
  return result;
}


update_user(){
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  this.showOverlay=true 


let data = this.createuserform2.value
data['user_id'] = localStorage.getItem('idd')
data['superuser'] = true
  this.authService.users_update(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 201) {
       this.showOverlay = false;
       this.toastr.success(mes.success);
       this.modalService.dismissAll()

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}

togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
  const passwordInput = document.getElementById('password') as HTMLInputElement;
  passwordInput.type = this.showPassword ? 'text' : 'password';
}







}
