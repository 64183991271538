<h1 class="page col-md-6 mb-4 mt-2">Users List</h1>
<div class="d-flex justify-content-end mt-5 laptop">
    <!-- <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup.enter)="applyFilter1($event.target.value)">
        <span class="ri-search-line search-icon"></span>
    </div> -->

    <div class="border text-whhite d-flex justify-content-end page-top">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
    <!-- <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
  </div> -->
</div>
<div class="justify-content-center mt-5 mobile">
    <!-- <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup.enter)="applyFilter1($event.target.value)">
        <span class="ri-search-line search-icon"></span>
    </div> -->

    <div class="border text-whhite d-flex justify-content-center page-top mt-2">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
    
    <!-- <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
  </div> -->
</div>
<!-- <div class="d-flex justify-content-end"> 
    <div class="border text-whhite d-flex justify-content-between" >
        <div class="position-relative col-md-3">
            <input type="text" class="form-control search-input" placeholder="Search...." (keyup)="applyFilter1($event.target)">
            <span class="ri-search-line search-icon"></span>
        </div>
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="length"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="pageEvent=fetchData($event)"></mat-paginator>
    </div>
</div> -->
<div class="row d-flex justify-content-center p-4">
    <div class="card-body" style="background-color:transparent;">
        <div class="material-datatables">
            <div class="position-relative overflow-auto">
                <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers" matSort>
                    <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i"
                       >
                        <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold" mat-sort-header
                            style="white-space: nowrap;">
                            {{ headerData[tableheader] }}
                        </th>
                        <ng-container *ngIf="tableheader=='actions'">
                            <td mat-cell class="text-center align-middle" *matCellDef="let row; index as i" style="font-size: 12px;">
                              <span style="cursor: pointer;">
                                <img src="assets/images/edit22.png" height="20px" width="20px"  (click)="openVerticallyCentered1(content,row)">
                              </span>
                            </td>
                          </ng-container>
                        <ng-container>
                          <ng-container *ngIf="tableheader === 'permission'">
                            <td mat-cell class="text-center align-middle" *matCellDef="let row; index as i" style="font-size: 12px;">
                              <ul>
                                <li  *ngIf="isWhatsApp === 'true'" style="text-align: left;">Is WhatsApp</li>
                                <li  *ngIf="isWhatsAppMarketing === 'true'" style="text-align: left;">Is WhatsApp Marketing</li>
                              </ul>
                            </td>
                          </ng-container>                          
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

            </div>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom">
            <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
              [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
        </div>
        <!-- <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="searchchar === false">
          <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
            [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
      </div> -->
    </div>


</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Update User</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="createuserform2">
      <div class="row">
        <div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="first_name">First Name</label>
              <input type="text" formControlName="first_name" class="form-control" id="first_name" placeholder="First Name"  [(ngModel)]="first_name"
              />
              <div *ngIf="createuserform2!.get('first_name')?.invalid && createuserform2!.get('first_name')?.touched"
                class="text-danger">
                First Name field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="last_name">Last Name</label>
              <input type="text" formControlName="last_name" class="form-control" id="last_name" [(ngModel)]="last_name"
                placeholder="Last Name"  />
              <div
                *ngIf="createuserform2!.get('last_name')?.invalid && createuserform2!.get('last_name')?.touched"
                class="text-danger">
                Last Name field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="username">Username</label>
              <input type="text" formControlName="username" class="form-control" id="username"  [(ngModel)]="username"
                placeholder="Enter Username"  />
              <div
                *ngIf="createuserform2!.get('username')?.invalid && createuserform2!.get('username')?.touched"
                class="text-danger">
                Username field is required
              </div>
            </div>
            <!-- <div class="col-md-6 mb-3">
              <label for="address2">Password</label>
              <input type="password" formControlName="password" class="form-control" id="password" [(ngModel)]="password"
                placeholder="Enter Password"  />
              <div
                *ngIf="createuserform2!.get('password')?.invalid && createuserform2!.get('password')?.touched"
                class="text-danger">
                Password field is required
              </div>
            </div> -->
            <div class="col-md-6 mb-3">
              <label for="password">Password</label>
              <div class="password-input">
                <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control" [(ngModel)]="password"
                  id="password" placeholder="Password" />
                <button type="button" class="btn btn-outline-secondary" (click)="togglePasswordVisibility()">
                  <i class="fa" style="color: black;"
                    [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"></i>
                </button>
              </div>
              <div *ngIf="createuserform2!.get('password')?.invalid && createuserform2!.get('password')?.touched"
                class="text-danger">
                Password field is required
              </div>
            </div>
          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="phone">Phone Number</label>
              <input type="text" formControlName="phone_number" class="form-control" id="phone"  maxlength="10" minlength="10" (keypress)="numericOnly($event)"  [(ngModel)]="phone_number"
                placeholder="Enter Phone Number" />
              <div
                *ngIf="createuserform2!.get('phone')?.invalid && createuserform2!.get('phone')?.touched"
                class="text-danger">
                Phone field is required
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="email_id">Email Id</label>
              <input type="text" formControlName="email" class="form-control" id="email" [(ngModel)]="email"
                placeholder="Enter Email"  />
              <div
                *ngIf="createuserform2!.get('email')?.invalid && createuserform2!.get('email')?.touched"
                class="text-danger">
                Email field is required
              </div>
            </div>

          </div>
          <div class="d-block d-sm-block d-xs-block d-md-flex d-lg-flex d-lg-flex d-xl-flex gap-2">
            <div class="col-md-6 mb-3">
              <label for="disposition">Is Active</label>
              <select formControlName="is_active" class="form-select"  aria-label="Default select example"  id="is_active"  [(ngModel)]="is_active">
                <option value="" selected disabled>Select an option</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
            <div class="col-md-6 mb-3">
              <label for="disposition">Is Superuser</label>
              <select formControlName="is_superuser" class="form-select"  aria-label="Default select example"  id="is_superuser" [(ngModel)]="is_superuser">
                <option value="" selected disabled>Select an option</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end me-2 mt-4">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit" (click)="update_user()"
 >Update</button>
    </div>

  </div>
  <ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>
</ng-template>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>