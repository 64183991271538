<div class="d-flex justify-content-between laptop">
    <h1 class="page col-md-6 mb-4 mt-2">Customers View</h1>
    <h1 class="page" *ngIf="d2c_name === 'shopify'">Total Customers : {{length}}</h1>

</div>
<div class="justify-content-center text-center mobile">
    <h1 class="page col-md-6 mb-4 mt-2">Customers View</h1>
    <h1 class="page" *ngIf="d2c_name === 'shopify'">Total Customers : {{length}}</h1>
</div>
<div class="d-flex justify-content-between mt-5 laptop">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup)="applyFilter1($event.target)">
        <span class="ri-search-line search-icon"></span>
    </div>
    <div class="d-flex" *ngIf="d2c_name!== 'shopify'">

        <div class="ms-2 d-flex">
            <input placeholder="Search Customer Name" value="" [(ngModel)]="searchTerm" (keyup.enter)="applyFilter($event.target.value)" id="input" class="form-control me-3">
            <button class="btn btn-danger w-md waves-effect waves-light me-3" type="submit" style="height: 40px;"
            (click)="cancel1()">Clear</button>
        </div>
    </div>
    <div class="d-flex" *ngIf="d2c_name === 'shopify'">
        <form [formGroup]="myForm">
            <select formControlName="order_count" class="form-select me-3" id="select">
                <option value="" disabled selected>Select Order Count</option>
                <option value="1">Greater Than or Equal To 1</option>
                <option value="2">Greater Than or Equal To 2</option>
                <option value="3">Greater Than or Equal To 3</option>
                <option value="4">Greater Than or Equal To 4</option>
                <option value="5">Greater Than or Equal To 5</option>
                <option value="6">Greater Than or Equal To 6</option>
                <option value="7">Greater Than or Equal To 7</option>
                <option value="8">Greater Than or Equal To 8</option>
                <option value="9">Greater Than or Equal To 9</option>
            </select>
        </form>
      
        <div class="d-flex">
            <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit" style="height: 40px;"
            (click)="search_shopify()">Submit</button>
            <button class="btn btn-danger w-md waves-effect waves-light me-3" type="submit" style="height: 40px;"
            (click)="cancel()">Clear</button>
        </div>
    </div>


    <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="d2c_name!== 'shopify'">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.repeated_count"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="count222($event)"></mat-paginator>
    </div>
    <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="d2c_name === 'shopify'">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="count22($event,'default')"></mat-paginator>
    </div>
</div>
<div class="justify-content-between mt-5 mobile">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup)="applyFilter1($event.target)">
        <span class="ri-search-line search-icon"></span>
    </div>
    <div class="d-flex" *ngIf="d2c_name!== 'shopify'">

        <div class="ms-2  mt-3  justify-content-center">
            <input placeholder="Search Customer Name" value="" [(ngModel)]="searchTerm" (keyup.enter)="applyFilter($event.target.value)" id="input" class="form-control me-3">
            <button class="btn btn-danger w-md waves-effect waves-light me-3" type="submit" style="height: 40px;"
            (click)="cancel1()">Clear</button>
        </div>
    </div>
    <div class="text-center mt-3" *ngIf="d2c_name === 'shopify'">
        <form [formGroup]="myForm">
            <select formControlName="order_count" class="form-select me-3" id="select">
                <option value="" disabled selected>Select Order Count</option>
                <option value="1">Greater Than or Equal To 1</option>
                <option value="2">Greater Than or Equal To 2</option>
                <option value="3">Greater Than or Equal To 3</option>
                <option value="4">Greater Than or Equal To 4</option>
                <option value="5">Greater Than or Equal To 5</option>
                <option value="6">Greater Than or Equal To 6</option>
                <option value="7">Greater Than or Equal To 7</option>
                <option value="8">Greater Than or Equal To 8</option>
                <option value="9">Greater Than or Equal To 9</option>
            </select>
        </form>
      
        <div class="d-flex justify-content-center mt-3">
            <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit" style="height: 40px;"
            (click)="search_shopify()">Submit</button>
            <button class="btn btn-danger w-md waves-effect waves-light me-3" type="submit" style="height: 40px;"
            (click)="cancel()">Clear</button>
        </div>
    </div>


    <div class="border text-whhite d-flex justify-content-center page-top mt-3" *ngIf="d2c_name!== 'shopify'">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.repeated_count"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="count222($event)"></mat-paginator>
    </div>
    <div class="border text-whhite d-flex justify-content-center page-top mt-3" *ngIf="d2c_name === 'shopify'">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="count22($event,'default')"></mat-paginator>
    </div>
</div>
<div class="row d-flex justify-content-center p-4">
    <div class="card-body" style="background-color:transparent;">
        <div class="material-datatables">
            <div class="position-relative overflow-auto">
                <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers" matSort>
                    <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i"
                        [stickyEnd]="tableheader == 'actions'">
                        <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold" mat-sort-header
                            style="white-space: nowrap;">
                            {{ headerData[tableheader] }}
                        </th>
                        <ng-container>
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

            </div>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="d2c_name!== 'shopify'">
            <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.repeated_count"
              [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="count222($event)"></mat-paginator>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="d2c_name === 'shopify'">
            <!-- <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" 
              (page)="count22($event)"></mat-paginator> -->
              <button [disabled]="prev_url === null" (click)="loadPreviousPage()">
                <i class="fas fa-chevron-left"></i>
            </button>
              
              <button  class="ms-3" [disabled]="next_url === null" (click)="loadNextPage()">
                <i class="fas fa-chevron-right"></i>
            </button>
              
        </div>
     
    </div>


</div>

<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>