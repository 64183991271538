import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, retry } from "rxjs";
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        
        getFirebaseBackend().logout();
    }
    signin(data:any){
        return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/signin?username=" +
        data.username+"&password="+data.password)
      }
      reset(data:any):Observable<any>{
        return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/otp",data)
      }
      changePassword(data:any):Observable<any>{
        return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/password",data)

      }
      signup(data:any){
        return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/signup",data)
      }
      portal_details(){
        let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
        return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/portal_details",{headers:headerToken,responseType:'text'})
      }
      user_details(){
        let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
        return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/userdata",{headers:headerToken,responseType:'text'})

      }
      logout1() {
        localStorage.clear()
    }
   

    update_profile(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/user_update",data
    ,{headers:headerToken,responseType:'text'})
  }
  
  order_details(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/order_filter?portal_id="+data.portal_id
    +"&portal_account_id="+data.portal_account_id+"&filter="+data.filter,{headers:headerToken,responseType:'text'})
  }
  order_details1(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/order_details?portal_id="+data.portal_id +"&portal_account_id="+data.portal_account_id  +"&days="+data.days,{headers:headerToken,responseType:'text'})

  }
  top_order(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/top_order?portal_account_id="+data.portal_account_id ,{headers:headerToken,responseType:'text'})
  }
  account_details(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/account_details?portal_account_id="+data.portal_account_id +"&d2c_name="+data.d2c_name,{headers:headerToken,responseType:'text'})
  }
  count(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/customer_count?portal_account_id="+data.portal_account_id +"&d2c_name="+data.d2c_name,{headers:headerToken,responseType:'text'})
  }
  selling_price(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/getting_selling_price?portal_id="+data.portal_id +"&portal_account_id="+data.portal_account_id,{headers:headerToken,responseType:'text'})



  }
  orders_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/orders_view",data,{headers:headerToken,responseType:'text'}).pipe(retry(1))

  }
  partial_orders_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/partial_orders_view?page="+data.page +"&portal_account_id="+data.portal_account_id +"&size="+data.size,{headers:headerToken,responseType:'text'})


  }
  partial_orders_view1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/partial_orders_view?page="+data.page +"&portal_account_id="+data.portal_account_id +"&size="+data.size +"&start_date="+data.start_date +"&end_date="+data.end_date,{headers:headerToken,responseType:'text'})


  }
  partial_orders_view2(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/partial_order_search?page="+data.page +"&portal_account_id="+data.portal_account_id +"&size="+data.size +"&search="+data.search ,{headers:headerToken,responseType:'text'})


  }
  dispute_search(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://011ia4xkhb.execute-api.ap-south-1.amazonaws.com/s3/weight_dispute_order_view?search_key="+data.search_key  +"&size="+data.size  +"&page="+data.page ,{headers:headerToken,responseType:'text'})


  }
  orders_search(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/orders_search",data,{headers:headerToken,responseType:'text'}).pipe(retry(1))

  }
  order_status(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/check_order_status",data,{headers:headerToken,responseType:'text'}).pipe(retry(1))

  }
  update_status(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/update_order_status",data,{headers:headerToken,responseType:'text'}).pipe(retry(1))



  }
  profile_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/portal_update",data,{headers:headerToken,responseType:'text'}).pipe(retry(1))
  }
  
  product_details(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/product_detail?portal_account_id="+data.portal_account_id +"&page_size="+data.page_size +"&page_number="+data.page_number, {headers:headerToken,responseType:'text'})

  }
  product_details1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/product_detail?portal_account_id="+data.portal_account_id +"&page_size="+data.page_size +"&page_number="+data.page_number +"&search="+data.search, {headers:headerToken,responseType:'text'})

  }
  portal_details_get():Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.get(" https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/get_portal_detail",{headers:headerToken,responseType:'text'})

  }
  portal_details_get1():Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/portal_details",{headers:headerToken,responseType:'text'})

  }

  ticket_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/ticket_view",data,{headers:headerToken,responseType:'text'})

  }
  ticket_create(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
  return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/ticket_create",data,{headers:headerToken,responseType:'text'})

  }
  ticket_view1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/ticket_view?portal_account_id="+data.portal_account_id +"&ticket="+data.ticket +"&page_number="+data.page_number +"&page_size="+data.page_size +"&status="+data.status, {headers:headerToken,responseType:'text'})


  }
  ticket_details(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/ticket_detail?ticket_id="+data.ticket_id, {headers:headerToken,responseType:'text'})


  }
   support_ticket_create(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/ticket_create",data,{headers:headerToken,responseType:'text'})

  }
  ticket_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/ticket_update",data,{headers:headerToken,responseType:'text'})

  }
  refund_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/refund_status_view",data,{headers:headerToken,responseType:'text'})

  }
  assigned_to(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("  https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/assign?portal_account_id="+data.portal_account_id, {headers:headerToken,responseType:'text'})


  }
  refund_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/refund_status_update",data, {headers:headerToken,responseType:'text'})

  }
  unread_tickets(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/unread_ticket?portal_account_id="+data.portal_account_id +"&portal_id="+data.portal_id, {headers:headerToken,responseType:'text'})

  }
  unread(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/unread_ticket",data, {headers:headerToken,responseType:'text'})

  }
  send_details(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/send_tracking_detail",data, {headers:headerToken,responseType:'text'})

  }
  order_reports(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/order_report",data, {headers:headerToken,responseType:'text'})

  }
  product_reports(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post(" https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/product_reports",data, {headers:headerToken,responseType:'text'})
  }
  repeated(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/customer_details?portal_account_id="+data.portal_account_id +"&d2c_name="+data.d2c_name +"&page_size="+data.page_size +"&url="+data.url, {headers:headerToken,responseType:'text'})

  }
  repeated2(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/customer_details?portal_account_id="+data.portal_account_id +"&d2c_name="+data.d2c_name +"&page_size="+data.page_size +"&url="+data.url +"&order_count="+data.order_count , {headers:headerToken,responseType:'text'})

  }
  repeated1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/repeated_customer?portal_account_id="+data.portal_account_id +"&portal_id="+data.portal_id +"&page_number="+data.page_number+"&page_size="+data.page_size, {headers:headerToken,responseType:'text'})

  }
  repeated3(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/search_repeated_customer?portal_account_id="+data.portal_account_id +"&portal_id="+data.portal_id +"&page_number="+data.page_number+"&page_size="+data.page_size +"&name="+data.name, {headers:headerToken,responseType:'text'})
  }
  resend_invoice(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post(" https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/resend_invoice", data, {headers:headerToken,responseType:'text'})
  }
  target_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/target_view?month="+data.month,  {headers:headerToken,responseType:'text'})

  }
  refund_count_ticket(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')};  
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/count_of_open_status?get_data="+data.get_data,  {headers:headerToken,responseType:'text'})

  }

  scanner_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://owaucfctpc.execute-api.ap-south-1.amazonaws.com/qr/scanner_details?page_number="+data.page_number +"&page_size="+data.page_size ,{headers:headerToken,responseType:'text'})
  }
  product_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/division_brand_update", data, {headers:headerToken,responseType:'text'})


  }
  refund_payment(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/refund", data, {headers:headerToken,responseType:'text'})


  }
  refund_download(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/refund_report", data, {headers:headerToken,responseType:'text'})


  }
  refund_get(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/refund_details?order_id="+data.order_id  +"&portal="+data.portal ,{headers:headerToken,responseType:'text'})
  }

  // refund_search(data:any):Observable<any>{
  //   let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
  //   return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/refund_status_view_search?search="+data.search +"&portal_account_id="+data.portal_account_id +"&page="+data.page +"&size="+data.size,{headers:headerToken,responseType:'text'})

  // }
  refund_search(data: any): Observable<any> {
    const headerToken = { 'Authorization': `Bearer ` + localStorage.getItem('token') };
      const searchParam = JSON.stringify(data.search);
      const url = `https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/refund_status_view_search?search=${encodeURIComponent(searchParam)}&portal_account_id=${data.portal_account_id}&page=${data.page}&size=${data.size}`;
  
    return this.http.get(url, { headers: headerToken, responseType: 'text' });
  }
  
  orders_search2(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/order_search?search="+data.search  +"&portal_account_id="+data.portal_account_id  +"&page="+data.page  +"&size="+data.size,{headers:headerToken,responseType:'text'})
 
  } 

  permission_get(data:any,token:any):Observable<any>{
    console.log(token,'testing the token')
    var headerToken1111={'Authorization':`Bearer `+ token}; 
    console.log(data,'testing the token')
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/permission?portal_account_id="+data.portal_account_id +"&page_number="+data.page_number  +"&page_size="+'10' ,{headers:data.token,responseType:'text'})

  }


  permission_get111(data:any,token:any):Observable<any>{
    console.log(token,'testing the token')
    var headerToken1111={'Authorization':`Bearer `+ token}; 
    console.log(data,'testing the token')
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/permission?portal_account_id="+data.portal_account_id +"&page_number="+data.page_number  +"&page_size="+'10' ,{headers:headerToken1111,responseType:'text'})

  }

  permission_create(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/permission", data, {headers:headerToken,responseType:'text'})
  }
  template_get(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/template_view?portal_account_id="+data.portal_account_id   +"&type="+data.type  +"&page_number="+data.page_number  +"&page_size="+data.page_size ,{headers:headerToken,responseType:'text'})

  }
  permission_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/permission", data, {headers:headerToken,responseType:'text'})
  }
  raised_request(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/raised_request", data, {headers:headerToken,responseType:'text'})

  }
  template_message(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/template_message_update", data, {headers:headerToken,responseType:'text'})

  }
  bulk_upload(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/marketing", data, {headers:headerToken,responseType:'text'})
  }

  existing(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/existing_customer_marketing", data, {headers:headerToken,responseType:'text'})
  }
  template_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/template_view", data, {headers:headerToken,responseType:'text'})
  }
  custom_messages(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/custom_messages", data, {headers:headerToken,responseType:'text'})
  }
  custom_message_details(data:any){
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/custom_messages?portal_account_id=" +data.portal_account_id, {headers:headerToken,responseType:'text'})
  }
  transaction_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/transaction_view?portal_account_id=" +data.portal_account_id +"&filter="+data.filter, {headers:headerToken,responseType:'text'})
  }
  transaction_view1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/transaction_view?portal_account_id=" +data.portal_account_id +"&start_date="+data.start_date +"&end_date="+data.end_date, {headers:headerToken,responseType:'text'})
  }
  users_list(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/user_list?portal_account_id=" +data.portal_account_id +"&page_number="+data.page_number +"&page_size="+data.page_size, {headers:headerToken,responseType:'text'})

  }
  product_get_order(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/product_details", data, {headers:headerToken,responseType:'text'})

  }
  create_order(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/order_create", data, {headers:headerToken,responseType:'text'})


  }
  bulk_status_details(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/bulk_status_details?days=" +data.days , {headers:headerToken,responseType:'text'})


  }
  bulk_status_details1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/bulk_status_details?start_date=" +data.start_date  +"&end_date="+data.end_date , {headers:headerToken,responseType:'text'})


  }
  portalwise_status_details(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/portalwise_bulk_status_details?start_date=" +data.start_date  +"&end_date="+data.end_date  , {headers:headerToken,responseType:'text'})
  }
  portalwise_status_details1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/portalwise_bulk_status_details?days=" +data.days   , {headers:headerToken,responseType:'text'})
  }

  users_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/user_update" ,data, {headers:headerToken,responseType:'text'})

  }
  template_delete(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.delete("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/template_view?template_id=" +data.template_id  , {headers:headerToken,responseType:'text'})


  }

  update_order(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/update_order" ,data, {headers:headerToken,responseType:'text'})
  }

  partial_order(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/partial_refund" ,data, {headers:headerToken,responseType:'text'})
  }

  dispute_order_create(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://011ia4xkhb.execute-api.ap-south-1.amazonaws.com/s3/create_dispute" ,data, {headers:headerToken,responseType:'text'})
  }

  dispute_order_create1(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://011ia4xkhb.execute-api.ap-south-1.amazonaws.com/s3/bulk_dispute_create" ,data, {headers:headerToken,responseType:'text'})
  }
  update_dispute(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.patch("https://011ia4xkhb.execute-api.ap-south-1.amazonaws.com/s3/update_dispute" ,data, {headers:headerToken,responseType:'text'})

  }
  close_dispute(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://011ia4xkhb.execute-api.ap-south-1.amazonaws.com/s3/dispute_close?order_id=" +data.order_id , {headers:headerToken,responseType:'text'})


  }
  bulk_update(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://011ia4xkhb.execute-api.ap-south-1.amazonaws.com/s3/bulk_update_dispute" ,data, {headers:headerToken,responseType:'text'})


  }

  dispute_get(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://011ia4xkhb.execute-api.ap-south-1.amazonaws.com/s3/weight_dispute_order_view?page=" +data.page +"&size="+data.size , {headers:headerToken,responseType:'text'})


  }

  get_alt_orders(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/alternate_orders_view?page=" +data.page +"&size="+data.size , {headers:headerToken,responseType:'text'})
  }

   get_alt_orders_search(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/alternate_orders_view?page=" +data.page +"&size="+data.size  +"&search_key="+data.search_key, {headers:headerToken,responseType:'text'})
  }


  mail_sharing(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://k287etqa0a.execute-api.ap-south-1.amazonaws.com/pandl_mail/post_pandl_mail_sharing?portal=" +data.portal, {headers:headerToken,responseType:'text'})

  }

  get_clone_orders(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.get("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/get_clone_order_details?order_id=" +data.order_id , {headers:headerToken,responseType:'text'})

  }

  create_clone_orders(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/create_alternate_order" ,data, {headers:headerToken,responseType:'text'})

  }



}





