import { Component,ElementRef,HostListener,ViewChild,TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment'; 


// import { ChartType } from '../chart/chartjs/chartjs.model';
interface ChartType {
  chart?: any;
  plotOptions?: any;
  colors?: any;
  series?: any;
  stroke?: any;
  fill?: any;
  labels?: any;
  markers?: any;
  legend?: any;
  xaxis?: any;
  yaxis?: any;
  tooltip?: any;
  grid?: any;
  datasets?: any;
  options?: any;
  toolbar?: any;
  type?: any;
  height?: any;
  dataLabels?: any;
  sparkline?: any;
  responsive?: any;
  states?: any;
  title?: any;
  subtitle?: any;
}



@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent {

  superuser:any;
  portal_name:any;
  portal_id:any;
  portal_account_id:any;
  d2c_name:any;
  showOverlay:boolean = false
  pageIndex:number = 0;
pageSize:any;
pageSizeOptions: number[] = [10, 20, 50, 100]; 
page:any;     
length:any;
page_no:number=1;
config:any;
paginate: any = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: 1
  }
pageEvent:any;
startIndex:any;
apiData:any
splineAreaChart: ChartType
splineAreaCharts: any[] = [];
selectedOption:any
fullData:any
amount:any;
amount1:any;
start_date = new FormControl();
end_date = new FormControl();






  constructor(
    private authService:AuthenticationService,
    private modalServicee: NgbModal,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,

  ){

  }

  ngOnInit(): void {
    this.fetchData(this.paginate,'weekly')
    this.selectedOption = 'weekly'
    this.fetchData1(this.paginate)
  }


  fetchData(event:PageEvent,data1:any) {
    const daysOfWeek = [];
    const deliveryDetailsData = [];
    const sentCustomData = [];
    const sentCustomerData = [];
    this.superuser = localStorage.getItem('is_superuser')
  
    if(this.superuser == 'true'){
            this.portal_name = localStorage.getItem('portal_name2')
            this.portal_id = localStorage.getItem('portal_id2')
            this.portal_account_id = localStorage.getItem('portal_account_id2')
            this.d2c_name = localStorage.getItem('d2c_name2')
      
          }
          else{
            this.portal_name = localStorage.getItem('portal_name')
             this.portal_id = localStorage.getItem('portal_id')
            this.portal_account_id = localStorage.getItem('portal_account_id')
            this.d2c_name = localStorage.getItem('d2c_name')
          }
       this.showOverlay=true 
   this.paginate = event;
   this.length = +event.length;
   this.pageIndex = +event.pageIndex;
   this.pageSize = +event.pageSize;
   this.startIndex = +event.pageIndex * event.pageSize
   let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
  
    let payload_data = {
      'portal_account_id': this.portal_account_id,
      'filter':data1
   
    };
    this.splineAreaCharts = []
    this.authService.transaction_view(payload_data).subscribe((res) => {
      let mes=JSON.parse(res)
      res =mes 
      if (res.statusCode>=200 && res.statusCode<=205) {
        // this.showOverlay = false;
        this.apiData = res.body
        this.processData()
      } 
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          // this.authService.logout()
          this.showOverlay=false
          this.router.navigate(['/account/login'])
        }
        else{
          this.toastr.error(res.error, "");
          this.showOverlay = false; 
          // console.log('error mesg',res)
        }
      }
    }, (error) => {
      this.showOverlay = false;
      if (error.status === 502) {
        this.toastr.error("502 Bad Gateway", "An error occurred");
      } else {
        this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
      }
    });
  }


//   splineAreaChart: ChartType = {
//     chart: {
//         height: 350,
//         type: 'area',
//     },
//     dataLabels: {
//         enabled: false
//     },
//     stroke: {
//         curve: 'smooth',
//         width: 3,
//     },
//     series: [{
//         name: 'series1',
//         data: [34, 40, 28, 52, 42, 109, 100]
//     }, {
//         name: 'series2',
//         data: [32, 60, 34, 46, 34, 52, 41]
//     }],
//     colors: ['#556ee6', '#34c38f'],
//     xaxis: {
//         type: 'datetime',
//         // tslint:disable-next-line: max-line-length
//         categories: ['2018-09-19T00:00:00', '2018-09-19T01:30:00', '2018-09-19T02:30:00', '2018-09-19T03:30:00', '2018-09-19T04:30:00', '2018-09-19T05:30:00', '2018-09-19T06:30:00'],
//     },
//     grid: {
//         borderColor: '#f1f1f1',
//     },
//     tooltip: {
//         x: {
//             format: 'dd/MM/yy HH:mm'
//         },
//     }
// };

processData() {
  const categories = this.apiData.map(day => Object.keys(day)[0]);

  const sectionData = {};

  this.apiData.forEach(day => {
    const dayName = Object.keys(day)[0];
    const sections = day[dayName];

    if (sections) {
      Object.keys(sections).forEach(section => {
        if (!sectionData[section]) {
          sectionData[section] = {};
        }

        const subsections = sections[section];
        Object.keys(subsections).forEach(subsection => {
          if (!sectionData[section][subsection]) {
            sectionData[section][subsection] = Array(categories.length).fill(0);
          }

          const dayIndex = categories.indexOf(dayName);
          sectionData[section][subsection][dayIndex] = subsections[subsection];
        });
      });
    }
  });

  Object.keys(sectionData).forEach(section => {
    const series = Object.keys(sectionData[section]).map(subsection => ({
      name: subsection,
      data: sectionData[section][subsection]
    }));

    const chart = {
      chart: {
        height: 350,
        type: 'area',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      series: series,
      colors: ['#556ee6', '#34c38f', '#f46a6a', '#50a5f1', '#34c38f'],
      xaxis: {
        categories: categories
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      }
    };

    this.splineAreaCharts.push({ section, chart });
  });
  this.showOverlay = false
}

onSelectChange(event: any) {
  const selectedValue = event.target.value;
  console.log(selectedValue)
  this.fetchData(this.paginate,selectedValue)
}


fetchData1(event:PageEvent) {
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
 this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

  let payload_data = {
    'portal_account_id':this.portal_account_id,
   "page_number":page_no,
   "page_size":this.pageSize,
  };
  this.authService.permission_get111(payload_data,localStorage.getItem('token')).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.length=mes.total_length
        this.fullData = res.body[0]; 
        this.amount = this.fullData.credit_amount
        this.amount1 = this.fullData.used_credit_amount
        console.log(this.fullData,'fullData')
    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/account/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}


onStartDateSelected(event: MatDatepickerInputEvent<Date>) {
  const startDate = event.value;
  const maxEndDate = this.calculateMaxEndDate(startDate);
  this.end_date.setValue(null); // Reset the end date when start date changes
  this.end_date.enable(); // Enable the end date input
  // this.end_date.setValidators([
  //   Validators.required,
  //   this.endDateValidator.bind(this, startDate, maxEndDate)
  // ]);
}

private calculateMaxEndDate(startDate: Date): Date {
  const maxEndDate = new Date(startDate);
  maxEndDate.setDate(maxEndDate.getDate() + 30);
  return maxEndDate;
}

private endDateValidator(startDate: Date, maxEndDate: Date, control: FormControl): { [key: string]: any } | null {
  const endDate = control.value;
  // if (endDate && endDate > maxEndDate) {
  //   return { maxEndDateExceeded: true };
  // }
  return null;
}

fetchData2() {
  const daysOfWeek = [];
  const deliveryDetailsData = [];
  const sentCustomData = [];
  const sentCustomerData = [];
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
//  this.paginate = event;
//  this.length = +event.length;
//  this.pageIndex = +event.pageIndex;
//  this.pageSize = +event.pageSize;
//  this.startIndex = +event.pageIndex * event.pageSize
//  let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1
console.log(this.end_date.value)
const startdate1 = moment(this.start_date.value).format('YYYY-MM-DD')
const  enddate1 = moment(this.end_date.value).format('YYYY-MM-DD')

  let payload_data = {
    'portal_account_id': this.portal_account_id,
     'start_date':startdate1,
     'end_date':enddate1


 
  };
  this.splineAreaCharts = []
  this.authService.transaction_view1(payload_data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    if (res.statusCode>=200 && res.statusCode<=205) {
      // this.showOverlay = false;
      this.apiData = res.body
      this.processData()
    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/account/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}



}
