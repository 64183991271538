import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, retry } from "rxjs";
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) {
  }

  orders_view(data:any):Observable<any>{
    let headerToken={'Authorization':`Bearer `+ localStorage.getItem('token')}; 
    return this.http.post("https://t7rek0xkmf.execute-api.ap-south-1.amazonaws.com/s3/orders_view",data,{headers:headerToken,responseType:'text'})

  }

}
