import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ChatComponent } from './chat/chat.component';
import { KanbanComponent } from './kanban/kanban.component';
import { ProfileComponent } from './profile/profile.component';
import { CreateuserComponent } from './createuser/createuser.component';
import { EditComponent } from './edit/edit.component';
import { OrdersComponent } from './orders/orders.component';
import { DetailsComponent } from './details/details.component';
import { OpenComponent } from './open/open.component';
import { SuperuserComponent } from './superuser/superuser.component';
import { TicketsComponent } from './tickets/tickets.component';
import { CancelComponent } from './cancel/cancel.component';
import { UpdateComponent } from './update/update.component';
import { SupportComponent } from './support/support.component';
import { TicketviewComponent } from './ticketview/ticketview.component';
import { SupportticketComponent } from './supportticket/supportticket.component';
import { RefundComponent } from './refund/refund.component';
import { RepeatedComponent } from './repeated/repeated.component';
import { ScannerComponent } from './scanner/scanner.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { UsersListComponent } from './users-list/users-list.component';
import { PartialOrdersComponent } from './partial-orders/partial-orders.component';

const routes: Routes = [
    // { path: '', component: DashboardComponent },


    {
        path:'retailripple',
        children:[
            { path: '', component: DashboardComponent },
            {
                path: 'refund',
                component: RefundComponent
            },
            {
                path: 'profile',
                component:ProfileComponent
            },
            {
                path: 'createuser',
                component:CreateuserComponent
            },
            {
                path: 'edit',
                component:EditComponent
            },
            {
                path: 'order',
                component:OrdersComponent
            },
            {
                path: 'details',
                component:DetailsComponent
            },
            {
                path: 'open',
                component:OpenComponent
            }, 
            {
                path: 'superuser',
                component:SuperuserComponent
            },
            {
                path: 'ticket',
                component:TicketsComponent
            },
            {
                path: 'cancel',
                component:CancelComponent
            },
         
            {
                path: 'support',
                component: SupportComponent
            },
            {
                path: 'ticketview',
                component: TicketviewComponent
            },
            {
                path: 'supportticket',
                component: SupportticketComponent
            },
            {
                path: 'repeated',
                component: RepeatedComponent
            },
            {
                path: 'scanner',
                component: ScannerComponent
            },
            {
                path: 'permissions',
                component: PermissionsComponent
            },
            {
                path: 'users-list',
                component: UsersListComponent
            },
            {
                path: 'partial-orders',
                component: PartialOrdersComponent
            },
            { path: 'whatsapp', loadChildren: () => import('./whatsapp/whatsapp.module').then(m => m.WhatsappModule) },

        ]
    },
    {
        path: 'update',
        component: UpdateComponent
    },
    
    { path: 'bvfe', component: DashboardComponent },
    { path: 'calendar', component: CalendarComponent },
    { path: 'chat', component: ChatComponent },
    { path: 'kanban-board', component: KanbanComponent },
    { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
    { path: 'email', loadChildren: () => import('./email/email.module').then(m => m.EmailModule) },
    { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
    { path: 'charts', loadChildren: () => import('./chart/chart.module').then(m => m.ChartModule) },
    { path: 'form', loadChildren: () => import('./form/form.module').then(m => m.FormModule) },
    { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
    { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
    // {
    //     path: 'profile',
    //     component:ProfileComponent
    // },
    // {
    //     path: 'createuser',
    //     component:CreateuserComponent
    // },
    // {
    //     path: 'edit',
    //     component:EditComponent
    // },
    // {
    //     path: 'order',
    //     component:OrdersComponent
    // },
    // {
    //     path: 'details',
    //     component:DetailsComponent
    // },
    // {
    //     path: 'open',
    //     component:OpenComponent
    // }, 
    // {
    //     path: 'superuser',
    //     component:SuperuserComponent
    // },
    // {
    //     path: 'ticket',
    //     component:TicketsComponent
    // },
    // {
    //     path: 'cancel',
    //     component:CancelComponent
    // },
    // {
    //     path: 'update',
    //     component: UpdateComponent
    // },
    // {
    //     path: 'support',
    //     component: SupportComponent
    // },
    // {
    //     path: 'ticketview',
    //     component: TicketviewComponent
    // },
    // {
    //     path: 'supportticket',
    //     component: SupportticketComponent
    // },
    // {
    //     path: 'refund',
    //     component: RefundComponent
    // },




];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
