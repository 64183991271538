import { Component,ViewChild,inject,TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/core/services/shared.service';


@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent {
  
  showOverlay=false
  // pageSizeOptions: number[] = [10, 20, 50, 100]; 
  headerData: any;
  ogData:any;
  customers: any;
  // pageEvent:any;
  total_product:any
  superuser:any;
  get_supper_user:any;
  d2c_name:any;
  portal_id:any;
  portal_account_id:any;
  searchdata:any;  
  portal_name:any;
  isCancelled: boolean = false;
  isOptionSelected: boolean = false
  startIndex:any
  remarksEdited: boolean = false;
  createuserform1: UntypedFormGroup;
  createuserform2: UntypedFormGroup;
  createuserform8: UntypedFormGroup;


  private modalService = inject(NgbModal);
  loading:boolean = false



  fullData: any;
  apiData: any = [];
  pageIndex:number = 0;
pageSize:any;

pageSizeOptions: number[] = [10, 20, 50, 100]; 
page:any;     
length:any;
page_no:number=1;
config:any;
paginate: any = {
  length: 0,
  pageIndex: 0,
  pageSize: 10,
  previousPageIndex: 1
  }
pageEvent:any;
  apiData1:any = [];
  img_path:any;
  data:any;
  searchchar:boolean = false

    displayedColumns: string[] | undefined;
    value:any


    @ViewChild(MatSort, { static: false }) sort: MatSort | undefined;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined; 




  constructor(
    private authService:AuthenticationService,
    private modalServicee: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _shared:SharedService

    ){

  }

  ngOnInit(): void {
    this.fetchData(this.paginate);
    this.superuser = localStorage.getItem('superuser')
    this.get_supper_user=localStorage.getItem('is_superuser')
    // console.log(this.get_supper_user);

    this.superuser = localStorage.getItem('is_superuser')
    if(this.superuser == 'true'){
      this.portal_name = localStorage.getItem('portal_name2')
      this.portal_id = localStorage.getItem('portal_id2')
      this.portal_account_id = localStorage.getItem('portal_account_id2')
      this.d2c_name = localStorage.getItem('d2c_name2')
  
    }
    else{
      this.portal_name = localStorage.getItem('portal_name')
       this.portal_id = localStorage.getItem('portal_id')
      this.portal_account_id = localStorage.getItem('portal_account_id')
      this.d2c_name = localStorage.getItem('d2c_name')
    }
    
    
    // console.log(this.length,'lengthhhhhhhh')

    this.createuserform1 = this.formBuilder.group({
      status: ['', [Validators.required]],
      // disposition: ['pending', [Validators.required]],
      remarks:[''],
      ifsc:[''],
      account_id:[''],
      reference_path:['']

  
    });
    this.createuserform2 = this.formBuilder.group({
      reason:[''],
      refund_amount:['', [Validators.required]],

  
    });

    
    this.createuserform8 = this.formBuilder.group({
      order_id: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });

  }
  


fetchData(event:PageEvent) {
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
 this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
//  console.log(this.pageSize,'this.pagesize')
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

  let payload_data = {
   "page":page_no,
   "size":this.pageSize,
   'portal_account_id':this.portal_account_id
  };
  this.authService.refund_view(payload_data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.length=mes.total_length
        this.fullData = res.Data; 
        console.log('this.fullData.ifsc',this.fullData.ifsc)
        this.ogData =res.Data
      this.headerData = res.headers;
      this.getData(this.fullData); 
    //   console.log("mesg", res.message); 
    // console.log(this.length,'lengthhhhhhhh')

    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}

getData(data:any) {
  this.apiData = data;
  this.headerData.actions = "Action"; 
  this.displayedColumns = Object.keys(this.headerData);
  this.customers = new MatTableDataSource(this.apiData);
  this.customers.sort = this.sort;
  // this.customers.paginator = this.paginator
  
}
applyFilter1(filterValue: any) {
  if(filterValue!=''){
    this.searchchar = true
    this.value = filterValue
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData1(this.paginate,filterValue)
  }
  else{
    this.searchchar = false
    this.paginate = {
      length: 0,
      pageIndex: 0,
      pageSize: 10,
      previousPageIndex: 1
      }
    this.fetchData(this.paginate)
  }
  // this.customers.filter = filterValue.value.trim().toLowerCase();
  // if (this.customers.paginator) {
  //   this.customers.paginator.firstPage();
  // }
}
// open1(content: any,row:any): void {
//   const modalRef = this.modalService.open(content, { centered: true, size: 'md' });
//   modalRef.componentInstance.form = this.createuserform1;

//   modalRef.result.then((result) => {
//     if (result === 'Yes click') {
//       const updatedTicketId = row.ticket_id ;
//       const newStatus = this.createuserform1.value.status;

//       this.updateTableData(updatedTicketId, newStatus);
//     }
//   });
// }
// updateTableData(updatedTicketId: number, newStatus: string): void {
//   const rowIndex = this.fullData.findIndex((row) => row.ticket_id === updatedTicketId);

//   if (rowIndex !== -1) {
//     this.fullData[rowIndex].status = newStatus;
//   }
// }


// open(content: TemplateRef<any>) {
//   this.modalService.open(content, { centered: true,size: 's'});

// }
payment_method11:any
order_id11 :any

rowData:any;
rowData1:any;
rowData2:any;
originalRemarksValue:any
datafrom:any
order_status:any
open1(content1: TemplateRef<any> ,row:any) {
  this.activeButton = 'update';
  this.modalService.dismissAll('Close click');
  this.datafrom = row
  this.order_id11 = row.order_id
  this.order_status = row.status
  this.payment_method11 = row.payment_method
  localStorage.setItem('ref_id',row.ref_id)
  localStorage.setItem('id',row.order_id)
  this.modalService.open(content1, { centered: true,size: 's'});

 
  this.rowData1 = row.ifsc
  this.rowData2 = row.account_id

  this.rowData = row
  this.remarksEdited = false; 
  this.originalRemarksValue = row.remarks;



  this.createuserform1.patchValue({
    status: this.rowData.status,
    account_id: this.rowData.account_id,
    ifsc: this.rowData.ifsc,
  });

}
open3(content1: TemplateRef<any>) {
  this.modalService.open(content1, { centered: true,size: 's'});
}

open2(content1: TemplateRef<any>) {

  this.activeButton = 'refund';
  this.createuserform2.reset()
  this.modalService.dismissAll('Close click');
  this.refund_get()
  this.modalService.open(content1, { centered: true,size: 'lg'});
  
  // localStorage.setItem('ref_id',row.ref_id)
  // localStorage.setItem('id',row.order_id)
  // this.rowData1 = row.ifsc
  // this.rowData2 = row.account_id

  // this.rowData = row
  // this.remarksEdited = false; 
  // this.originalRemarksValue = row.remarks;



  // this.createuserform1.patchValue({
  //   status: this.rowData.status,
  //   account_id: this.rowData.account_id,
  //   ifsc: this.rowData.ifsc,
  // });

}

async s3Upload(file:any ){ 
    
  let bucketName = 'd2c-crm'
  let FolderName = `referance_path/${localStorage.getItem('id')}`
  try {
    let data = await this._shared.UploadFileToS3(bucketName,FolderName,file);
    // console.log(data,'data2')
    this.showOverlay=false 
    this.img_path=data 
    console.log(data,'data1')
    this.createuserform1.get('reference_path').setValue(this.img_path);
    return data;
  } catch (error) {
    // console.error(error);
    this.showOverlay=false 
  }
}
update_refund(){
  this.loading = true
//   const updatedRemarksValue = this.createuserform1.value.remarks;

// if (updatedRemarksValue !== null && updatedRemarksValue !== undefined && this.originalRemarksValue !== updatedRemarksValue && updatedRemarksValue.trim() !== '') {
//   this.rowData.remarks.push(updatedRemarksValue);
//   this.remarksEdited = true;
// }
const updatedRemarksValue = this.createuserform1.value.remarks;

if (updatedRemarksValue !== null && updatedRemarksValue !== undefined && updatedRemarksValue.trim() !== '') {
  // Check if this.rowData.remarks is falsy, and initialize it as an empty array if needed
  if (!this.rowData.remarks) {
    this.rowData.remarks = [];
  }

  this.rowData.remarks.push(updatedRemarksValue);
  this.remarksEdited = true;
}


// console.log(this.rowData.remarks);


  // const editedRemarksValue = this.createuserform1.value.remarks;
  // const updatedRemarksValue = this.createuserform1.value.remarks;
  // if (this.originalRemarksValue !== updatedRemarksValue && updatedRemarksValue.trim() !== '') {
  //   this.rowData.remarks.push(updatedRemarksValue);
  //   this.remarksEdited = true;
  // }
  // console.log(this.rowData.remarks)

  // if (this.remarksEdited) {
  //   const combinedRemarks = [{ text: this.originalRemarksValue }, { text: editedRemarksValue }];
  //   console.log('Combined Remarks:', combinedRemarks);
  // } else {
  //   const originalRemarksOnly = [{ text: this.originalRemarksValue }];
  //   console.log('Original Remarks Only:', originalRemarksOnly);
  // }
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  if(this.superuser == 'true'){
    this.data = {
      'ref_id':   localStorage.getItem('ref_id') ,
      'status':this.createuserform1.value.status,
      'account_id':this.createuserform1.value.account_id,
      'ifsc':this.createuserform1.value.ifsc,
      'remarks':this.rowData.remarks,
      'reference_path':this.img_path

    }
  }
  else{
    this.data = {
      'ref_id':   localStorage.getItem('ref_id') ,
      'account_id':this.createuserform1.value.account_id,
      'ifsc':this.createuserform1.value.ifsc,
      'remarks':this.rowData.remarks,
      'status':this.createuserform1.value.status,


    }
  }
 
  this.authService.refund_update(this.data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.toastr.success(res.success, "");
      this.fetchData(this.paginate);
      this.createuserform1.reset()
      this.modalService.dismissAll('Close click');
      this.loading = false


     


    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
        this.loading = false
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        this.createuserform1.reset()
        this.toastr.error(res.error, "");
        this.modalService.dismissAll('Close click');
        this.loading = false



        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    this.loading = false
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}
saveRemarks() {
  const updatedRemarksValue = this.createuserform1.value.remarks;
  if (this.originalRemarksValue !== updatedRemarksValue) {
    this.rowData.remarks.push(updatedRemarksValue);
    this.remarksEdited = true;
  }
  // console.log(this.rowData.remarks)
}

onRemarksChange() {
  // this.remarksEdited = true;
  this.remarksEdited = this.createuserform1.value.remarks !== this.originalRemarksValue;
  if (this.remarksEdited) {
    const editedRemarksValue = this.createuserform1.value.remarks;
    // console.log('Edited Remarks:', editedRemarksValue);
  } else {
    // console.log('Remarks not edited');
  }
}

alphanumericOnly1(event: KeyboardEvent): void {
  const inputElement = event.target as HTMLInputElement;
  const currentValue = inputElement.value;

  const allowedCharsPattern = /^[a-zA-Z0-9]+$/;
  const isAllowedChar = allowedCharsPattern.test(event.key);
  if (!isAllowedChar) {
    event.preventDefault();
    return;
  }
  const newValue = currentValue + event.key.toUpperCase(); // Convert to uppercase and add to the current value
  inputElement.value = newValue; // Update the input field value
  inputElement.dispatchEvent(new Event('input')); // Trigger an 'input' event to update Angular model
  event.preventDefault(); // Prevent the default keypress behavior
}
numericOnly(event:any): boolean {    
  let patt = /^[0-9]+$/;
  let result = patt.test(event.key);
  return result;
}
public GetFileOnLoad1(event: any) {
  var file = event.target.files[0];
  var element = document.getElementById("fakeFileInput1") as HTMLInputElement | null;
  if (element != null) {
    // element.value = file?.name;
    this.s3Upload(file);
  }
}



downloadInvoice1(row:any){
  console.log(row,'rowwww')
  console.log(row.reference_path,'reference_path')
  this.downloads3_files(row.reference_path,"reference_path.pdf")
}
async downloads3_files(file_path:any,file_name:any){
let bucketName = 'd2c-crm'
console.log('file_path',file_path)
try {
  const fileBlob: Blob = await this._shared.downloadFileFromS3(bucketName,file_path);
  // Create a temporary URL for the blob and trigger a download
  const url = window.URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.href = url;
  link.download = file_name; 
  link.click();
  window.URL.revokeObjectURL(url);
} catch (error) {
  this.toastr.error("No File Found", "");
  // this.toastService.show('File Path is null', { classname: 'bg-danger text-center text-white', delay: 2000 });
}
}


ticket_create(){
  this.loading = true
  this.showOverlay = true
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  // console.log(this.d2c_name)
  let data = {
    'portal':this.d2c_name,
    'portal_account_id': parseInt(this.portal_account_id),
    'order_id': localStorage.getItem('id'),
    'refund_amount': this.createuserform2.value.refund_amount
  }
  if (this.d2c_name === 'woocommerce'){
      data['reason'] = this.createuserform2.value.reason

  }

  this.authService.refund_payment(data).subscribe((res: any) => {
    // console.log(res,'ticket data');
    let mes = JSON.parse(res);
    // console.log(mes);
    if (mes.statusCode === 200) {
      this.showOverlay=false
      this.loading = false
      this.createuserform2.reset()
      this.toastr.success(mes.success, "");
      this.fetchData(this.paginate);
      this.modalService.dismissAll('Close click');

      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
    this.loading = false
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');
    this.loading = false

  }


}
},
(error) => {
  this.showOverlay = false;
  this.loading = false
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}


fullData11:any
data11:any


refund_get(){
  this.showOverlay = true
  this.superuser = localStorage.getItem('is_superuser')
  if(this.superuser == 'true'){
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else{
    this.portal_name = localStorage.getItem('portal_name')
     this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  
  let orderId = localStorage.getItem('id');
  orderId = orderId ? orderId.replace(/#/g, '') : '';
  
  this.data11 = {
    'order_id': orderId,
    'portal':this.d2c_name
  }
  console.log(this.data11,'dataa')
   this.authService.refund_get(this.data11).subscribe((res: any) => {
    let mes = JSON.parse(res);
    if (mes.statusCode === 200) {
      this.fullData11 = mes.body[0]
      this.payment_method11 = this.fullData11.payment_method
      console.log(this.fullData11)
      this.showOverlay=false

      // this.toastr.success(mes.success, "");
      // this.modalService.dismissAll('Close click');

      

}
else{
  if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
    // this.authService.logout()
    this.showOverlay=false
    this.router.navigate(['/auth/login'])
  }
  else{
    this.toastr.error(mes.error, "");
    this.showOverlay = false; 
    this.modalService.dismissAll('Close click');

  }


}
},
(error) => {
  this.showOverlay = false;
  if (error.status === 502) {
    this.toastr.error("502 Bad Gateway", "An error occurred");
  } else {
    this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
  }
});

}

activeButton: string = 'update';


setActiveButton(button: string) {
  this.activeButton = button;
}


fetchData1(event:PageEvent,data1:any) {
  this.superuser = localStorage.getItem('is_superuser')

  if(this.superuser == 'true'){
          this.portal_name = localStorage.getItem('portal_name2')
          this.portal_id = localStorage.getItem('portal_id2')
          this.portal_account_id = localStorage.getItem('portal_account_id2')
          this.d2c_name = localStorage.getItem('d2c_name2')
    
        }
        else{
          this.portal_name = localStorage.getItem('portal_name')
           this.portal_id = localStorage.getItem('portal_id')
          this.portal_account_id = localStorage.getItem('portal_account_id')
          this.d2c_name = localStorage.getItem('d2c_name')
        }
     this.showOverlay=true 
 this.paginate = event;
 this.length = +event.length;
 this.pageIndex = +event.pageIndex;
 this.pageSize = +event.pageSize;
//  console.log(this.pageSize,'this.pagesize')
 this.startIndex = +event.pageIndex * event.pageSize
 let page_no = this.pageIndex > 0 ? this.pageIndex + 1 : 1

  let payload_data = {
   "page":page_no,
   "size":this.pageSize,
   "search":data1,
   "portal_account_id":this.portal_account_id

  }
  this.authService.refund_search(payload_data).subscribe((res) => {
    let mes=JSON.parse(res)
    res =mes 
    this.total_product = mes.total_product
    if (res.statusCode>=200 && res.statusCode<=205) {
      this.showOverlay = false;
      this.length=mes.total_length
        this.fullData = res.Data; 
        // console.log('this.fullData.ifsc',this.fullData.ifsc)
        this.ogData =res.Data
      this.headerData = res.headers;
      this.getData(this.fullData); 
    //   console.log("mesg", res.message); 
    // console.log(this.length,'lengthhhhhhhh')

    } 
    else {
      if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
        // this.authService.logout()
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
      }
      else{
        this.toastr.error(res.error, "");
        this.showOverlay = false; 
        // console.log('error mesg',res)
      }
    }
  }, (error) => {
    this.showOverlay = false;
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
  });
}

openVerticallyCentered4(content: TemplateRef<any>) {
  this.modalService.open(content, { centered: true,size: 'lg'});

}


partial_order(){
  this.loading = true
  this.superuser = localStorage.getItem('is_superuser')

  if (this.superuser == 'true') {
    this.portal_name = localStorage.getItem('portal_name2')
    this.portal_id = localStorage.getItem('portal_id2')
    this.portal_account_id = localStorage.getItem('portal_account_id2')
    this.d2c_name = localStorage.getItem('d2c_name2')

  }
  else {
    this.portal_name = localStorage.getItem('portal_name')
    this.portal_id = localStorage.getItem('portal_id')
    this.portal_account_id = localStorage.getItem('portal_account_id')
    this.d2c_name = localStorage.getItem('d2c_name')
  }
  let data = this.createuserform8.value
  console.log(data,'data')
  this.authService.partial_order(data).subscribe((res: any) => {
    let mes = JSON.parse(res);
     if (mes.statusCode === 200) {
       this.showOverlay = false;
       this.fetchData(this.paginate)
       this.toastr.success(mes.success);
       this.modalService.dismissAll()
       this.loading = false

    
     } 
     else{
      if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
        this.showOverlay=false
        this.router.navigate(['/auth/login'])
        this.loading = false
      }
      else{
        this.toastr.error(mes.error, "");
        this.showOverlay = false; 
        this.loading = false
      }
    
     }
    
    }, 
    (error) => {
    this.showOverlay = false;
    this.loading = false
    if (error.status === 502) {
      this.toastr.error("502 Bad Gateway", "An error occurred");
    } else {
      this.toastr.error("An error occurred while communicating with the server.", "Please Try Again After some time");
    }
    });

}

}

