<div class="container d-flex justify-content-center">
    <div class="row col-md-8 d-flex justify-content-center" style="margin-top: 50px;">
        <div>
          <h2 class="d-flex justify-content-center mb-5">Portal Update</h2>
          <form [formGroup]="createuserform">
              <div class="row mb-2">
                  <div class="col-md-6">
                      <label for="portalname mb-5">Portal Name</label>
                      <!-- <input type="text" formControlName="portal_name" class="form-control" id="portalname" placeholder="Portal Name" /> -->
                      <select class="form-select" aria-label="Default select example" formControlName="portal_name"  [(ngModel)]="selectedPortalName" (change)="onPortalNameChange()">
                        <option *ngFor="let name of fullData" [value]="name.portal_name"  >
                          {{ name.portal_name }}
                        </option>
                      </select>
                         <!-- <select class="form-select" aria-label="Default select example"   formControlName="portal_name">
                        <option value="null">Select Portal Name</option>
                        <option *ngFor="let name of fullData" [value]="name.portal_account_id">
                          {{ name.portal_name }}
                        </option>
                    </select> -->
                      <div *ngIf="createuserform!.get('portal_name')?.invalid && createuserform!.get('portal_name')?.touched" class="text-danger" >
                        Portal Name field is required 
                      </div>
                  </div>
                  <div class="col-md-6">
                      <label for="portal_id">Portal Id</label> 
                      <input type="text" formControlName="portal_id" class="form-control" id="portal_id" placeholder="Portal Id"  [(ngModel)]="selectedportal" readonly/>
                      <div *ngIf="createuserform!.get('portal_id')?.invalid && createuserform!.get('portal_id')?.touched" class="text-danger" >
                        Portal Id field is required 
                      </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                      <label for="portalaccountid">Portal Account Id</label>
                      <input type="text" formControlName="portal_account_id" class="form-control" id="portal_account_id" placeholder="Portal Account Id"    [(ngModel)]="selectedPortalId" readonly
                      />
                      <div *ngIf="createuserform!.get('portal_account_id')?.invalid && createuserform!.get('portal_account_id')?.touched" class="text-danger" >
                        Portal Account Id field is required 
                      </div>
                  </div>
                  <div class="col-md-6">
                    <label for="d2cname">D2C Name</label>
                    <input type="text" formControlName="d2c_name" class="form-control" id="d2c_name" placeholder="D2C Name"  [(ngModel)]="d2c_name" readonly />
                    <div *ngIf="createuserform!.get('d2c_name')?.invalid && createuserform!.get('d2c_name')?.touched" class="text-danger" >
                      D2C Name field is required 
                    </div>
                </div>
                </div>
                
  
          </form>
        </div>
        <div class="d-flex justify-content-end mt-5">
            <button class="mt-1 me-2 ms-4 bg-primary"  style="background-color: rgb(245, 117, 117); color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;" (click)="profile_update()" [disabled]="!createuserform.valid">Submit</button>
            <button class="mt-1 me-2 ms-4"  style="background-color: rgb(245, 117, 117); color: white; border-radius: 6px; padding: 8px; border: none; width: 80px; height: 40px;"  (click)="route()">Cancel</button>
        </div>

    </div>

</div>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>