import { Injectable } from '@angular/core';
import { AuthenticationService } from './auth.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  fullData:any;
  first_name:any;
  last_name:any;
  portal_name:any;
  d2c_name:any;
  phone_number:any;
  email:any;
  imageURL:any;

  constructor(
    private router: Router, 
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private _shared : SharedService
  ) { }


  fetchdata(){
    // this.showOverlay=true;
    this.authService.user_details().subscribe((res: any) => {
      console.log(res)
      let mes=JSON.parse(res)
      console.log(mes.statusCode)
      if (mes.statusCode === 200) {
        // this.showOverlay = false;
        this.fullData = mes.body[0];
        localStorage.setItem('fullData', JSON.stringify(this.fullData)); 
        console.log('fullData', mes.body);
        this.first_name = this.fullData.first_name
        this.last_name = this.fullData.last_name
        this.portal_name = this.fullData.portal_name
        this.d2c_name = this.fullData.d2c_name
        this.phone_number = this.fullData.phone_number
        this.email = this.fullData.email
        localStorage.setItem('portal_name1',this.fullData.portal_name)
        if (this.fullData.image_path &&this.fullData.image_path.length !== 0) {
          // this.showOverlay=false
        this.imageURL = this._shared.getImageUrl('d2c-crm', this.fullData.image_path);
      } else {
          // this.showOverlay=false
          this.imageURL = 'assets/images/multi-user.jpg';}



      }
      else {
        if(res.error =='Token has expired' || res.error =='Invalid token' || res.error =='Token is required'){
          this.authService.logout1()
          // this.showOverlay=false
          this.router.navigate(['/auth/login'])
        }
        else{
          // this.toastr.error(res.error, "");
          // this.showOverlay = false; 
          // console.log('error mesg',res)
        }
      }
      // else{
      //   this.showOverlay=false




      // }
    })

  }
}
