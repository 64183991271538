<div class="d-flex justify-content-between">
    <h1 class="page mt-3 ms-2">Support Ticket View</h1>
    <button class="bg-primary"
    style="background-color: blueviolet; color: white; border-radius: 6px; padding: 8px; border: none; width:112px; height: 40px;"
    (click)="openVerticallyCentered1(content3)" >Create Ticket</button>

  </div>
<div class="d-flex justify-content-between mt-5 laptop">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup)="applyFilter1($event.target)">
        <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-end page-top">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="onPageChange($event)"></mat-paginator>
    </div>
</div>
<div class="d-flex justify-content-center mt-5 mobile">
  <div class="position-relative col-md-3">
      <input type="text" class="form-control search-input" placeholder="Search...."
          (keyup)="applyFilter1($event.target)">
      <span class="ri-search-line search-icon"></span>
  </div>

  <div class="border text-whhite d-flex justify-content-center page-top">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="onPageChange($event)"></mat-paginator>
  </div>
</div>
<!-- <div class="d-flex justify-content-end"> 
    <div class="border text-whhite d-flex justify-content-between" >
        <div class="position-relative col-md-3">
            <input type="text" class="form-control search-input" placeholder="Search...." (keyup)="applyFilter1($event.target)">
            <span class="ri-search-line search-icon"></span>
        </div>
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="length"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="pageEvent=fetchData($event)"></mat-paginator>
    </div>
</div> -->
<button type="button" class="btn btn-sm btn-light mt-3"  [ngClass]="{ 'bg-primary1': currentFilter === 'open', 'bg-light1': currentFilter !== 'open' }" (click)="setFilter('open')" style="color: black!important;">Open</button>
<button type="button" class="btn btn-sm btn-light mt-3  ms-3"  [ngClass]="{ 'bg-primary1': currentFilter === 'close', 'bg-light1': currentFilter !== 'close' }" (click)="setFilter('close')" style="color: black!important;">Close</button>
<button type="button" class="btn btn-sm btn-light mt-3  ms-3"  [ngClass]="{ 'bg-primary1': currentFilter === 'all', 'bg-light1': currentFilter !== 'all' }" (click)="setFilter('all')" style="color: black!important;">All</button>
<div class="row d-flex justify-content-center p-4">
    <div class="card-body" style="background-color:transparent;">
        <div class="material-datatables">
            <div class="position-relative overflow-auto">
                <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers" matSort>
                    <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i"
                        [stickyEnd]="tableheader == 'actions'">
                        <th mat-header-cell *matHeaderCellDef
                            class="text-center  justify-content-center text-primary text-bold" mat-sort-header
                            style="white-space: nowrap;">
                            {{ headerData[tableheader] }}
                        </th>
                        <ng-container *ngIf="tableheader=='ticket_id'">
                            <!-- <td mat-cell class="text-center align-middle"
                                style="white-space: nowrap; font-size: 12px; cursor: pointer; color: blue;"
                                *matCellDef="let row; index as i" (click)="ticket_details(row)">
                                <span>{{ row[tableheader] }}</span>
                            </td> -->
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;" *matCellDef="let row; index as i">
                              <span [style.color]="isUnreadTicket(row['ticket_id']) ? 'red' : 'blue'" style="cursor: pointer;" 
                                    [ngClass]="{'clickable': isUnreadTicket(row['ticket_id'])}"
                                    (click)="handleTicketClick(row)">
                                {{ row['ticket_id'] }}
                              </span><br>
                              <span>{{ row['order_id'] }}</span>
                            </td>
                        </ng-container>
                        <ng-container>
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

            </div>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom">
            <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
              [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="onPageChange($event)"></mat-paginator>
        </div>
    </div>


</div>
<ng-template #content3 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Raise Support Ticket</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createuserform2">
        <div class="row col-md-12">
          <div class="col-md-6 mb-3">
            <label for="disposition">Is Order Issue</label>
            <select formControlName="is_order_issue" class="form-select" id="is_order_issue">
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform2.get('is_order_issue')?.value === 'false'">
            <label for="status">Category</label>
            <select formControlName="category" class="form-select" id="category">
              <option value="" disabled selected>Select Category</option>
              <option value="new product listing">New Product Listing</option>
              <option value="product price changes">Product Price Changes</option>
              <option value="payment gateway issues">payment gateway issues</option>
              <option value="website theme or plugin changes">Website Theme or Plugin Changes</option>
              <option value="inventory">Inventory</option>
              <option value="product related">Product Related</option>
              <option value="cancel or return">Cancel or Return</option>
              <option value="notification services">Notification Services</option>
              <option value="invoice issues">Invoice Issues</option>
              <option value="delivery related issues">Delivery Related Issues</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform2.get('is_order_issue')?.value === 'true'">
            <label for="status">Category</label>
            <select formControlName="category" class="form-select" id="category">
              <option value="" disabled selected>Select Category</option>
              <option value="missing order">Missing Order</option>
              <option value="wrong product dispatched">Wrong Product Dispatched</option>
              <option value="order not received">Order Not Received</option>
              <option value="missing quantity">Missing Quantity</option>
              <option value="wrong invoice shared">Wrong Invoice Shared</option>
              <option value="order delay">Order Delay</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform2.get('is_order_issue')?.value === 'true'">
            <label for="order_id">Order Id</label>
            <input type="text" formControlName="order_id" class="form-control" id="order_id" placeholder="Order Id" />
            <div *ngIf="createuserform2!.get('order_id')?.invalid && createuserform2!.get('order_id')?.touched"
              class="text-danger">
              Order Id field is required
            </div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform2.get('is_order_issue')?.value === 'true'">
            <label for="awb">Awb</label>
            <input type="text" formControlName="awb" class="form-control" id="awb" placeholder="Awb" />
            <div *ngIf="createuserform2!.get('awb')?.invalid && createuserform2!.get('awb')?.touched"
              class="text-danger">
              Awb field is required
            </div>
          </div>
          <!-- <div class="col-md-6 mb-3">
            <label for="status">Status</label>
            <select formControlName="status" class="form-select" id="status1">
              <option value="open">Open</option>
              <option value="close">Close</option>
            </select>
          </div>  -->
          <!-- <div class="col-md-6 mb-3">
            <label for="disposition">Disposition</label>
            <select formControlName="disposition" class="form-select" id="disposition">
              <option value="pending">Pending</option>
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="in progress">In Progress</option>
              <option value="resolved">Resolved</option>
            </select>
          </div>  -->

          <div class="col-md-6 mb-3">
            <label for="agent_remark">Agent Remark</label>
            <input type="text" formControlName="agent_remark" class="form-control" id="agent_remark" (blur)="only_spl_and_num_not_accept($event,'Agent Remark')"
              placeholder="Agent Remark" />
            <div *ngIf="createuserform2!.get('agent_remark')?.invalid && createuserform2!.get('agent_remark')?.touched"
              class="text-danger">
              Agent Remark field is required
            </div>
          </div>

          <div class="col-md-6 mb-3" *ngIf="createuserform2.get('is_order_issue')?.value === 'true'">
            <label for="voice_of_customer">Voice Of Customer</label>
            <input type="text" formControlName="voice_of_customer" class="form-control" id="voice_of_customer" (blur)="only_spl_and_num_not_accept($event,'Voice Of Customer')"
              placeholder="Voice Of Customer" />
            <div
              *ngIf="createuserform2!.get('voice_of_customer')?.invalid && createuserform2!.get('voice_of_customer')?.touched"
              class="text-danger">
              Voice Of Customer field is required
            </div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="createuserform2.get('is_order_issue')?.value === 'false'">
            <label for="support_chart">Leave A Note</label>
            <input type="text" formControlName="support_chat" class="form-control" id="support_chart" (blur)="only_spl_and_num_not_accept($event,'Leave A Note')"
              placeholder="Leave A Note" />
            <div
              *ngIf="createuserform2!.get('support_chart')?.invalid && createuserform2!.get('support_chart')?.touched"
              class="text-danger">
              Leave A Note field is required
            </div>
          </div>
          <div class="col-md-12 mb-3" *ngIf="createuserform2.get('is_order_issue')?.value === 'true'">
            <label for="support_chart">Leave A Note</label>
            <input type="text" formControlName="support_chat" class="form-control" id="support_chart" (blur)="only_spl_and_num_not_accept($event,'Leave A Note')"
              placeholder="Leave A Note" />
            <div
              *ngIf="createuserform2!.get('support_chart')?.invalid && createuserform2!.get('support_chart')?.touched"
              class="text-danger">
              Leave A Note field is required
            </div>
          </div>


        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
        (click)="support_ticket_create()" [disabled]="!createuserform2.valid">Submit</button>
    </div>
  </ng-template>

<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>