<h1 class="page col-md-6 mb-4 mt-2">Permission List</h1>
<div class="d-flex justify-content-end mt-5 laptop">
    <!-- <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup.enter)="applyFilter1($event.target.value)">
        <span class="ri-search-line search-icon"></span>
    </div> -->

    <div class="border text-whhite d-flex justify-content-end page-top">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
    <!-- <div class="border text-whhite d-flex justify-content-end page-top" *ngIf="searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
  </div> -->
</div>
<div class="justify-content-center mt-5 mobile">
    <div class="position-relative col-md-3">
        <input type="text" class="form-control search-input" placeholder="Search...."
            (keyup.enter)="applyFilter1($event.target.value)">
        <span class="ri-search-line search-icon"></span>
    </div>

    <div class="border text-whhite d-flex justify-content-end page-top mt-4">
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
          [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
    </div>
<!--     
    <div class="border text-whhite d-flex justify-content-center page-top mt-2" *ngIf="searchchar === false">
      <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
        [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
  </div> -->
</div>
<!-- <div class="d-flex justify-content-end"> 
    <div class="border text-whhite d-flex justify-content-between" >
        <div class="position-relative col-md-3">
            <input type="text" class="form-control search-input" placeholder="Search...." (keyup)="applyFilter1($event.target)">
            <span class="ri-search-line search-icon"></span>
        </div>
        <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="length"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="pageEvent=fetchData($event)"></mat-paginator>
    </div>
</div> -->
<div class="row d-flex justify-content-center p-4">
    <div class="card-body" style="background-color:transparent;">
        <div class="material-datatables">
            <div class="position-relative overflow-auto">
                <table class="table table-no-bordered table-hover" mat-table [dataSource]="customers" matSort>
                    <ng-container [matColumnDef]="tableheader" *ngFor="let tableheader of displayedColumns; index as i"
                       >
                        <th mat-header-cell *matHeaderCellDef class="text-center text-primary text-bold" mat-sort-header
                            style="white-space: nowrap;">
                            {{ headerData[tableheader] }}
                        </th>
                        <ng-container *ngIf="tableheader=='actions'">
                            <td mat-cell class="text-center align-middle" *matCellDef="let row; index as i" style="font-size: 12px;">
                              <span style="cursor: pointer;">
                                <img src="assets/images/edit22.png" height="20px" width="20px"  (click)="openVerticallyCentered2(content4,row)">
                              </span>
                            </td>
                          </ng-container>
                        <ng-container>
                          <ng-container *ngIf="tableheader === 'permission'">
                            <td mat-cell class="text-center align-middle" *matCellDef="let row; index as i" style="font-size: 12px;">
                              <ul>
                                <!-- <li  *ngIf="isWhatsApp === 'true'" style="text-align: left;">Is WhatsApp</li>
                                <li  *ngIf="isWhatsAppMarketing === 'true'" style="text-align: left;">Is WhatsApp Marketing</li> -->
                                <li  *ngIf="isWhatsApp === true || isWhatsApp === 'true'" style="text-align: left;">Is WhatsApp</li>
                                <li  *ngIf="isWhatsAppMarketing === true || isWhatsAppMarketing === 'true'" style="text-align: left;">Marketing Notifications</li>
                                <li  *ngIf="order_notifications === true || order_notifications === 'true'" style="text-align: left;">Order Notifications</li>
                                <li  *ngIf="custom_notifications === true || custom_notifications === 'true'" style="text-align: left;">Custom Notifications</li>
                              </ul>
                            </td>
                          </ng-container>   
                          <ng-container *ngIf="tableheader === 'transaction'">
                            <td mat-cell class="text-center align-middle" *matCellDef="let row; index as i" style="font-size: 12px;">
                              <ul>
                                <!-- <li  *ngIf="isWhatsApp === 'true'" style="text-align: left;">Is WhatsApp</li>
                                <li  *ngIf="isWhatsAppMarketing === 'true'" style="text-align: left;">Is WhatsApp Marketing</li> -->
                                <li  style="text-align: left;">Order Notification : {{this.order}}</li>
                                <li style="text-align: left;">Marketing Notification : {{this.marketing}}</li>
                              </ul>
                            </td>
                          </ng-container>                        
                            <td mat-cell class="text-center align-middle" style="white-space: nowrap; font-size: 12px;"
                                *matCellDef="let row; index as i">
                                <span>{{ row[tableheader] }}</span>
                            </td>
                        </ng-container>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

            </div>
        </div>
        <div class="border text-whhite d-flex justify-content-end page-bottom">
            <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
              [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
        </div>
        <!-- <div class="border text-whhite d-flex justify-content-end page-bottom" *ngIf="searchchar === false">
          <mat-paginator class="bg-transparent" [hidden]="apiData?.length==0" [length]="this.length"
            [pageSizeOptions]="[10, 25, 50]" [(pageSize)]="paginate.pageSize" (page)="fetchData($event)"></mat-paginator>
      </div> -->
    </div>


</div>
<ng-template #content4 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Update Permission</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createuserform4">
        <div class="row col-md-12">
          <div class="col-md-6 mb-3">
            <label for="disposition">Is Whatsapp</label>
            <select formControlName="is_whatsapp" class="form-select"  aria-label="Default select example"  id="is_whatsapp" [(ngModel)]="isWhatsApp">
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="disposition">Marketing Notifications</label>
            <select formControlName="is_whatsapp_marketing" class="form-select" aria-label="Default select example" id="is_whatsapp_marketing" [(ngModel)]="isWhatsAppMarketing">
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="disposition">Order Notifications</label>
            <select formControlName="order_notifications" class="form-select" aria-label="Default select example" id="order_notifications" [(ngModel)]="order_notifications">
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="disposition">Custom Notifications</label>
            <select formControlName="custom_notifications" class="form-select" aria-label="Default select example" id="custom_notifications" [(ngModel)]="custom_notifications">
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
          <div class="col-md-6 mb-3">
            <label for="order_id">Credit Amount</label>
            <input type="text" formControlName="credit_amount" class="form-control" id="credit_amount" placeholder="Credit Amount" [(ngModel)]="credit_amount" />
            <div *ngIf="createuserform4!.get('credit_amount')?.invalid && createuserform4!.get('credit_amount')?.touched"
              class="text-danger">
              Credit Amount field is required
            </div>
          </div>
          <div class="col-md-6">
            <label for="portalname">Portal</label>
            <input type="text" formControlName="portal_account_id" class="form-control" id="portal_account_id" placeholder="Enter Portal Name" [(ngModel)]="portal_name" readonly />
            <!-- <select class="form-select" aria-label="Default select example" formControlName="portal_account_id" [(ngModel)]="portal_account_id1"
             >
              <option value="Select Portal Name" disabled>Select Portal Name</option>
              <option *ngFor="let name of fullData1" [value]="name.portal_account_id">
                {{ name.portal_name }}
              </option>
            </select> -->
            <div *ngIf="createuserform4!.get('portal_account_id')?.invalid && createuserform4!.get('portal_account_id')?.touched"
              class="text-danger">
              Portal field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="order_amount">Order Notification Amount</label>
            <input type="text" formControlName="order_amount" class="form-control" id="order_amount" placeholder="Enter Order Notification Amount" [(ngModel)]="order" />
            <div *ngIf="createuserform4!.get('order_amount')?.invalid && createuserform4!.get('order_amount')?.touched"
              class="text-danger">
              Order Notification Amount field is required
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="order_amount">Marketing Notification Amount</label>
            <input type="text" formControlName="marketing_amount" class="form-control" id="marketing_amount" placeholder="Enter Marketing Notification Amount" [(ngModel)]="marketing" />
            <div *ngIf="createuserform4!.get('marketing_amount')?.invalid && createuserform4!.get('marketing_amount')?.touched"
              class="text-danger">
              Marketing Notification Amount field is required
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary w-md waves-effect waves-light me-3" type="submit"
        (click)="permission_create()">update</button>
    </div>
    <ng-container *ngIf="showOverlay" id="overlay-container">
      <app-spinner [overlay]="true" id="loader"></app-spinner>
  </ng-container>
  </ng-template>
<ng-container *ngIf="showOverlay" id="overlay-container">
    <app-spinner [overlay]="true" id="loader"></app-spinner>
</ng-container>