import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { SharedService } from 'src/app/core/services/shared.service';



@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {

  updateform: UntypedFormGroup;
  fullData:any
  firstname:any;
  lastname:any;
  email1:any;
  phonenumber:any;
  showOverlay=false;
  imageURL:any;
  selectedFile:any;
  username:any;
  img_path:any;
  userProfileImage: string = 'assets/images/users/avatar-2.jpg'; 
  constructor(private router: Router, 
    private authService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private _shared:SharedService
    ){

  }

  ngOnInit(): void {

    this.fullData = JSON.parse(localStorage.getItem('fullData') || '[]');
    this.firstname = this.fullData.first_name
    this.lastname = this.fullData.last_name
    this.phonenumber = this.fullData.phone_number
    this.email1 = this.fullData.email
    this.username = this.fullData.username
    
    if (this.fullData.image_path &&this.fullData.image_path.length !== 0) {
      this.showOverlay=false
    this.imageURL = this._shared.getImageUrl('d2c-crm', this.fullData.image_path);
  } else {
      this.showOverlay=false
      this.imageURL = 'assets/images/multi-user.jpg';}
    // this.imageURL = this._shared.getImageUrl('d2c-crm', this.fullData.image_path);
    this.initform();

  }
  initform(){
    this.updateform = this.formBuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      // password: ['', [Validators.required]],
      // username: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      image_path:['']
    });
  }

  numericOnly(event): boolean {    
    let patt = /^[0-9 .]+$/;
    let result = patt.test(event.key);
    return result;
  }
  onSubmit() {
    this.showOverlay=true;
    let data = this.updateform.value
    data['image_path'] = this.img_path
    this.authService.update_profile(data).subscribe((res: any) => {
      let mes=JSON.parse(res)
      if(mes.statusCode === 201){
        this.toastr.success(mes.success, 'Success')
        this.showOverlay=false
        this.router.navigate(['/page/retailripple/profile'])
      }
      else {
        if(mes.error =='Token has expired' || mes.error =='Invalid token' || mes.error =='Token is required'){
          this.authService.logout1()
          this.showOverlay=false
          this.router.navigate(['/auth/login'])
        }
        else{
          this.toastr.error(mes.error, "");
          this.showOverlay = false; 
        }
      }
     
    })
     
    
  }


  fileChange(event: any) {
    this.showOverlay = true;
    let fileList: any = (event.target as HTMLInputElement);
    let file: File = fileList.files[0];
    
  
    if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
      this.toastr.error("Uh-oh! We only accept PNG, JPG, or JPEG images. Please upload a file in one of these formats");
      this.showOverlay = false;

      return;
    }

  
    this.selectedFile = fileList.files[0];
    // console.log(this.selectedFile,'selectedfile')
    this.s3Upload(this.selectedFile);
  
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      (document.getElementById('companylogo-img') as HTMLImageElement).src = this.imageURL;
    };
    reader.readAsDataURL(file);
  
  }

  async s3Upload(file:any ){ 
    
    let bucketName = 'd2c-crm'
    let FolderName = `profile-image`
    try {
      let data = await this._shared.UploadFileToS3(bucketName,FolderName,file);
      // console.log(data,'data2')
      this.showOverlay=false 
      this.img_path=data 
      // console.log(data,'data1')
      this.updateform.get('image_path').setValue(this.img_path);
      return data;
    } catch (error) {
      // console.error(error);
      this.showOverlay=false 
    }
  }

  albhabetsOnly(event:any): boolean {    
    let patt = /^[a-z A-Z]+$/;
    let result = patt.test(event.key);
    return result;
  }

}
